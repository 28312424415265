import { saveCallbackStatus } from '@finpay-development/shared-components';
import { createSlice } from '@reduxjs/toolkit';
import { emptyFinPassPatientModel, emptyVobPatient, FinPassPatientModel, patientIocResult, patientSearchResult, vobPatient } from '../models/patient';
import { createPatientIoc, getVobPatient, saveVobPatient, searchAAPatient, getCapacityToPay, getNationalPovertyThreshold, saveFinPassPatient } from './vob-patient-thunk';
import { PowerlyticsGeographyData } from '../models/powerlytics';
import { emptyLoadingState, LoadingState } from '../models/loading-state';

// vob patient slice. Not to be confused with patient slice in Patient Records

export interface patientSliceState {
    patient: vobPatient;
    patientSearchResults: {
        total: number;
        data: patientSearchResult[];
    };
    powerlyticsData?: PowerlyticsGeographyData;
    patientEncounterResult: patientIocResult | undefined;
    capacityToPayStatus: saveCallbackStatus;
    patientIocStatus: saveCallbackStatus;
    isError: boolean;
    nationalPovertyThreshold: number;
    isPatientLoading: LoadingState;
    saveFinPassPatientStatus: saveCallbackStatus;
    selectedFinPassPatient: FinPassPatientModel;
    errorMessage: string;
}

const initialState = {
  patient: emptyVobPatient,
  patientSearchResults: {
    total: 0,
    data: []
  },
  powerlyticsData: undefined,
  patientEncounterResult: undefined,
  capacityToPayStatus: saveCallbackStatus.none,
  patientIocStatus: saveCallbackStatus.none,
  isError: false,
  nationalPovertyThreshold: 0,
  isPatientLoading: emptyLoadingState,
  saveFinPassPatientStatus: saveCallbackStatus.none,
  selectedFinPassPatient: emptyFinPassPatientModel,
  errorMessage: '',
} as patientSliceState;

export const vobPatientSlice = createSlice({
	name: "vobPatientContext",
	initialState,
	reducers: {
		setPatient: (state, action) => {
			state.patient = {
        ...state.patient,
        ...action.payload
      };
		},
		clearPatient: (state) => {
			state.patient = emptyVobPatient;
      state.selectedFinPassPatient = emptyFinPassPatientModel
		},
		clearCapacityToPayStatus: (state) => {
			state.capacityToPayStatus = initialState.capacityToPayStatus;
		},
		clearPatientIocStatus: (state) => {
			state.patientIocStatus = initialState.patientIocStatus;
		},
    clearFinPassStatus: (state) => {
      state.saveFinPassPatientStatus = initialState.saveFinPassPatientStatus;
      state.errorMessage = "";
    },
    clearPatientEncounterResult: (state)=>{
      state.patientEncounterResult = undefined
    }
	},
  extraReducers: (builder) => {
    builder.addCase(saveVobPatient.pending, (state, action) => {
      state.patient.isLoading = true;
    });
    builder.addCase(saveVobPatient.fulfilled, (state, action) => {
      state.patient.isLoading = false;
      state.patient = action.payload;
    });
    builder.addCase(saveVobPatient.rejected, (state, action) => {
      state.patient.isLoading = false;
      state.isError = true;
    });
    builder.addCase(getVobPatient.pending, (state, action) => {
      state.isPatientLoading.getVobPatient = true;
    });
    builder.addCase(getVobPatient.fulfilled, (state, action) => {
      state.isPatientLoading.getVobPatient = false;
      state.patient = action.payload;
    });
    builder.addCase(getVobPatient.rejected, (state, action) => {
      state.isPatientLoading.getVobPatient = false;
      state.isError = true;
    });
    builder.addCase(searchAAPatient.pending, (state, action) => {
      state.isPatientLoading.searchPatient = true;
    });
    builder.addCase(searchAAPatient.fulfilled, (state, action) => {
      state.isPatientLoading.searchPatient = false;
      state.patientSearchResults = action.payload;
    });
    builder.addCase(searchAAPatient.rejected, (state, action) => {
      state.isPatientLoading.searchPatient = false;
      state.isError = true;
    });
    builder.addCase(getCapacityToPay.pending, (state, action) => {
      state.capacityToPayStatus = saveCallbackStatus.none;
      state.isPatientLoading.capacityToPay = true;
    });
    builder.addCase(getCapacityToPay.fulfilled, (state, action) => {
      state.isPatientLoading.capacityToPay = false;
      state.capacityToPayStatus = saveCallbackStatus.success;
      state.powerlyticsData = action.payload;
    });
    builder.addCase(getCapacityToPay.rejected, (state, action) => {
      state.isPatientLoading.capacityToPay = false;
      state.capacityToPayStatus = saveCallbackStatus.error;
      state.isError = true;
    });
    builder.addCase(getNationalPovertyThreshold.pending, (state, action) => {
      state.isPatientLoading.nationalPovertyThreshold = true;
    });
    builder.addCase(getNationalPovertyThreshold.fulfilled, (state, action) => {
      state.isPatientLoading.nationalPovertyThreshold = false;
      state.nationalPovertyThreshold = action.payload.data.poverty_threshold;
    });
    builder.addCase(getNationalPovertyThreshold.rejected, (state, action) => {
      state.isPatientLoading.nationalPovertyThreshold = false;
      state.isError = true;
    });
    builder.addCase(createPatientIoc.pending, (state, action) => {
      state.patientIocStatus = saveCallbackStatus.none;
      state.isPatientLoading.createPatientIoc = true;
    });
    builder.addCase(createPatientIoc.fulfilled, (state, action) => {
      state.isPatientLoading.createPatientIoc = false;
      state.patientIocStatus = saveCallbackStatus.success;
      state.patientEncounterResult = action.payload;
    });
    builder.addCase(createPatientIoc.rejected, (state, action) => {
      state.isPatientLoading.createPatientIoc = false;
      state.patientIocStatus = saveCallbackStatus.error;
      state.isError = true;
    });
    builder.addCase(saveFinPassPatient.pending, (state, action) => {
      state.isPatientLoading.saveFinPassPatient = true;
      state.saveFinPassPatientStatus = saveCallbackStatus.none;
    });
    builder.addCase(saveFinPassPatient.fulfilled, (state, action) => {
      state.isPatientLoading.saveFinPassPatient = false;
      state.selectedFinPassPatient = action.payload;
      state.patient.fpPatientId = action.payload.patientId;
      // state.patient.advisorPatientId = action.payload.advisorPatientId;
      state.saveFinPassPatientStatus = saveCallbackStatus.success;
    });
    builder.addCase(saveFinPassPatient.rejected, (state, action) => {
      state.isPatientLoading.saveFinPassPatient = false;
      state.saveFinPassPatientStatus = saveCallbackStatus.error;
      state.errorMessage = action.error.message === undefined ? 'UNKNOWN ERROR' : action.error.message;
      state.isError = true;
    });
  },
});

export const { setPatient, clearPatient, clearCapacityToPayStatus, clearPatientIocStatus, clearFinPassStatus, clearPatientEncounterResult } = vobPatientSlice.actions;

export default vobPatientSlice.reducer;