import { Button, Toggle } from '@finpay-development/shared-components';
import CreateIcon from '@mui/icons-material/Create';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ImplementationFacility,
} from '../../../implementation-specialist/components/implementation-clients/details/models/implementation-facility';
import { TableColumn } from '../../../shared/model/table-column';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { setEstimator } from '../../state/estimator-slice';
import { admissionsAdvisorUtils } from '../../utils/admission-advisor-utils';
import { EstPriorCareModal } from './estimator-prior-care-modal/estimator-prior-care-modal';

interface PriorCareSummaryProps {
  facilities: ImplementationFacility[][];
  facilityId: number;
}

export function EstPriorCareSummary(props: PriorCareSummaryProps) {
  const { facilities, facilityId } = props;
  const [priorCareModalOpen, setIsPriorCareModalOpen] = useState(false);
  const [locRowsPerPage, setLocRowsPerPage] = useState(5);
  const [locPage, setLocPage] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const locTableHeaders: TableColumn[] = [
    { id: "levelOfCareCode", label: "Code", minWidth: 200 },
    { id: "levelOfCareName", label: "Name", minWidth: 200 },
    { id: "colLocDays", label: "Days/Sessions" },
  ];

  const state = {
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.estimator
    ),
  };

  const { vobState, estimatorState } = state;

  useEffect(() => {
    priorCareSummaryFormik.values.priorCareYesNo = estimatorState.hasPriorCare
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatorState]);

  const handleLocChangePage = (event: unknown, newPage: number) => {
    setLocPage(newPage);
  };

  const handleChangeLocRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocRowsPerPage(+event.target.value);
    setLocPage(0);
  };

  const handlePriorCareModalOpen = (isEdit: boolean) => {
    setEditMode(isEdit);
    setIsPriorCareModalOpen(true);
  };

  async function handleModalSubmit(isEditMode: boolean) {
    setIsPriorCareModalOpen(false);
  }

  function handleModalCancel() {
    setIsPriorCareModalOpen(false);
  }

  const priorCareSummaryFormik = useFormik({
    initialValues: {
      priorCareYesNo: estimatorState.hasPriorCare,
    },
    onSubmit: () => {},
  });

  const handleHasPriorCareChange = () => {
    let estimatorStateCopy = { ...estimatorState };
    estimatorStateCopy.hasPriorCare =
      !priorCareSummaryFormik.values.priorCareYesNo;
    dispatch(setEstimator(estimatorStateCopy));
  };

	const priorLevelsOfCareSummary = admissionsAdvisorUtils.getPriorLevelsOfCare(estimatorState?.priorCare);
	const genericPriorLoc = admissionsAdvisorUtils.getGenericPriorAdjChg(priorLevelsOfCareSummary, estimatorState, vobState);

  return (
    <>
      {estimatorState?.selectedLevelsOfCare?.length > 0 ? (
        <>
          <Paper>

            {(estimatorState.planYearCrossover?.hasPlanYearCrossover && !estimatorState.planYearCrossover?.planYearCrossoverConfirmed) && (
              <Grid container direction="row" sx={{backgroundColor: "#E8DB47"}}>
                <Grid item xs={12} className="p-2">
                  Solve for plan year crossover PFR to enable prior care last 30 days and financial assistance
                </Grid>
              </Grid>
            )}

            <Box
              style={{
                padding: "1.0em",
                borderBottom: "1px solid #cccccc",
                marginBottom: "1.5em",
              }}
            >
              <Grid container direction="row" justifyContent="space-between">
                <Grid
                  item
                  justifyContent="center"
                  direction="column"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DateRangeIcon sx={{ marginRight: "0.25em" }} /> Adjustments for Prior Care
                  </Typography>
                </Grid>
                <Grid item>
                  <Toggle
                    leftText="Yes"
                    rightText="No"
                    name="priorCareYesNo"
                    onChange={() => {
                      handleHasPriorCareChange();
                    }}
                    value={priorCareSummaryFormik.values.priorCareYesNo}
                    formik={priorCareSummaryFormik}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row">
                {estimatorState.hasPriorCare && (
                  <>
                    {priorLevelsOfCareSummary.priorLevelsOfCare?.length ? (
                      <>
                        <Grid item direction="column" md={5}>
                          <Typography variant="body2">
                            {priorLevelsOfCareSummary.labelOne}
                          </Typography>
                          {priorLevelsOfCareSummary.valueOne}
                        </Grid>
                        <Grid item direction="column" md={5}>
                            <Typography variant="body2">{priorLevelsOfCareSummary.labelTwo}</Typography>
                            {priorLevelsOfCareSummary.valueTwo}
                        </Grid>

                        <Grid
                          item
                          direction="column"
                          md={2}
                          style={{ textAlign: "right" }}
                        >
                          <IconButton
                            color="primary"
                            title="Edit Prior Care"
                            style={{ fontSize: "1.5rem" }}
                            onClick={() => handlePriorCareModalOpen(true)}
                          >
                            <CreateIcon /> Edit
                          </IconButton>
                        </Grid>

                        <TableContainer component={Paper}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                {locTableHeaders.map((column) => (
                                  <TableCell key={column.id}>
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {_.sortBy(
                                genericPriorLoc,
                                "name"
                              ).slice!(
                                locPage * locRowsPerPage,
                                locPage * locRowsPerPage + locRowsPerPage
                              ).map((row, index) => {
                                return (
                                  <TableRow key={`${row.code}.${index}`}>
                                    <TableCell>
                                      {row.code}
                                    </TableCell>
                                    <TableCell>
                                      {row.name}
                                      <div style={{fontSize: "8pt"}}>{admissionsAdvisorUtils.getFacilityName(row.facilityId, facilities)}</div>
                                    </TableCell>
                                    <TableCell>
                                      {row.days}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>

                          <Grid container>
                            <Grid item xs={12}>
                              <TablePagination
                                component="div"
                                rowsPerPageOptions={[
                                  5,
                                  10,
                                  50,
                                  {
                                    value:
                                    genericPriorLoc?.length!,
                                    label: "All",
                                  },
                                ]}
                                count={
                                  genericPriorLoc?.length!
                                }
                                rowsPerPage={locRowsPerPage}
                                page={locPage}
                                labelRowsPerPage={"View"}
                                onPageChange={handleLocChangePage}
                                onRowsPerPageChange={handleChangeLocRowsPerPage}
                              />
                            </Grid>
                          </Grid>
                        </TableContainer>
                      </>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "center", padding: "1em" }}
                      >
                        <Button onClick={() => handlePriorCareModalOpen(false)}>
                          Enter Prior Care
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Box>
          </Paper>
        </>
      ) : (
        <></>
      )}

      {priorCareModalOpen && (
        <EstPriorCareModal
          open={priorCareModalOpen}
          isEdit={editMode}
          facilities={facilities}
          facilityId={facilityId}
          handlePriorCareModalCancel={handleModalCancel}
          handlePriorCareModalSubmit={handleModalSubmit}
        />
      )}
    </>
  );
}
