import { DialogActionButton, TextField } from '@finpay-development/shared-components';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LevelOfCare } from '../../../../admin-configuration/models/level-of-care';
import { clearStatus } from '../../../../admin-configuration/state/admin-configuration-slice';
import {
  ImplementationFacility,
} from '../../../../implementation-specialist/components/implementation-clients/details/models/implementation-facility';
import { showErrorStatus } from '../../../../security/state/user-slice';
import { RootState } from '../../../../shared/state/root-reducer';
import { EstLevelOfCare, EstPriorLevelsOfCare } from '../../../models/estimator';
import { setEstimator } from '../../../state/estimator-slice';
import { admissionsAdvisorUtils } from '../../../utils/admission-advisor-utils';
import { PriorCareFacilityLocGrid } from './prior-care-facility-loc-grid';
import { PriorCareMasterLocGrid } from './prior-care-master-loc-grid';

interface EstPriorCareModalProps {
  open: boolean;
  isEdit: boolean;
  facilities: ImplementationFacility[][];
  facilityId: number;
  handlePriorCareModalCancel: () => void;
  handlePriorCareModalSubmit: (isEdit: boolean) => void;
}

interface NameValue {
  name: string;
  value: string;
}

export function EstPriorCareModal(props: EstPriorCareModalProps) {
  const {
    open,
    isEdit,
    facilities,
    facilityId,
    handlePriorCareModalCancel,
    handlePriorCareModalSubmit,
  } = props;

  const priorCareProviders: NameValue[] = [
    { name: "Third Party", value: "thirdparty" },
    { name: "Client", value: "client" },
  ];

  const thirdPartyProviderOptions: NameValue[] = [
    { name: "Average In-Network", value: "innetw" },
    { name: "Average Out-of-Network", value: "oonetw" },
  ];

  const [selectedThirdPartyLocs, setSelectedThirdPartyLocs] = useState<LevelOfCare[]>([]);
  const [selectedFacilityLocs, setSelectedFacilityLocs] = useState<EstLevelOfCare[]>([]);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [priorCareProvider, setPriorCareProvider] = useState<NameValue>(
    priorCareProviders[0]
  );
  const [clientFacilityId, setClientFacilityId] = useState(facilityId)
  const [thirdPartyProviderOption, setThirdPartyProviderOption] =
    useState<NameValue>(thirdPartyProviderOptions[0]);
  const [thirdPartyGridIsValid, setThirdPartyGridIsValid] = useState(false);
  const [facilityGridIsValid, setFacilityGridIsValid] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const priorLevelsOfCareSummary = admissionsAdvisorUtils.getPriorLevelsOfCare(estimatorState?.priorCare);
    if (priorLevelsOfCareSummary.masterListLoc) {
      setSelectedThirdPartyLocs([...priorLevelsOfCareSummary?.priorLevelsOfCare! as LevelOfCare[]]);
    } else {
      setSelectedFacilityLocs([...priorLevelsOfCareSummary?.priorLevelsOfCare! as EstLevelOfCare[]]);
    }
    if (estimatorState?.priorCare?.priorCareProvider?.value !== "") {
      const providerOption = priorCareProviders?.find((el) => el.value === estimatorState.priorCare.priorCareProvider.value);
      if (providerOption) {
        setPriorCareProvider(providerOption)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    checkIsFormValid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirdPartyGridIsValid, facilityGridIsValid]);


  const state = {
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.estimator
    )
  };
  const { estimatorState } = state;

  const saveStatus = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.modalSaveStatus
  );

  const errorMessage = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.errorMessage
  );

	function validatePriorCareForm() {
		let isValid = false;
		switch(priorCareModalFormik.values.priorCareProvider) {
			case "thirdparty":
				if (priorCareModalFormik.values.thirdPartyPayerStatus !== "") {
					isValid = true;
				}
				break;
			case "client":
				if (priorCareModalFormik.values.facility !== null) {
					isValid = true;
				}
				break;
			default:
				// do nothing
		}
		return isValid;
  }

  const priorCareModalFormik = useFormik({
    initialValues: {
      priorCareProvider:
        estimatorState?.priorCare?.priorCareProvider?.value !== ""
          ? estimatorState?.priorCare?.priorCareProvider?.value
          : "thirdparty",
      thirdPartyPayerStatus:
        estimatorState?.priorCare?.thirdPartyPayerStatus?.value !== ""
          ? estimatorState?.priorCare?.thirdPartyPayerStatus?.value
          : "innetw",
      facility: facilityId,
    },
    onSubmit: () => {},
  });

  function handleSave() {
    let priorLocBody: EstPriorLevelsOfCare = {
      priorCareProvider: priorCareProvider,
      thirdPartyPayerStatus: thirdPartyProviderOption,
      facility: {
        facilityId: priorCareModalFormik.values.facility,
        facilityName: facilities
          .flat()
          .find((el) => el.facilityId === priorCareModalFormik.values.facility)
          ?.facilityName,
      },
      priorThirdPartyLevelsOfCare: selectedThirdPartyLocs,
      priorFacilityLevelsOfCare: selectedFacilityLocs,
    };
    let estimatorStateCopy = { ...estimatorState };
    estimatorStateCopy.priorCare = priorLocBody;
    dispatch(setEstimator(estimatorStateCopy));
    handleSaveCallback(true);
  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      handlePriorCareModalSubmit(isEdit);
      setEnableSaveButton(false);
    } else {
      dispatch(showErrorStatus(errorMessage));
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    handlePriorCareModalCancel();
  }

  const handleThirdPartyProviderChange = (e: Event) => {
    const selectedProvider = priorCareProviders.find(
      (el) => el.value === (e.target as HTMLInputElement).value
    );
    if (selectedProvider) {
      setPriorCareProvider(selectedProvider);
    }
  };

  const handleThirdPartyOptionsChange = (e: Event) => {
    const selectedOption = priorCareProviders.find(
      (el) => el.value === (e.target as HTMLInputElement).value
    );
    if (selectedOption) {
      setThirdPartyProviderOption(selectedOption);
    }
    checkIsFormValid();
  };

  const updateSelectedFacility = (e: Event) => {
     setClientFacilityId(+(e.target as HTMLInputElement).value);
  };

  const handleThirdPartyLocSelections = (selectedCopy: LevelOfCare[], isValid:boolean) => {
    setSelectedThirdPartyLocs(selectedCopy);
    setThirdPartyGridIsValid(isValid);
  };

  const handleFacilityLocSelections = (selectedCopy: EstLevelOfCare[], isValid:boolean) => {
    setSelectedFacilityLocs(selectedCopy);
    setFacilityGridIsValid(isValid);
  };

  const checkIsFormValid = () => {
    let gridIsValid:boolean = false;
    switch(priorCareModalFormik.values.priorCareProvider) {
			case "thirdparty":
				gridIsValid = thirdPartyGridIsValid;
				break;
			case "client":
				gridIsValid = facilityGridIsValid;
				break;
			default:
				// do nothing
		}
    setEnableSaveButton(validatePriorCareForm() && gridIsValid);
  };



  return (
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="lg"
      scroll="body"
    >
      <DialogTitle>{isEdit ? "Edit" : "Add"} Prior Levels of Care</DialogTitle>
      <DialogContent>
        <>
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Grid item direction="column" md={6} xs={6}>
            <TextField
              select
              label="Prior Care Provider"
              name="priorCareProvider"
              value={priorCareModalFormik.values.priorCareProvider}
              onChange={(e: Event) => {
                priorCareModalFormik.handleChange(e);
                handleThirdPartyProviderChange(e);
								checkIsFormValid();
              }}
              onBlur={priorCareModalFormik.handleBlur}
              error={
                priorCareModalFormik.touched.priorCareProvider &&
                priorCareModalFormik.errors.priorCareProvider
              }
            >
              {priorCareProviders.map((provider: NameValue) => (
                <MenuItem key={provider.value} value={provider.value}>
                  {provider.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item direction="column" md={6} xs={6}>
            {priorCareProvider.value === "thirdparty" ? (
              <TextField
                select
                label="Third Party Payer Status"
                name="thirdPartyPayerStatus"
                value={priorCareModalFormik.values.thirdPartyPayerStatus}
                onChange={(e: Event) => {
                  priorCareModalFormik.handleChange(e);
                  handleThirdPartyOptionsChange(e);
									checkIsFormValid();
                }}
                onBlur={priorCareModalFormik.handleBlur}
                error={
                  priorCareModalFormik.touched.thirdPartyPayerStatus &&
                  priorCareModalFormik.errors.thirdPartyPayerStatus
                }
              >
                {thirdPartyProviderOptions.map((option: NameValue) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <TextField
                select
                label="Facility"
                name="facility"
                value={priorCareModalFormik.values.facility}
                onChange={(e: Event) => {
                  priorCareModalFormik.handleChange(e);
                  updateSelectedFacility(e);
									checkIsFormValid();
                }}
                onBlur={priorCareModalFormik.handleBlur}
                error={
                  priorCareModalFormik.touched.facility &&
                  priorCareModalFormik.errors.facility
                }
              >
                {facilities.flat().map((facility: ImplementationFacility) => (
                  <MenuItem
                    key={facility.facilityId}
                    value={facility.facilityId}
                  >
                    {facility.facilityName}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
        </Grid>

        {priorCareProvider.value === "thirdparty" ? (
          <PriorCareMasterLocGrid
            handleSelections={handleThirdPartyLocSelections}
            selectedThirdPartyLocs = {selectedThirdPartyLocs}
          />
         ) : (
          <PriorCareFacilityLocGrid
            facilityId={clientFacilityId}
            handleSelections={handleFacilityLocSelections}
            selectedFacilityLocs = {selectedFacilityLocs}
          />
        )}


        </>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText={isEdit ? "Update" : "Save"}
          saveStatus={saveStatus}
          spinnerLeftPosition={5}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
