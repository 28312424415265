import '../../../../scss/components/_list-header.scss';

import { Button, DeleteModal, LoadingOverlay, Status, Toggle, AlertModal } from '@finpay-development/shared-components';
import GetAppIcon from '@mui/icons-material/GetApp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RefreshIcon from '@mui/icons-material/Refresh';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Divider, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { getClient } from '../../../../implementation-specialist/state/clients/implementation-clients-thunk';
import AccessControl from '../../../../security/components/access-control';
import { RolePageNames } from '../../../../security/model/role-page-names';
import { formatNumberToUSD } from '../../../../shared/calculators';
import { StatusColors } from '../../../../shared/enums';
import { ClientBusinessRules } from '../../../../shared/model/client';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { Utils } from '../../../../shared/utils';
import { patientHelper } from '../../../services/patient-service';
import { setCalculatedRemainingBalance, setTransactionsCSVData } from '../../../state/patient-slice';
import { getTransactions, releaseCharge, resendPaymentReceipt, resendRefundReceipt } from '../../../state/patient-thunk';
import { PatientEncounter } from '../../models/patient-encounter';
import { Payment, PaymentDispute, PaymentReversal } from '../../models/payment';
import { PAYMENT_METHOD_TYPES } from '../../models/payment-method';
import { PaymentStatus } from '../../models/payment-status';
import { PAYMENT_TYPES } from '../../models/payment-type';
import { PFRAdjustment } from '../../models/pfr-adjustment';
import { Transaction } from '../../models/transaction';
import {ClientStatusCardViewModel} from 'src/shared/model/client-status-card';
import { useNavigate } from 'react-router';
import ResendReceiptModal from './resend-receipt-modal';


type menuActions = "refund" | "resend receipt";

interface TransactionAccordionContentsProp {
  selectedEncounter: PatientEncounter;
  isAccountHolder?: boolean,
}

export const TransactionsAccordionContents = (props: TransactionAccordionContentsProp) => {

  const { selectedEncounter, isAccountHolder = false } = props;
  const transactions = selectedEncounter?.patientTransactions;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const groupedTransactions = transactions ? patientHelper.consolidateTransactions(transactions, isAccountHolder) : []

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // eslint-disable-next-line
  const [transactionId, setTransactionId] = useState(0);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false)
  const [showTransactionDetails, setShowTransactionsDetails] = useState(false)

  const navigate = useNavigate();
  const [optionTexts, setOptionTexts] = useState(
    {
      menuText: "Refund",
      deleteModalTitle: "Refund",
    }
  )
  const resendReceiptButton = {
     menuText: "Resend Receipt",
     modalTitle: "Receipt"
  }
  const formRef: any = useRef();

  const stateFields = {
    isLoading: useSelector((state: RootState) => {
      return state.patientContext.isLoading.patientTransactions;
    }),
    isTransactionsFetchError: useSelector((state: RootState) => {
      return state.patientContext.isError.patientTransactions
    }),
    transactionsCSVData: useSelector((state: RootState) => {
      return state.patientContext.transactionsCSVData
    }),
    currentClientBusinessRule: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.currentClientBusinessRules as ClientBusinessRules
    }),
    missingAddressError: useSelector((state: RootState) => state.patientContext.isError.missingAddress),
    isReleaseChargeError: useSelector((state: RootState) => {
      return state.patientContext.isError.releaseCharge
    }),
    releasePaymentStatusMsg: useSelector((state: RootState) => {
      return state.patientContext.releasePaymentStatusMsg
    }),
    selectedPatient: useSelector((state: RootState) => {
      return state.patientContext.selectedPatient
    }),
    allClients: useSelector((state: RootState) => {
      return state.implementationContext?.implementationSpecialistClient
        ?.allClients
    }),
    selectedPatientEncounter: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter
    }),
  }
  const {isLoading, isTransactionsFetchError, transactionsCSVData, currentClientBusinessRule, missingAddressError, isReleaseChargeError, releasePaymentStatusMsg,selectedPatient, allClients,selectedPatientEncounter} = stateFields;

  const calculatedRemainingBalance = selectedEncounter?.calculatedRemainingBalance || 0;
  const { doPFRAdjustmentsExist, adjustedPFR } = selectedEncounter;
  const patientPaymentProgram = selectedEncounter?.patientPaymentProgram?.[selectedEncounter.patientPaymentProgram.length - 1];
  const pfrAdjustments = selectedEncounter?.patientPfrAdjustments;
  const dispatch = useDispatch<AppDispatch>();

  const getSelectedPayment = () => {
    return selectedEncounter?.patientTransactions?.find((transaction: Transaction) => (
      transaction?.payment?.paymentId === transactionId))?.payment as Payment;
  }

  useEffect(() => {
    if (!isLoading && groupedTransactions?.length > 0) {
      const PFR = (adjustedPFR === 0 || adjustedPFR) ? adjustedPFR : selectedEncounter?.pfrAmt
      const recalculatedBalance = patientHelper.calculateRemainingBalance(groupedTransactions, PFR);
      if (selectedEncounter?.calculatedRemainingBalance !== recalculatedBalance) {
        dispatch(setCalculatedRemainingBalance({calculatedRemainingBalance: recalculatedBalance, isAccountHolder}));
      }
    }
  }, [adjustedPFR, dispatch, groupedTransactions, isAccountHolder, isLoading, selectedEncounter?.calculatedRemainingBalance, selectedEncounter?.pfrAmt])

  useEffect(() => {
    if (currentClientBusinessRule?.clientId !== selectedEncounter?.clientId && !isAccountHolder) {
      dispatch(getClient(selectedEncounter.clientId));
    }
  }, [dispatch, selectedEncounter?.clientId, currentClientBusinessRule?.clientId, isAccountHolder])

  const handleRefreshTransactions = () => {
    if (selectedEncounter?.patientEncounterId !== 0 && !missingAddressError) {
      dispatch(getTransactions({
        patientId: selectedEncounter?.patientId,
        encounterId: selectedEncounter?.patientEncounterId,
        isAccountHolder: isAccountHolder,
      }));
    }
  }
  // don't allow refunds to be issued by PES if the client is configured to be the one who issues refunds.
  const disallowRefund = currentClientBusinessRule?.refundsIssuedBy?.toLowerCase() === 'client' && selectedEncounter?.workflow?.workflowId === 3
  function handleReleaseFunds() {
    dispatch(releaseCharge({patientId: selectedEncounter?.patientId, encounterId: selectedEncounter?.patientEncounterId, payment: getSelectedPayment()}))
    setIsRefundModalOpen(false);
  }

  const handleRefundMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    transactionId: number,
    isPaymentTypeCard: boolean,
    isPaymentCaptured?: boolean,
    payment?: Payment,
  ) => {
    const isPaymentPending = payment?.paymentStatusPending

    if (payment?.paymentType?.paymentTypeId === PAYMENT_TYPES.AT_FACILITY.paymentTypeId) {
      setOptionTexts({menuText: "Remove", deleteModalTitle: "Remove Funds"}) // successful Paid at Facility
    } else if (!isPaymentCaptured || isPaymentPending) {
      if (!isPaymentTypeCard) {
        selectedEncounter?.workflow?.workflowId === 3
          ? setOptionTexts({menuText: "Cancel", deleteModalTitle: "Cancel Transfer"}) // converted cancelling ACH
          : setOptionTexts({menuText: "Remove", deleteModalTitle: "Remove Funds"}) // preconverted cancelling ACH
      } else {
        setOptionTexts({menuText: "Release", deleteModalTitle: "Release Funds"}) // release uncaptured credit card (hold on card)
      }
    } else {
      setOptionTexts({menuText: "Refund", deleteModalTitle: "Issue Refund"}) // successful Card or ACH
    }

    setAnchorEl(event.currentTarget);
    setTransactionId(transactionId);
  };

  const handleMenuClose = (menuActionType?: menuActions) => {
    setAnchorEl(null);
    if (menuActionType === 'refund') {
      setIsRefundModalOpen(true);
    } else if (menuActionType === 'resend receipt'){
       setIsReceiptModalOpen(true)
    } 
  };

  const handlePatientLedgerClick = (clientId: number, patientId: number) => {
    window.open(`/specialist/dashboard/client/${clientId}/patient/${patientId}/ledger`, '_blank');
  };

  const handleFailedResponse = (externalResponse: string) => {
      let toolTipTitle: string;
      if (externalResponse.startsWith('{')) {
          const parsedExternalResponse = JSON.parse(externalResponse);
          toolTipTitle = `${parsedExternalResponse.message} - ${parsedExternalResponse.code}:${parsedExternalResponse.declineCode}`;
      } else {
        toolTipTitle = externalResponse;
      }
      return toolTipTitle;
  };

  const getStatusTextReversalItem = (payment: Payment) => {
    const paymentSuccessful = payment?.paymentStatus === 'Success'
    let text = 'Refunded';
    if ((!payment?.paymentMethodUsed?.ach) && (!payment?.isCaptured)) { // uncaptured credit card payment
      text = 'Released'
    } else if ((payment?.paymentMethodUsed?.ach && (!payment?.isCaptured)) || (payment?.paymentType?.paymentTypeId === PAYMENT_TYPES.AT_FACILITY.paymentTypeId)) {
      text = 'Removed'
    }
    if (selectedEncounter?.workflow?.workflowId === 3 && payment?.paymentMethodUsed?.ach && payment?.isCaptured && !paymentSuccessful) {
      text = "Cancelled"
    }
    return text;
  }

  const showStatus = (payment: Payment) => !(payment?.paymentMethodUsed?.ach && !payment?.isCaptured && !(selectedEncounter?.workflow?.workflowId === 3));

  const getStatusText = (payment: Payment, showDetailedTransactions?: boolean) => {
    if (payment?.paymentMethodUsed?.cardPayment) {
      if (payment?.paymentStatusPending) {
        return payment?.isCaptured ? "Pending" : "Uncaptured"
      } else if (payment?.paymentStatusFailed) {
        return "Failed"
      } else if (payment?.paymentStatusSuccess) {
        return payment?.isCaptured ? "Success" : "Uncaptured"
      }
    } else if ((payment?.paymentType?.paymentTypeId === PAYMENT_TYPES.RECURRING.paymentTypeId) && (!payment?.paymentMethodUsed?.cardPayment && !payment?.paymentMethodUsed?.ach)) {
      // special condition for if a recurring payment was removed
      return "Failed"
    } else {
      return payment?.paymentStatus
    }
    return '';
  }

  const clientItem: ClientStatusCardViewModel = allClients?.find((client:ClientStatusCardViewModel) =>  client.clientId === selectedPatientEncounter.clientId)!

  const transactionSuccess = Array.isArray(transactions)? transactions.map((transaction)=> {
    const paymentStatus = transaction.payment?.paymentStatus !== PaymentStatus.failed;
    const paymentReversalIdExists = transaction.paymentReversal?.paymentReversalId !==null;
    return paymentStatus || paymentReversalIdExists;
  }):[]

  const reversalIdArray = Array.isArray(transactions)
  ? transactions.map((transaction) =>transaction.paymentReversal?.paymentReversalId).filter(Boolean)
  :[]
  const paymentIdArray = Array.isArray(transactions) ?
  transactions.map((transaction)=> transaction.payment?.paymentId).filter(Boolean)
  :[]
  
  const reversalPaymentOriginArray = Array.isArray(transactions)?
  transactions.map((transaction)=> transaction.paymentReversal?.paymentId): []

  const disputeIdArray = Array.isArray(transactions) ?
  transactions.map((transaction)=> transaction.paymentDispute?.paymentDisputeId): []

  const handleResendReceipt = (receiptEmail: string) => {

    // Logic to resend the appropriate receipt
    if (paymentIdArray.includes(transactionId)) {
        
        // Dispatch resend payment receipt action
        dispatch(resendPaymentReceipt({ 
            transactionId, 
            receiptEmail 
        }));
    } 
    else if (reversalIdArray.includes(transactionId)) {
       
        // Dispatch resend refund receipt action
        dispatch(resendRefundReceipt({ 
            transactionId, 
            receiptEmail 
        }));
    } 
    else {
        console.error('Transaction ID not found in either array, or payment status is invalid.');
    }
    setIsReceiptModalOpen(false);
  }

  const transactionsListItem = (payment: Payment, paymentReversal: PaymentReversal, paymentDispute?: PaymentDispute) => {
    const hasDispute = !!paymentDispute?.paymentId;
    const disputeLost = paymentDispute?.disputeStatus === 'lost'
    let shortDate = "", fullDate = ""
    if(payment.paymentInitDt) {
      let arrayDate = Utils.convertISODate(new Date(payment.paymentInitDt), showTransactionDetails).split(" ")
      shortDate = arrayDate[0]
      if(showTransactionDetails)
      fullDate = `${arrayDate[1]} ${arrayDate[2]} `
    }

    const wasPaymentMethodDeleted = (payment?.paymentType?.paymentTypeId === PAYMENT_TYPES.RECURRING.paymentTypeId) && (!payment?.paymentMethodUsed?.cardPayment && !payment?.paymentMethodUsed?.ach)

    return (
        <Grid container spacing={2} data-testid="transaction-children">
            <Grid item xs={2}>
                <Typography variant="body2">{shortDate}</Typography>
                <Typography variant="body2">
                    {showTransactionDetails && fullDate}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                {!wasPaymentMethodDeleted && (
                    <Typography
                        variant="body2"
                        color={hasDispute ? 'error' : 'textSecondary'}
                    >
                        {Utils.getPaymentTypeName(payment, hasDispute)}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={2}>
                <>
                    {showStatus(payment) && (
                        <Typography variant="body2" className="pl-3">
                            {(payment?.paymentStatusFailed && payment?.externalResponse) ? (
                                <Tooltip
                                    title={handleFailedResponse(
                                        payment?.externalResponse!
                                    )}
                                >
                                    <div>
                                        <Status
                                            text={
                                                getStatusText(
                                                    payment,
                                                    showTransactionDetails
                                                ) || ''
                                            }
                                            statusColor={
                                                (payment?.paymentStatusSuccess || payment?.paymentStatus === 'Success') &&
                                                payment?.isCaptured &&
                                                !releasePaymentStatusMsg.errorMsg
                                                    ? StatusColors.success
                                                    : (payment?.paymentStatusPending ||
                                                          !payment?.isCaptured) &&
                                                      !payment?.paymentStatusFailed
                                                    ? StatusColors.warning
                                                    : StatusColors.error
                                            }
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Status
                                    text={
                                        getStatusText(
                                            payment,
                                            showTransactionDetails
                                        ) || ''
                                    }
                                    statusColor={
                                      (payment?.paymentStatusSuccess || payment?.paymentStatus === 'Success') &&
                                        payment?.isCaptured &&
                                        !releasePaymentStatusMsg.errorMsg
                                            ? StatusColors.success
                                            : (payment?.paymentStatusPending ||
                                                  !payment?.isCaptured) &&
                                              !payment?.paymentStatusFailed
                                            ? StatusColors.warning
                                            : StatusColors.error
                                    }
                                />
                            )}
                        </Typography>
                    )}
                    {hasDispute && showTransactionDetails && !disputeLost && (
                        <>
                            <Typography variant="body2" color="error">
                                {'Dispute status: ' +
                                    paymentDispute?.disputeStatus}
                            </Typography>
                            <Typography variant="body2" color="error">
                                {'Reason: ' + paymentDispute?.disputeReason}
                            </Typography>
                        </>
                    )}
                    {showTransactionDetails && wasPaymentMethodDeleted && (
                        <Typography variant="body2" color="error">
                            No Recurring Payment Method in Stripe
                        </Typography>
                    )}
                </>
                <Typography
                    variant="body2"
                    className="pl-2"
                    color={
                        hasDispute && !disputeLost ? 'error' : 'textSecondary'
                    }
                >
                    {showTransactionDetails && !wasPaymentMethodDeleted
                        ? Utils.getTransactionDetails(payment)
                        : ''}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2">
                    {formatNumberToUSD(payment?.paymentAmt)}
                </Typography>
                <Typography variant="body2" className="break-word">
                    {showTransactionDetails &&
                        Utils.getExternalPaymentId(payment)}
                </Typography>
                <Typography variant="body2" className="break-word">
                    {showTransactionDetails &&
                        hasDispute &&
                        !disputeLost &&
                        paymentDispute?.externalDisputeId}
                </Typography>
            </Grid>
            <Grid item xs={isAccountHolder ? 3 : 2}>
                <Typography variant="body2">
                    {Utils.getTransactionType(payment)}
                </Typography>
            </Grid>
            {transactions &&
            !isAccountHolder&&
            transactionSuccess&&
            !disallowRefund?(
            <Grid item xs={1} style={{marginTop: -12, maxHeight: 10}}>
              <IconButton
              aria-haspopup='true'
              onClick={e => {
                const isPaymentTypeCard =
                  payment?.paymentMethodUsed
                    ?.paymentMethodTypeId ===
                  PAYMENT_METHOD_TYPES.CARD
                    .paymentMethodTypeId;
                const isPaymentCaptured =
                  payment?.isCaptured;
                handleRefundMenuClick(
                  e,
                  payment?.paymentId,
                  isPaymentTypeCard,
                  isPaymentCaptured,
                  payment
                );
                {
                  paymentDispute ? setTransactionId(paymentDispute.paymentDisputeId)
                    : payment ? setTransactionId(payment?.paymentId)
                      : setTransactionId(0)
                }
              }}
              size='large'
              >
                <MoreHorizIcon/>
              </IconButton>
            </Grid>):
            !paymentReversal?.paymentId&&
            !isAccountHolder &&
            payment?.paymentStatus !== PaymentStatus.failed &&
            !disallowRefund &&
            !hasDispute &&(
              <Grid item xs={1} style={{marginTop: -12, maxHeight: 10}}>
                     <IconButton
                          aria-haspopup="true"
                          onClick={e => {
                              const isPaymentTypeCard =
                                  payment?.paymentMethodUsed
                                      ?.paymentMethodTypeId ===
                                  PAYMENT_METHOD_TYPES.CARD
                                      .paymentMethodTypeId;
                              const isPaymentCaptured =
                                  payment?.isCaptured;
                              handleRefundMenuClick(
                                  e,
                                  payment?.paymentId,
                                  isPaymentTypeCard,
                                  isPaymentCaptured,
                                  payment
                              ); 
                          }}
                          size="large"
                      >
                          <MoreHorizIcon/>
                      </IconButton>
              </Grid>
          ) }
        </Grid>
    );
  }

  const paymentDisputeListItem = (payment: Payment, paymentDispute: PaymentDispute) => {
    let shortDate = "", fullDate = ""
    if(paymentDispute.disputeCloseDt) {
      let arrayDate = Utils.convertISODate(new Date(paymentDispute.disputeCloseDt), showTransactionDetails).split(" ")
      shortDate = arrayDate[0]
      if(showTransactionDetails)
      fullDate = `${arrayDate[1]} ${arrayDate[2]} `
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="body2">
            {shortDate}
          </Typography>
          <Typography variant="body2">
            {showTransactionDetails && fullDate}
          </Typography>
        </Grid>
        <Grid item xs={1}>
            <Typography variant="body2">
              Dispute
            </Typography>
          </Grid>
        <Grid item xs={2}>
          <>
            {(showStatus(payment)) && (
              <Typography variant="body2" className="pl-3">
                <Status
                  text={isAccountHolder ? 'Dispute Won' : 'Dispute Lost'}
                  statusColor={
                    isAccountHolder ? StatusColors.success : StatusColors.error
                  }
                />
              </Typography>
            )}
            {showTransactionDetails && (
              <Typography variant="body2">
                {'Reason: ' + paymentDispute?.disputeReason}
              </Typography>
            )}
          </>
          <Typography variant="body2" className="pl-2">
            {showTransactionDetails ?
              Utils.getTransactionDetails(payment) : ""
            }
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="error">
            {formatNumberToUSD(paymentDispute?.disputeAmt)}
          </Typography>
          <Typography variant="body2" className="break-word">
            {showTransactionDetails && (
              paymentDispute?.externalDisputeId
            )}
          </Typography>
        </Grid>
        <Grid item xs={isAccountHolder ? 3:2}>
          <Typography variant="body2">
            {Utils.getTransactionType(payment)}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const reversedTransactionsListItem = (payment: Payment, paymentReversal: PaymentReversal) => {
    let shortDate = "", fullDate = ""
    if(paymentReversal?.reversalDate) {
      let arrayDate = Utils.convertISODate(new Date(paymentReversal.reversalDate), showTransactionDetails).split(" ")
      shortDate = arrayDate[0]
      if(showTransactionDetails)
      fullDate = `${arrayDate[1]} ${arrayDate[2]} `
    }
    // "To do: Remove `reservalAmount` and use `reservalAmt` after rebuilding the transaction Java API in Node.js."
    const amount = (paymentReversal?.reversalAmount ?? paymentReversal?.reversalAmt) ?? 0;
    return (
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="body2">
            {shortDate}
          </Typography>
          <Typography variant="body2">
            {showTransactionDetails && fullDate }
          </Typography>
        </Grid>
        <Grid item xs={1} >
            <Typography variant="body2" color="textSecondary">
              {Utils.getPaymentTypeName(payment)}
            </Typography>
          </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" className="pl-2">
              <Status
                text={getStatusTextReversalItem(payment)}
                statusColor={
                  payment?.paymentStatus === PaymentStatus.success ? StatusColors.success :
                  (payment?.paymentStatus === PaymentStatus.pending ? StatusColors.warning
                  : StatusColors.error)
                }
              />
          </Typography>
        <Typography variant="body2" className="pl-2">
            {showTransactionDetails ?
              Utils.getTransactionDetails(payment) : ""
            }
          </Typography>
        </Grid>
        <Grid item xs={4} style={{maxHeight: 10}}>
          <Typography variant="body2" color="error">
            {formatNumberToUSD(amount && -Math.abs(amount))}
          </Typography>
          <Typography variant="body2" className="break-word">
            {showTransactionDetails && (
              paymentReversal?.externalReversalId
            )}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            {Utils.getTransactionType(payment)}
          </Typography>
        </Grid>
        {((paymentReversal?.paymentId) && (!isAccountHolder)) && (
          <Grid item xs={1} style={{marginTop: -12, maxHeight: 10}}>
            <>
              <IconButton
                aria-haspopup="true"
                onClick={(e) => {
                  const isPaymentTypeCard = (payment?.paymentMethodUsed?.paymentMethodTypeId === PAYMENT_METHOD_TYPES.CARD.paymentMethodTypeId)
                  const isPaymentCaptured = payment?.isCaptured
                  handleRefundMenuClick(e, payment?.paymentId, isPaymentTypeCard, isPaymentCaptured, payment)
                  setTransactionId(paymentReversal?.paymentReversalId)
                }}
                size="large">
                <MoreHorizIcon />
              </IconButton>
            </>
          </Grid>
        )}
      </Grid>
    );
  }

  const transactionsHeader = (
    <Grid container spacing={2} className="pl-4 pb-1">
      <Grid container spacing={2} className="header px-1 mb-1">
        <Grid item xs={2}>
          <Typography variant="h4" >
            Initiated Date
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h4">
            Type
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4" className="pl-3">
            Status / Source
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4">
            Amount / ID
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4" >
            Transaction
          </Typography>
          <Typography variant="h4">
            Type
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const pfrAdjustmentsHeader = (
    <Grid container className="header mb-5 pb-2" spacing={2}>
      <Grid item xs={5}/>
      <Grid item xs={3}>
        <Typography variant="h4">
          PFR Adjustments
        </Typography>
      </Grid>
      <Grid item xs={4}/>
    </Grid>
  )

  const pfrAdjustmentListItem = (pfrAdjustment: PFRAdjustment, index: number) => {
    let arrayDate = Utils.convertISODate(new Date(pfrAdjustment.adjustmentDt), showTransactionDetails).split(" ")
    const shortDate = arrayDate[0];
    const fullDate = `${arrayDate[1]} ${arrayDate[2]}`;
    return (
    <Grid container spacing={2} key={index}>
      <Grid item xs={2}>
        <Typography variant="body2">
          {shortDate}
        </Typography>
        <Typography variant="body2">
          {showTransactionDetails && fullDate}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body2">
          PFR
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2" className="pl-3">
          ---
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">
          {formatNumberToUSD(pfrAdjustment?.adjustmentAmt)}
        </Typography>
        <Typography variant="body2">
          {showTransactionDetails && pfrAdjustment?.patientPFRAdjustmentId}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2">
          Adjustment
        </Typography>
      </Grid>
    </Grid>
    )
  };

  const balanceInfo = (
    <Grid
      container
      spacing={2}
      className="px-2"
    >
      <Grid item xs={12} className="mt-6">
        <Divider />
      </Grid>
      <Grid item xs={8} className="">
        <Typography variant="subtitle2">
          Estimated PFR
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end" className="">
          <Typography variant="subtitle2">
            {formatNumberToUSD(selectedEncounter?.pfrAmt)}
          </Typography>
        </Box>
      </Grid>
      {(doPFRAdjustmentsExist) &&
        <>
          <Grid item xs={8} className="">
            <Typography variant="subtitle2">
              Adjusted PFR
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end" className="">
              <Typography variant="subtitle2">
                {formatNumberToUSD(adjustedPFR)}
              </Typography>
            </Box>
          </Grid>
        </>
      }
      <Grid item xs={8} className="">
        <Typography variant="subtitle2" test-id="pending-remaining-balance">
          Pending Remaining Balance
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end" className="">
          <Typography variant="subtitle2">
            {formatNumberToUSD(patientPaymentProgram?.patientPaymentSchedule.pfrPendingBalance)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} className="">
        <Typography variant="subtitle2" test-id="remaining-balance">
          Remaining Balance
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end" className="">
          <Typography variant="subtitle2">
            {formatNumberToUSD(patientPaymentProgram?.patientPaymentSchedule.pfrCapturedBalance)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )

  useEffect(() => {
    if (!isLoading && groupedTransactions && (groupedTransactions?.length > 0)) {
      let fullTable = [];
      let rowValues: string[] = []
      rowValues.push('Date/Time', 'Type', 'Status/Source', 'Amount/Id', "Transaction Type")
      fullTable.push(rowValues)
      groupedTransactions.forEach((transaction: Transaction) => {

        let shortDate = "", fullDate = "", shortDateReversal = "", fullDateReversal = ""
        if (transaction?.payment?.paymentInitDt) {
          let arrayDate = Utils.convertISODate(new Date(transaction.payment.paymentInitDt), showTransactionDetails).split(" ")
          shortDate = arrayDate[0]
          if (showTransactionDetails) {
            fullDate = `${arrayDate[1]} ${arrayDate[2]} `
          }
        }
        if (transaction?.paymentReversal?.reversalDate) {
          let arrayDateReversal = Utils.convertISODate(new Date(transaction.paymentReversal.reversalDate), showTransactionDetails).split(" ")
          shortDateReversal = arrayDateReversal[0]
          if (showTransactionDetails) {
            fullDateReversal = `${arrayDateReversal[1]} ${arrayDateReversal[2]} `
          }
        }
        rowValues = [];
        const payment = transaction?.payment;
        const paymentReversal = transaction?.paymentReversal;
        const status = showStatus(payment) ? (payment?.isCaptured || payment?.paymentMethodUsed?.ach) ? payment?.paymentStatus || '' : "Uncaptured" : ""
        const cardInfo = Utils.getTransactionDetails(payment);
        const date = !showTransactionDetails ? `${shortDate}`:`${shortDate} \n ${fullDate}`
        const reversalDate = !showTransactionDetails ? `${shortDateReversal}`:`${shortDateReversal} \n ${fullDateReversal}`
        const amountInfo = !showTransactionDetails
          ? `${formatNumberToUSD(payment?.paymentAmt)}` : `${formatNumberToUSD(payment?.paymentAmt)} \n ${Utils.getExternalPaymentId(payment)}`
        if(transaction?.payment?.paymentId) {
          const paymentInfo = !showTransactionDetails ? `${status}` : `${status} \n ${cardInfo}`
          rowValues.push(
            `${date}`,
            `${payment ? Utils.getPaymentTypeName(payment): ''}`,
            `${(paymentInfo || '')}`,
            `${(amountInfo || '')}`,
            `${(payment ? Utils.getTransactionType(payment) : '')}`
          )
          fullTable.push(rowValues);
        }

        // also a row if the transaction has a reversal/refund
        if (paymentReversal?.paymentReversalId) {
        const paymentInfo = !showTransactionDetails ? `Refund` : `Refund \n ${cardInfo}`
          rowValues = [];
          rowValues.push(
            `${reversalDate}`,
            `${payment ? Utils.getPaymentTypeName(payment): ''}`,
            `${(paymentInfo  || '' )}`,
            `${(amountInfo || '')}`,
            `${(payment ? Utils.getTransactionType(payment) : '')}`
          )
          fullTable.push(rowValues);
        }
      })

      dispatch(setTransactionsCSVData(fullTable));
    }
    // eslint-disable-next-line
  }, [transactions, isLoading, showTransactionDetails])


  const initialValues = {
    showDetailedTransactions: false,
  }

  const validationSchema = Yup.object({
    showDetailedTransactions: Yup.boolean(),
  })

  function checkIfIsValid(value: { showDetailedTransactions: boolean }) {
    setShowTransactionsDetails(value.showDetailedTransactions);
    validationSchema
      .validate(value)
  }

  const disabledDownloadButton = (<Button
      type="text"
      icon={<GetAppIcon />}
      paddingLeft={0}
      disabled
    >
      Download File
    </Button>)
  
  const disabledPatientLedgerButton = (
    <IconButton
      color="primary"
      style={{ fontSize: "1.5rem", float: 'right', paddingRight: '6px', paddingTop: '7px' }}
      disabled
    >
      <OpenInNewIcon fontSize="small" style={{paddingRight: '6px'}} /> View Master Patient Ledger
    </IconButton>
  )

  const getMenuButtons = () => {
    const currentTransaction = transactions && transactions.find(
      (transaction) =>
        transaction.payment?.paymentId === transactionId ||
        transaction.paymentReversal?.paymentReversalId === transactionId
    );
    const isTransactionSuccess = 
      currentTransaction?.payment?.paymentStatus === PaymentStatus.success &&
      currentTransaction?.payment?.isCaptured;

    if (disputeIdArray.includes(transactionId)){
      return (
        <MenuItem onClick={() => navigate(`/specialist/dashboard/dispute/${transactionId}`)} className="danger">
        Dispute
      </MenuItem>
      )
    } else if (!reversalIdArray.includes(transactionId) && !reversalPaymentOriginArray.includes(transactionId)) {
      return (
        <>
          <MenuItem onClick={() => handleMenuClose('refund')} className="danger">
            {optionTexts.menuText}
          </MenuItem>
          {isTransactionSuccess && <MenuItem onClick={() => handleMenuClose('resend receipt')} className="danger">
            {resendReceiptButton.menuText}
          </MenuItem>}
        </>
      );
    }else if (transactionSuccess) {
      return (
        isTransactionSuccess && <MenuItem onClick={() => handleMenuClose('resend receipt')} className="danger">
          {resendReceiptButton.menuText}
        </MenuItem>
      )
    }
    return null;
  };  

  return <>
    <div className="pb-7 px-1">
      <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Send Ledger" isAccountHolderPortal={true}
        renderNoAccess={() => (disabledDownloadButton)}
      >
        {!isLoading && !isTransactionsFetchError && ((groupedTransactions?.length > 0)) ? (
          <CSVLink data={transactionsCSVData} filename={'Transactions_Data.csv'}>
          <Button
            type="text"
            icon={<GetAppIcon />}
            paddingLeft={0}
          >
            Download File
          </Button>
        </ CSVLink>
        ) : (disabledDownloadButton)
      }
      </AccessControl>
      <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Patient Ledger" isAccountHolderPortal={true}
        renderNoAccess={() => (disabledPatientLedgerButton)}
      >
        <IconButton
            color="primary"
            style={{ fontSize: "1.5rem", float: 'right', paddingRight: '6px', paddingTop: '7px' }}
            onClick={() =>
              handlePatientLedgerClick(selectedEncounter?.clientId, selectedEncounter?.patientId)
            }
          >
            <OpenInNewIcon fontSize="small" style={{paddingRight: '6px'}} /> View Master Patient Ledger
          </IconButton>
      </AccessControl>
      {!isAccountHolder && (
        <Grid container className="my-3 ml-2">
          <Grid xs={12} item>
            <Typography variant="h3" className="mb-2">
              Details
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validate={checkIfIsValid}
              onSubmit={() => {}}
            >
              {(formik) => (
                <Form>
                  <div className="mb-6">
                    <Toggle
                      name="showDetailedTransactions"
                      formik={formik}
                      value={formik.values.showDetailedTransactions}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      )}
    </div>
    <div className="list-header documents-accordion px-4 pb-4">
      {isTransactionsFetchError ? (
        <Grid container direction="row">
          <Grid item xs={10}>
            <Typography variant="h4">
              Transactions Pending. Use Refresh button to update.
            </ Typography>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end" marginTop="-1.2rem" marginRight="-1.5rem">
              <IconButton onClick={() => handleRefreshTransactions()} size="large">
                <RefreshIcon className="icon" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          {isLoading ? (
            <LoadingOverlay whiteBackground />
            ) : (
              <div className="px-1">
                {transactionsHeader}
                <div className="striped-row-container" data-testid="transaction-list">
                  {(groupedTransactions && groupedTransactions?.length > 0) ? groupedTransactions.map((transaction: Transaction, index: number) => (
                    <React.Fragment key={transaction?.payment?.paymentId}>
                      {transactionsListItem(transaction?.payment, transaction?.paymentReversal, transaction?.paymentDispute)}
                      {transaction?.paymentReversal?.paymentReversalId &&
                        reversedTransactionsListItem(transaction?.payment, transaction?.paymentReversal)
                      }
                      {transaction?.paymentDispute?.paymentDisputeId && (transaction?.paymentDispute?.disputeStatus === 'lost') &&
                        paymentDisputeListItem(transaction?.payment, transaction?.paymentDispute)
                      }
                    </React.Fragment>
                  )) : (
                    <Typography className="mt-4" variant="h4">There are no transactions available</Typography>
                  )}
                </div>
                  {doPFRAdjustmentsExist && (
                    <React.Fragment>
                      {pfrAdjustmentsHeader}
                      <div className="striped-row-container">
                        {pfrAdjustments && pfrAdjustments.map((pfrAdjustment: PFRAdjustment, index: number) => pfrAdjustmentListItem(pfrAdjustment, index))}
                      </div>
                    </React.Fragment>
                  )}
              </div>
            )}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => handleMenuClose()}
            >
              <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Add Instance of Care"
                renderNoAccess={() => <MenuItem disabled>danger</MenuItem>}>
                {getMenuButtons()}
              </AccessControl>
            </Menu>
        </>
      )}
    </div>
    {balanceInfo}
    {isReceiptModalOpen && (
      <ResendReceiptModal
        open={isReceiptModalOpen}
        receiptEmail={selectedPatient?.contact.email}
        titleText='Resend Receipt?'
        onSend={handleResendReceipt}
        onClose={()=> setIsReceiptModalOpen(false)}
      />
    )}
    {isRefundModalOpen && (
      <DeleteModal
        open={isRefundModalOpen}
        title={optionTexts.deleteModalTitle}
        subTitle="This action cannot be undone"
        okButtonText= {`${optionTexts.deleteModalTitle}`}
        handleDeleteCancel={() => setIsRefundModalOpen(false)}
        handleDeleteOk={handleReleaseFunds}
      />
    )}
  </>;
}
