import { Button, TextField } from '@finpay-development/shared-components';
import { Grid, Paper } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams} from 'react-router';
import * as Yup from 'yup';

import { setIsPublicMode, setIsPublicModeLoggedIn } from '../../admin/state/users/admin-slice';
import { RootState } from '../../shared/state/root-reducer';
import { AppDispatch } from '../../shared/state/store';
import { Utils } from '../../shared/utils';
import { getStateByName, getStateByAbbrev } from '../../shared/misc/us-states';
import classes from '../components/PayNowOverview.module.css';
import { GuestForm, guestInfo, GuestModel, GuestParameterInfo } from '../models/anonymous-guest';
import {
  setEmailLookupAuthToken,
  setIsExistingPatient,
  setIsInvalidForPayNow,
  setIsPayNowOverviewActive,
  setLaunchToken,
  setSelectedGuest,
  setIsChannel,
  setChannelType, 
  setPatientInfo,
  setIsMobileConverted,
  setAuthToken
} from '../state/guest-slice';
import {
  checkEmail,
  getAnonymousRequestToken, getClientFacility,
  getConfig,
  getEmailLookupAuthToken,
  getLaunchPayload,
  getPaymentChannels
} from '../state/guest-thunk';
import { setSelectedPatientV2, setSelectedEncounter } from 'src/patient/state/patient-slice';
import {paynowService} from '../services/paynow-service';

const PayNowOverview = () => {
  const params = useParams<{ token?: string}>()
  const [enableContinueButton, setEnableContinueButton ] = useState(false);

  const stateFields = {
    selectedGuest: useSelector((state: RootState) => {
      return state.guestContext.selectedGuest;
    }),
    bannerText: useSelector((state: RootState) =>  state.guestContext?.bannerText),
    isPublicModeLoggedIn: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.isPublicModeLoggedIn
    ),
    isPublicMode: useSelector(
        (state: RootState) => state.adminContext.adminUserContext.isPublicMode
    ),
    userIsAuthenticated: useSelector(
        (state: RootState) => state.userContext.userIsAuthenticated
    ),
    configId: useSelector((state: RootState) =>  state.guestContext?.configId),
    configIdSource: useSelector((state: RootState) => state.guestContext?.configIdSource),
    guestContext: useSelector((state: RootState) => state.guestContext),
    authToken: useSelector((state: RootState) =>  state.guestContext?.authToken),
    isLoadingAuthToken: useSelector((state: RootState) =>  state.guestContext?.isLoading.getAuthToken),
    launchToken: useSelector((state: RootState) =>  state.guestContext?.launchToken),
    isExistingPatient: useSelector((state: RootState) =>  state.guestContext?.isExistingPatient),
    isConfigLoadComplete: useSelector((state: RootState) =>  state.guestContext?.isConfigLoadComplete),
    emailLookupAuthToken: useSelector((state: RootState) =>  state.guestContext?.emailLookupAuthToken),
    isPayNowOverviewActive: useSelector((state: RootState) =>  state.guestContext?.isPayNowOverviewActive),
    isInvalidForPayNow: useSelector((state: RootState) =>  state.guestContext?.isInvalidForPayNow),
    isErrorGetConfig: useSelector((state: RootState) =>  state.guestContext?.isError?.getConfig),
    isChannel: useSelector((state: RootState) => state.guestContext?.isChannel),
    urlChannelParams: useSelector((state: RootState) => state.adminContext?.adminUserContext.urlParameters),
    clientFacilityDetails: useSelector((state: RootState) => state.guestContext.clientFacilityDetails),
    channelType: useSelector((state: RootState) => state.guestContext.channelType),
  }
  const { selectedGuest, configId, configIdSource, authToken, isExistingPatient, isConfigLoadComplete, emailLookupAuthToken, isPayNowOverviewActive, launchToken, isInvalidForPayNow, bannerText, isChannel, urlChannelParams, clientFacilityDetails, channelType, isErrorGetConfig } = stateFields;

  const currentGuest = { ...selectedGuest } as GuestModel;

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (isInvalidForPayNow) {
      window.location.href = window.location.href.substring(0, window.location.href.indexOf('/webpayment'));
    }
  }, [
    isInvalidForPayNow
  ]);

  useEffect(() => {
    if (isPayNowOverviewActive) {
      if (!authToken) {
        dispatch(getAnonymousRequestToken());
      }
    } else {
      dispatch(setIsPublicModeLoggedIn(false));
      dispatch(setIsExistingPatient(undefined));
      dispatch(setIsPayNowOverviewActive(true));
    }
    checkIfIsValid(formRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPayNowOverviewActive
  ]);

  useEffect(() => {
    function getLaunchToken() {
      if (!params.token) {
        dispatch(setIsInvalidForPayNow(true));
      } else {
        dispatch(setLaunchToken(params.token));
      }
    }
    if (!launchToken) {
      getLaunchToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    launchToken
  ]);

  useEffect(() => {
    const getLaunchPayloadFromEncrypted = async () => {
      dispatch(getLaunchPayload(({"encryptedValue": launchToken,  "authToken": authToken})));
    }

    if (launchToken && !configId && authToken) {
      getLaunchPayloadFromEncrypted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    launchToken, configId, authToken
  ]);

  useEffect(() => {
    const checkForExistingEmail = async () => {
      if(clientFacilityDetails.clientId){
        dispatch(checkEmail(({
          clientId: clientFacilityDetails.clientId,
          email: currentGuest.email,
          authToken: emailLookupAuthToken,
        })));
      }
    }
    if (emailLookupAuthToken) {
      checkForExistingEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    emailLookupAuthToken
  ]);

  useEffect(() => {
    const fetchConfigs = async () => {

      let patientEncounter;
      let patient;
      if(channelType === 'mobile'){
        //configId is patientEncounerId. Need to get facilityId
        patientEncounter = await paynowService.getPatientEncounter(+configId, authToken);
        patient = await paynowService.getPatient(+patientEncounter.patientId, authToken);
        dispatch(setSelectedPatientV2(patient));
        dispatch(setSelectedEncounter(patientEncounter));
      }
      const facilityId = patientEncounter?.facilityId ? patientEncounter.facilityId : configId

      const responseData = await
      Promise.all([
        dispatch(getConfig({"configId": facilityId, "configIdSource": configIdSource, "configKey": "BANNER_TEXT", "authToken": authToken})),
        dispatch(getConfig({"configId": facilityId, "configIdSource": configIdSource, "configKey": "ACCT_HELP_TEXT", "authToken": authToken})),
        dispatch(getConfig({"configId": facilityId, "configIdSource": configIdSource, "configKey": "ACCT_ID", "authToken": authToken})),
        dispatch(getPaymentChannels({"authToken": authToken})),
        dispatch(getClientFacility({"facilityId": facilityId, "authToken": authToken}))
      ]);
      // Handling URL Paramters for Omni Channel after ConfigId is set
      if (isChannel) {
        const updatedSelectedGuest = Object.assign(
          Utils.deepClone(selectedGuest), Utils.deepClone(urlChannelParams)
        );
        const updatedGuestParams = handleStateParam(updatedSelectedGuest);
        dispatch(setIsPublicModeLoggedIn(true));
        dispatch(setIsPublicMode(true));
        dispatch(setIsChannel(true));
        dispatch(setChannelType(updatedSelectedGuest.ch));
        dispatch(setSelectedGuest(updatedGuestParams));
        dispatch(setAuthToken(authToken));

        if(channelType === 'mobile'){
          dispatch(setPatientInfo({
            firstName: patient.contact.firstName,
            lastName: patient.contact.lastName,
            mobileNumber: patient.contact.primPhoneType === 'mobile' ? patient.contact.primPhoneNum : patient.contact.secPhoneNum,
            email: patient.contact?.email ? patient.contact?.email : "",
            clientName: patientEncounter.clientName,
            patientEncounterId: patientEncounter.patientEncounterId,
            patientId: patientEncounter.patientId,
            pfrAmt: patientEncounter.pfrAmt
          }))
          dispatch(setIsMobileConverted(patientEncounter.isConverted));

          if (responseData[4]?.payload?.client?.paymentProgramVersion === 2
            || responseData[4]?.payload?.client?.paymentProgramVersion === 3) {
            navigate('/webpayment/mobile-payment-program');
          } else {
            navigate('/webpayment/mobile-payment');
          }
        }else{
          navigate('/webpayment/billing-info');
        }
      }
    }
    if (authToken && configId && !isConfigLoadComplete && !isErrorGetConfig) {
      fetchConfigs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authToken, configId
  ]);

  useEffect(() => {
    if ((isExistingPatient !== undefined) && (isPayNowOverviewActive)) {
      if (isExistingPatient) {
          // navigate to landing page with message to login to Patient Portal
          dispatch(setIsPublicModeLoggedIn(false));
          if(!isChannel) navigate('/webpayment');
       } else {
          // continue to Billing Info page
          dispatch(setIsPublicModeLoggedIn(true));
          if(!isChannel) navigate('/webpayment/billing-info');
       }
       dispatch(setEmailLookupAuthToken(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isExistingPatient
  ]);

  // Hook for calling <Formik>
  const formRef: any = useRef();
  const initialGuestInfo = {
    email: selectedGuest?.email,
    firstName: selectedGuest?.firstName,
    lastName: selectedGuest?.lastName,
    confirmEmail: selectedGuest?.email
  };

  const validationGuestSchema = Yup.object(guestInfo);

  //guest login
  function handleContinue(guestValues: GuestForm) {
    currentGuest.email = guestValues?.email?.trim();
    currentGuest.firstName = guestValues?.firstName?.trim();
    currentGuest.lastName = guestValues?.lastName?.trim();
    dispatch(setSelectedGuest(currentGuest));
    dispatch(getEmailLookupAuthToken());
  }

  function checkIfIsValid(value: {}) {
    validationGuestSchema
      .validate(value)
      .then(() => {
        setEnableContinueButton(true);
      })
      .catch((err) => {
        setEnableContinueButton(false);
      });
  }

  function handleStateParam(currentGuestInfo: GuestParameterInfo) {   
    let updatedParams = Utils.deepClone(currentGuestInfo);
    const isStateParamNaN = isNaN(updatedParams.billingState);
    if (isStateParamNaN) {
      if (updatedParams.billingState.length === 2) {
        const stateToSaveCode = getStateByAbbrev(updatedParams.billingState);
        stateToSaveCode !== undefined ? updatedParams.billingState = stateToSaveCode.stateId : updatedParams.billingState = '';
      } else {
        const stateToSave = getStateByName(updatedParams.billingState);
        stateToSave !== undefined ? updatedParams.billingState = stateToSave.stateId : updatedParams.billingState = ''
        // TODO: Add condition for handling a misspelled State -- using Smartystreets zipCode API
      }
    } 
    return updatedParams;
  }

  return <>
    {bannerText && !isChannel && (
      <Paper className={classes.splashStyle}
      >

        <Formik
          innerRef={formRef}
          initialValues={initialGuestInfo}
          enableReinitialize={true}
          validate={checkIfIsValid}
          validationSchema={validationGuestSchema}
          onSubmit={(values) => handleContinue(values)}
        >
          {(formik) => (
            <Form>
              <Grid
                container
                spacing={1}
                direction="row"
                style={{ paddingTop: "5em"}}
              >
                <Grid item direction="column" xs={6}
                  style={{
                    paddingLeft: "35px",
                    paddingTop: "1em"
                  }}>
                  <TextField
                    required
                    name="firstName"
                    label="First Name"
                    variant="outlined"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="First Name"
                    error={
                      formik.touched["firstName"] &&
                      formik.errors["firstName"]
                    }
                  />
                </Grid>
                <Grid
                  item
                  direction="column"
                  xs={6}
                  style={{
                    paddingRight: "35px",
                    paddingTop: "1em",
                    paddingLeft: "15px"
                  }}
                >
                  <TextField
                    required
                    label="Last Name"
                    name="lastName"
                    variant="outlined"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Last Name"
                    helperText=""
                    error={
                      formik.touched["lastName"] && formik.errors["lastName"]
                    }
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  direction="column"
                  xs={12}
                  style={{
                    paddingLeft: "35px",
                    paddingRight: "35px",
                    paddingTop: "2.5em"
                  }}
                >
                  <TextField
                    label="Email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Your Email Address"
                    helperText=""
                    error={formik.touched["email"] && formik.errors["email"]}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  direction="column"
                  xs={12}
                  style={{
                    paddingLeft: "35px",
                    paddingRight: "35px",
                    paddingTop: "2.5em"
                  }}
                >
                  <TextField
                    label="Confirm Email"
                    name="confirmEmail"
                    value={formik.values.confirmEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Re-enter Your Email Address"
                    helperText=""
                    error={formik.touched["confirmEmail"] && formik.errors["confirmEmail"]}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignContent="flex-end"
                justifyContent="flex-end"
                spacing={2}
                style={{ paddingTop: "50px" }}
              >
                <Grid
                  item
                  style={{
                    paddingBottom: "37px",
                    marginRight: "30px",
                  }}
                >
                  <Button
                    type="primary"
                    isSubmitButton
                    disabled={
                      !enableContinueButton || !formik.isValid
                    }
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    )}
  </>;
}
export default PayNowOverview;
