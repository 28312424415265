import '../../../../scss/components/_list-header.scss';

import { Button, DeleteModal, LoadingOverlay, Status } from '@finpay-development/shared-components';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccessControl from '../../../../security/components/access-control';
import { RolePageNames } from '../../../../security/model/role-page-names';
import { StatusColors } from '../../../../shared/enums';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { Utils } from '../../../../shared/utils';
import { downloadHelloSignDocument, helloSignReminder } from '../../../state/patient-thunk';
import { DocumentType } from '../../models/document-types';
import { FormattedDocument, PatientDocument } from '../../models/patient-document';
import { PatientEncounter } from '../../models/patient-encounter';
import { PatientS3document } from '../../models/patient-s3document';
import { SignatureStatus } from '../../models/signature-tracking';
import DocumentForwardModal from './document-forward-modal';
import { S3DocumentsListItem } from './document-s3-list-';
import DocumentsModal from './documents-modal';

type ElectronicMenuActions = "remind" | "details" | "forward";
type AttachedMenuActions = "details" | "delete";

interface DocumentAccordionContentsProps {
  documents: PatientDocument[];
  selectedEncounter: PatientEncounter;
  isAccountHolder?: boolean;
  s3Documents?: PatientS3document[],
}

export const DocumentsAccordionContents = (props: DocumentAccordionContentsProps) => {

  const { documents, selectedEncounter, isAccountHolder, s3Documents } = props;
  const authorizationDocumentStatus = selectedEncounter?.authorizationDocumentStatus;
  const helloSignDocs = documents?.filter((doc) => doc.documentLocationType.toLowerCase() === DocumentType.url); // electronic docs (helloSign docs)
  const attachedDocs = documents?.filter((doc) => doc.documentLocationType.toLowerCase() === DocumentType.s3); // attached docs (non-helloSign docs)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [electronicAnchorEl, setElectronicAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDocumentModelOpen, setDocumentModalOPen] = useState(false);
  const [isDocumentForwardModalOpen, setIsDocumentForwardModalOpen] = useState(false);

  const doPfrAdjustmentDocumentsExist = !!authorizationDocumentStatus?.doPfrAdjustmentDocumentsExist;
  const doPhiConsentDocumentsExist = !!authorizationDocumentStatus?.doPhiConsentDocumentsExist;
  const doFullPayDocumentsExist = !!authorizationDocumentStatus?.doFullPayDocumentsExist;

  const dispatch = useDispatch<AppDispatch>();

  const stateFields = {
    loading: useSelector((state: RootState) => {
      return state.patientContext.isLoading;
    }),
  }

  const { loading } = stateFields;

  const handleElectronicDocMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    documentId: number
  ) => {
    setElectronicAnchorEl(event.currentTarget);
  };

  const handleAttachedDocMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    documentId: number
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleElectronicMenuClose = (menuActionType?: ElectronicMenuActions) => {
    setElectronicAnchorEl(null);
    switch (menuActionType) {
      case "remind":
        dispatch(helloSignReminder(selectedEncounter));
        break;
      case "forward":
        setIsDocumentForwardModalOpen(true);
        break;
    }
  };

  const handleAttachedMenuClose = (menuActionType?: AttachedMenuActions) => {
    setAnchorEl(null);
    switch (menuActionType) {
      case "delete":
        setIsDeleteModalOpen(true);
        break;
    }
  };

  const doDocumentsExist = !(((attachedDocs?.length === 0 && helloSignDocs?.length === 0) && (!loading.patientDocuments))
  && (authorizationDocumentStatus?.pfrAdjustmentDocuments?.length === 0)
  && (authorizationDocumentStatus?.phiConsentDocuments?.length === 0)
  && (authorizationDocumentStatus?.spaDocuments?.length === 0))

  const handleDocumentClick = (documentId: any) => {
    if (documentId) {
        dispatch(
          downloadHelloSignDocument({
            patientId: selectedEncounter.patientId,
            encounterId: selectedEncounter.patientEncounterId,
            externalDocumentId: documentId,
          })
        );
    }
  }

  const areDocsSigned = () => {
    return selectedEncounter.patientPaymentProgram?.[selectedEncounter.patientPaymentProgram.length-1]?.areDocsSigned;
  }


  const handleToggleDocumentModal = (
      status: boolean
  ) => {
    setDocumentModalOPen(status);
  };

  const electronicDocumentsListItem = (document: PatientDocument) => (
    <Grid container spacing={2} className={"py-2 mb-2"}>
      <Grid item xs={3}>
        <Typography variant="body1">
          {Utils.convertDate(
            new Date(document.signatureTracking?.signatureRequestDt),
            true
          )}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          className={document?.documentLocation ? "link" : ""}
          color={document?.documentLocation ? "primary" : undefined}
          variant="body1"
          onClick={() =>
            handleDocumentClick(
              selectedEncounter?.patientDocument?.[0].signatureTracking
                ?.externalDocumentId
            )
          }
        >
          {document.documentName}
        </Typography>
      </Grid>
      <Grid item xs={3} style={{ maxHeight: 10 }}>
        <Status
          text={document.signatureTracking?.signatureStatus}
          statusColor={
            document.signatureTracking?.signatureStatus ===
            SignatureStatus.Available
              ? StatusColors.success
              : document.signatureTracking?.signatureStatus ===
                SignatureStatus.PendingEsign
              ? StatusColors.warning
              : document.signatureTracking?.signatureStatus ===
                SignatureStatus.SignatureCompleted
              ? StatusColors.success
              : StatusColors.error
          }
        />
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1" noWrap>
          {"N/A"}
        </Typography>
      </Grid>
      {!areDocsSigned() && !isAccountHolder && (
        <Grid item xs={1} style={{ marginTop: -12, maxHeight: 10 }}>
          <>
            <IconButton
              aria-haspopup="true"
              onClick={(e) =>
                handleElectronicDocMenuClick(e, document.patientDocumentId)
              }
              size="large"
            >
              <MoreHorizIcon />
            </IconButton>
          </>
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );

  const otherDocumentsHeader = (documentType: string) => (
    <Grid container spacing={2} className="pl-8 pb-4">
      <Grid item xs={12}>
        <Typography variant="h6">
          <Box fontWeight="fontWeightBold" className="pb-5" style={{marginLeft: -20}}>
            {`${documentType}`}
          </Box>
        </Typography>
      </Grid>
      <Grid container spacing={2} className="header px-1 mb-1">
        <Grid item xs={3}>
          <Typography variant="h4">
            Date
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h4">
            {'Document Type'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h4">
            {'Status'}
          </Typography>
        </Grid>
        <Grid item xs={3} className="pb-2">
          <Typography variant="h4">
            {'Uploaded By'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const attachedDocumentsListItem = (document: PatientDocument) => (
    <Grid container spacing={2} className={"py-2 mb-2"}>
      <Grid item xs={3}>
        <Typography variant="body1">
          {Utils.convertDate(new Date(document.signatureTracking.signatureRequestDt), true)}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          className={document?.documentLocation ? "link" : ""}
          color={document?.documentLocation ? "primary" : undefined}
          variant="body1"
          onClick={() => handleDocumentClick(document?.documentLocation)}
        >
          {document?.documentName}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1">
          {document?.documentStatus}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1">
          {'N/A'}
        </Typography>
      </Grid>
      {areDocsSigned() && <Grid item xs={1} style={{marginTop: -12, maxHeight: 10}}>
        <>
          <IconButton
            aria-haspopup="true"
            onClick={(e) => handleAttachedDocMenuClick(e, document.patientDocumentId) }
            size="large">
            <MoreHorizIcon />
          </IconButton>
        </>
      </Grid>}
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  )

  const authorizationDocumentsListItem = (document: FormattedDocument, documentType: string, documentStatusColor: StatusColors, documentText: string) => (
    <Grid container spacing={2} className="pb-4">
      <Grid item xs={3}>
        <Typography variant="body1">
          {document?.lastUpdateDt ? Utils.convertDate(new Date(document.lastUpdateDt), true) : 'No Date'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          className={(document?.externalSignatureRequestId && document?.documentStatus === SignatureStatus.signed) ? "link" : ""}
          color={(document?.externalSignatureRequestId && document?.documentStatus === SignatureStatus.signed) ? "primary" : undefined}
          variant="body1"
          onClick={() => handleDocumentClick(document.externalSignatureRequestId)}
        >
          {documentType}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Status
          typographyVariant="body1"
          statusColor={documentStatusColor}
          text={documentText}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography>
          {'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  )

  return (
    <>
      <div className="list-header documents-accordion px-4 pb-4">
        {loading.patientDocuments && <LoadingOverlay whiteBackground />}
        {!loading.patientDocuments && doDocumentsExist && (
          <>
            {otherDocumentsHeader("Electronic Documents")}
            {helloSignDocs?.length > 0 &&
              helloSignDocs.map((document: PatientDocument, index: number) => (
                <div key={document.patientDocumentId}>
                  {electronicDocumentsListItem(document)}
                </div>
              ))}
            {attachedDocs?.length > 0 &&
              attachedDocs.map((document: PatientDocument, index: number) => (
                <div key={document.patientDocumentId}>
                  {attachedDocumentsListItem(document)}
                </div>
              ))}
            {authorizationDocumentStatus?.phiConsentDocuments?.length > 0 &&
              authorizationDocumentsListItem(
                authorizationDocumentStatus.phiConsentDocuments[0],
                "PHI Consent Document",
                Utils.getDocumentStatusColor(
                  doPhiConsentDocumentsExist,
                  doPhiConsentDocumentsExist
                    ? authorizationDocumentStatus?.phiConsentDocuments[0]
                        ?.displayedDocumentStatus
                    : ""
                ),
                doPhiConsentDocumentsExist
                  ? authorizationDocumentStatus?.phiConsentDocuments[0]
                      ?.displayedDocumentStatus || ""
                  : "No documents exist"
              )}
            {authorizationDocumentStatus?.pfrAdjustmentDocuments?.length > 0 &&
              authorizationDocumentsListItem(
                authorizationDocumentStatus.pfrAdjustmentDocuments[0],
                "PFR Adjustment Document",
                Utils.getDocumentStatusColor(
                  doPfrAdjustmentDocumentsExist,
                  doPfrAdjustmentDocumentsExist
                    ? authorizationDocumentStatus?.pfrAdjustmentDocuments[0]
                        ?.displayedDocumentStatus
                    : ""
                ),
                doPfrAdjustmentDocumentsExist
                  ? authorizationDocumentStatus?.pfrAdjustmentDocuments[0]
                      ?.displayedDocumentStatus || ""
                  : "No documents exist"
              )}
            {selectedEncounter?.patientPaymentProgram?.[
              selectedEncounter?.patientPaymentProgram?.length - 1
            ]?.patientPaymentSchedule?.paymentFreq === "F" && (
              <>
                {authorizationDocumentStatus?.spaDocuments?.length > 0 &&
                  authorizationDocumentsListItem(
                    authorizationDocumentStatus.spaDocuments[0],
                    "Full Pay Document",
                    Utils.getDocumentStatusColor(
                      doFullPayDocumentsExist,
                      doFullPayDocumentsExist
                        ? authorizationDocumentStatus?.spaDocuments[0]
                            ?.displayedDocumentStatus
                        : ""
                    ),
                    doFullPayDocumentsExist
                      ? authorizationDocumentStatus?.spaDocuments[0]
                          ?.displayedDocumentStatus || ""
                      : "No documents exist"
                  )}
              </>
            )}
          </>
        )}
        {!doDocumentsExist && (
          <Typography variant="h4" className="mb-4">
            No Documents Available
          </Typography>
        )}
        <S3DocumentsListItem
          documents={s3Documents}
          selectedEncounter={selectedEncounter}
        />
        <AccessControl
          rolePageName={RolePageNames.PatientRecords}
          actionName="Add Instance of Care"
          renderNoAccess={() => <Button disabled>Add Document</Button>}
        >
          <Button
            type="text"
            icon={<AddIcon />}
            paddingLeft={0}
            marginTop={4}
            onClick={() => handleToggleDocumentModal(true)}
          >
            Add Document
          </Button>
        </AccessControl>

        <DocumentsModal
          patientID={selectedEncounter.patientId}
          encounterId={selectedEncounter.patientEncounterId}
          open={isDocumentModelOpen}
          onClose={handleToggleDocumentModal}
        />

        <Menu
          anchorEl={electronicAnchorEl}
          open={Boolean(electronicAnchorEl)}
          onClose={() => handleElectronicMenuClose()}
        >
          <AccessControl
            rolePageName={RolePageNames.PatientRecords}
            actionName="Add Instance of Care"
            renderNoAccess={() => <MenuItem disabled>Remind</MenuItem>}
          >
            <MenuItem
              className="primary"
              onClick={() => handleElectronicMenuClose("remind")}
            >
              Remind
            </MenuItem>
            <MenuItem
              className="primary"
              onClick={() => handleElectronicMenuClose("forward")}
            >
              Forward
            </MenuItem>
          </AccessControl>
        </Menu>

        {/* attached document menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleAttachedMenuClose()}
        >
          <AccessControl
            rolePageName={RolePageNames.PatientRecords}
            actionName="Add Instance of Care"
            renderNoAccess={() => <MenuItem disabled>Delete</MenuItem>}
          >
            <MenuItem
              className="danger"
              onClick={() => handleAttachedMenuClose("delete")}
            >
              Delete
            </MenuItem>
          </AccessControl>
        </Menu>
        {isDeleteModalOpen && (
          <DeleteModal
            open={isDeleteModalOpen}
            title="Delete Attached Document"
            subTitle="This action cannot be undone"
            okButtonText="Delete Forever"
            handleDeleteCancel={() => setIsDeleteModalOpen(false)}
            handleDeleteOk={() => {}}
          />
        )}

        {isDocumentForwardModalOpen && (
          <DocumentForwardModal
            open={isDocumentForwardModalOpen}
            patientEncounter={selectedEncounter}
            handleCloseModal={() => setIsDocumentForwardModalOpen(false)}
          />
        )}
      </div>
    </>
  );
}

