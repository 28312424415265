import { TextField, Toggle } from '@finpay-development/shared-components';
import { Box, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch } from 'react-redux';

import { Utils } from '../../utils';
import StripeCardField from '../../../stripe/components/StripeCardField';
import { setPatientTakePaymentToken, setRecurringPatientTakePaymentToken } from '../../../patient/state/patient-slice';
import { AppDispatch } from '../../state/store';
import { StripePaymentMethod } from 'src/patient/components/models/stripe-payment-method';

interface TakePaymentFieldsProps {
  paymentMethods?: any[];
  paymentMethodsFailed: boolean;
  requiresExistingSource: boolean;
  requiresRecurringPayment: boolean;
  patientsAndChampionsList?: any[];
  formik: any;
  stripeStatus: (status: boolean) => void;
}

export function TakePaymentFields(props: TakePaymentFieldsProps) {
  
  const {
    paymentMethods,
    paymentMethodsFailed,
    patientsAndChampionsList,
    requiresExistingSource,
    requiresRecurringPayment,
    formik,
    stripeStatus
  } = props;
  const dispatch = useDispatch<AppDispatch>();

  const stripeDisabledCallback = (isDisabledFromStripe: boolean ) => {
    stripeStatus(!isDisabledFromStripe);
  };

  // placeholder function for integrating other take payment modal with this component
  const reFetchPaymentMethods = () => {
  //   if (!missingAddressError) {
  //     dispatch(getPaymentMethods({
  //       patientId: selectedEncounter?.patientId,
  //       encounterId: selectedEncounter?.patientEncounterId
  //     }));
  //   }
  };

  const stripeTokenCallback = (data: { token?: string, cardToken?: string, bankToken?: string }) => {
    const { token, cardToken, bankToken } = data
    if (token && cardToken) {
      dispatch(setPatientTakePaymentToken({ token: token, cardToken: cardToken }))
    } else if (bankToken) {
      dispatch(setPatientTakePaymentToken({ bankToken: bankToken }))
    }
  }

  const stripeRecurringPaymentTokenCallback = (data: { token?: string, cardToken?: string }) => {
    const { token, cardToken } = data
    if (token && cardToken) {
      dispatch(setRecurringPatientTakePaymentToken({ token: token, cardToken: cardToken }))
    }
  }

  return (
    <>
      <Grid container>
        {requiresExistingSource &&
          <>
            <Grid xs={12} item>
              <Typography variant="h4">
                Use Existing Source
              </Typography>
            </Grid>
            <Grid item xs={12} className="pb-6">
              <Toggle
                name="useExistingSource"
                value={formik.values.useExistingSource}
                formik={formik}
              />
            </Grid>
          </>
        }
        {requiresRecurringPayment && !formik?.values?.useExistingSource && (
          <>
            <Grid xs={12} item>
              <Typography variant="h4">
                Save as Recurring Payment
              </Typography>
            </Grid>
            <Grid item xs={12} className="pb-6">
              <Toggle
                name="savePaymentMethod"
                value={formik.values.savePaymentMethod}
                formik={formik}
              />
            </Grid>
          </>
        )}
        {requiresRecurringPayment && !formik?.values?.useExistingSource && formik?.values?.savePaymentMethod && (
          <>
            <Grid item xs={12} className="pb-2">
              <Typography variant="h4">
                Payor
              </Typography>
            </Grid>
            <Grid item xs={6} className="pb-6">
              <TextField
                select={true}
                error={
                  formik.touched["payorName"] && formik.errors["payorName"]
                }
                label="Payor Name"
                name="payorName"
                value={formik.values.payorName}
                placeholder="Select"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="-1">Select</MenuItem>
                {patientsAndChampionsList!.map((payor: { name: string, id: number }) => (
                  <MenuItem key={payor.id} value={payor.id}>
                    {payor.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </>
        )}
      </Grid>
      {requiresRecurringPayment && formik.values.useExistingSource && requiresExistingSource ? (
        <>
          <Grid xs={6} item>
            <TextField
              select={true}
              error={
                formik.touched["paymentSources"] && formik.errors["paymentSources"]
              }
              label="Payment Sources"
              name="paymentSources"
              value={formik.values.paymentSources}
              className="pb-4"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="-1">
                Select Payment Source
              </MenuItem>
              {paymentMethods && paymentMethods?.length > 0 && paymentMethods.map((source: StripePaymentMethod, index: number) => (
                <MenuItem
                  key={(source?.id) ? source.id : index }
                  value={JSON.stringify(source)}
                >
                  {Utils.convertPaymentMethodToReadableInfo(source)}
                </MenuItem>
              ))}
          </TextField>
          </Grid>
        <Grid item xs={6}>
          {paymentMethodsFailed && (
            <>
              <Typography variant="h4" className="pl-6">
                Refresh payment methods
              </Typography>
              <Box display="flex" justifyContent="flex-end" marginTop="-4rem">
                <IconButton onClick={() => reFetchPaymentMethods()} size="large">
                  <RefreshIcon fontSize='large' className="icon" style={{color: '297ec1'}} />
                </IconButton>
              </ Box>
            </>
          )}
        </Grid>
        </>
      ) : (
        <>
          <Grid item xs={6}>
            <TextField
              error={
                formik.touched["nameOnCard"] && formik.errors["nameOnCard"]
              }
              label="Name on Card"
              placeholder="Enter Name on Card"
              name="nameOnCard"
              value={formik.values.nameOnCard}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={12} className="my-2">
            <StripeCardField
              disabledCallback={stripeDisabledCallback}
              tokenCallback={stripeTokenCallback}
              data={
                {
                  name: formik.values.nameOnCard,
                  address_country: 'US'
                }
              }
              recurringPayment={!formik.values.useExistingSource && formik.values.savePaymentMethod}
              recurringPaymentTokenCallback={stripeRecurringPaymentTokenCallback}
            />
          </Grid>
        </>
      )}
    </>
  );
}
