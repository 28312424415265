import { BuildSelectedLevelsofCare, TextField, Vob } from '@finpay-development/shared-components';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import SearchIcon from '@mui/icons-material/Search';
import {
  Checkbox as MuiCheckbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TableColumn } from '../../../../shared/model/table-column';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { EditEstLevelOfCare, EstLevelOfCare, Estimate, PayorLocRateLos } from '../../../models/estimator';
import { getClientLevelsOfCare } from '../../../state/estimator-thunk';
import { setfacilityAllLevelsOfCare } from 'src/admissions-\advisor/state/estimator-slice';
import { LevelOfCare } from '@finpay-development/shared-components/lib/esm/models/level-of-care';

interface PriorCareFacilityLocGridProps {
	facilityId: number;
  handleSelections: (selectedCopy: EstLevelOfCare[], isValid: boolean) => void;
  selectedFacilityLocs: EstLevelOfCare[];
}

export const getFacilityLevelsOfCare = async (masterLevelsOfCareState: LevelOfCare[], estimatorState: Estimate, vob: Vob, dispatch: AppDispatch) => {
  if (masterLevelsOfCareState.length > 0) {
    const getLocConfig: any = {
      paramId: -2,
      clientId: estimatorState.client?.clientId,
      payerPlanId: vob?.plan?.payorPlanId,
      filterByPayerPlanId: !!vob?.plan?.payorPlanId,
      masterListLevelsOfCare: masterLevelsOfCareState,
    };
    const getLOCs: any = await dispatch(getClientLevelsOfCare(getLocConfig));
    const clientFacilitylocArray: EditEstLevelOfCare[] = getLOCs.payload; 
    const facilityLocs: EditEstLevelOfCare[] = BuildSelectedLevelsofCare(
      clientFacilitylocArray,
      vob?.facility?.facilityId!,
      estimatorState?.quoteMethod || "avglos",
      vob?.payer?.inNetwork || false,
      vob?.selfPay || false
    );
    dispatch(setfacilityAllLevelsOfCare(facilityLocs));
  }
};

export function PriorCareFacilityLocGrid(props: PriorCareFacilityLocGridProps) {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState<EstLevelOfCare[]>();
  const [searched, setSearched] = useState<string>("");
  const [page, setPage] = useState(0);
  const [selectedLocs, setSelectedLocs] = useState<EstLevelOfCare[]>(
    props.selectedFacilityLocs
  );
  const dispatch = useDispatch<AppDispatch>();
  const paramId = -2;

  const state = {
    masterLevelsOfCareState: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.levelsOfCare
    ),
    facilityAllLevelsOfCareState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.facilityAllLevelsOfCare
    ),
    vob: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimatorState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.estimatorContext.estimator
    ),
  };
  const { masterLevelsOfCareState, facilityAllLevelsOfCareState, vob, estimatorState } = state;

  const locHeaders: TableColumn[] = [
    { id: "colCheck", label: "", minWidth: 30 },
    { id: "levelOfCareCode", label: "Code", minWidth: 200 },
    { id: "levelOfCareName", label: "Name", minWidth: 200 },
    { id: "colLocDays", label: "Days/Sessions" },
  ];

  useEffect(() => {
    getFacilityLevelsOfCare(masterLevelsOfCareState, estimatorState, vob, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterLevelsOfCareState, props.facilityId]);

  useEffect(() => {
    const filteredFacilityAllLevelsOfCareState = facilityAllLevelsOfCareState.filter((loc)=> {
      // double O(n) time complexity
      // consider sorting master list by id, then binary search
      // performance improvement: O(n) -> O(log n)
      const matchingLoc = masterLevelsOfCareState.find((cfgLoc)=> {
        return cfgLoc.levelOfCareId === loc.cfgLevelOfCareId
      })

      if(matchingLoc?.locType === 1) return false
      
      return true
    });
    
    setRows(filteredFacilityAllLevelsOfCareState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityAllLevelsOfCareState]);

  const clearSelectedLocs = (event: unknown) => {
    setSelectedLocs([]);
  };

  const handleCheckboxClick = (row: EstLevelOfCare) => {
    let index;
    let selectedCopy = [...selectedLocs];
    index = selectedCopy.findIndex(
      (el) => el.cfgLevelOfCareId === row.cfgLevelOfCareId
    );
    if (index > -1) {
      selectedCopy.splice(
        selectedCopy.findIndex((el) => {
          return el.cfgLevelOfCareId === row.cfgLevelOfCareId;
        }),
        1
      );
    } else {
      let editedLoc: EstLevelOfCare = { ...row };
      selectedCopy.push(editedLoc);
    }
    setSelectedLocs(selectedCopy);
    const isFormValid = checkIsFormValid(selectedCopy);
		props.handleSelections(selectedCopy, isFormValid);
  };

  const changeLocDays = (id: number, value: string) => {
    let selectedCopy: EstLevelOfCare[] = JSON.parse(JSON.stringify(selectedLocs));
    let index = selectedCopy.findIndex((el) => el.cfgLevelOfCareId === id);
    if (selectedCopy[index]) {
			(selectedCopy[index].facilityPayorLocRateLos as PayorLocRateLos).losDays = parseInt(value);
    }
    setSelectedLocs(selectedCopy);
    const isFormValid = checkIsFormValid(selectedCopy);
    props.handleSelections(selectedCopy, isFormValid);
  };

  const checkIsFormValid = (selectedCopy: EstLevelOfCare[]) => {
    return (
      selectedCopy.length > 0 &&
      selectedCopy.every((currentValue) => !isNaN((currentValue?.facilityPayorLocRateLos as PayorLocRateLos).losDays!))
    );
  };

  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const filteredRows = facilityAllLevelsOfCareState?.filter((row) => {
      return (
        row.facilityLevelOfCareName?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.facilityLevelOfCareCode?.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
    setPage(0);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const isSelected = (cfgLevelOfCareId: number) =>
    selectedLocs.findIndex((loc) => loc.cfgLevelOfCareId === cfgLevelOfCareId) !== -1;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Grid container direction="row" spacing={2} alignItems="center" className="my-1">
        <Grid item direction="column" xs={7}>
          <Typography variant="subtitle2">Select Levels of Care</Typography>
        </Grid>
        <Grid item xs={5} direction="column">
          <TextField
            placeholder="Search by code or name"
            name="searched"
            value={searched}
            onChange={(event: any) => requestSearch(event?.target?.value)}
            onBlur={() => cancelSearch()}
            startAdornment={<SearchIcon />}
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {locHeaders.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.sortBy(rows, "levelOfCareName").slice!(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).map((row: EstLevelOfCare) => {
              const isItemSelected = isSelected(row?.cfgLevelOfCareId);
              const selectedRow: EstLevelOfCare =
                selectedLocs[
                  selectedLocs.findIndex(
                    (loc) => loc.cfgLevelOfCareId === row.cfgLevelOfCareId
                  )
                ];
              return (
                <TableRow>
                  <TableCell align="center">
                    <MuiCheckbox
                      onClick={(e) => handleCheckboxClick(row)}
                      className="selectCheckbox"
                      checked={isItemSelected}
                    />
                  </TableCell>
                  <TableCell align="left">{row.facilityLevelOfCareCode}</TableCell>
                  <TableCell align="left">{row.facilityLevelOfCareName}</TableCell>
                  <TableCell>
                    {isItemSelected && (
                      <MuiTextField
                        type="number"
                        style={{ width: "4.5em" }}
                        value={(selectedRow?.facilityPayorLocRateLos as PayorLocRateLos).losDays}
                        onChange={(e) =>
                          changeLocDays(row.cfgLevelOfCareId, e.target.value)
                        }
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Grid container justifyContent="space-between">
          <Grid item>
            <IconButton
              title="clear selected"
              style={{ fontSize: "1.5rem", marginLeft: "1.25rem" }}
              onClick={(e) => clearSelectedLocs(e)}
            >
              <HighlightOffSharpIcon color="error" /> Clear selected
            </IconButton>
          </Grid>
          <Grid item>
            <TablePagination
              component="div"
              rowsPerPageOptions={[
                5,
                10,
                50,
                { value: rows?.length!, label: "All" },
              ]}
              count={rows?.length!}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={"View"}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </TableContainer>
    </>
  );
}
