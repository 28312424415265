import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/state/root-reducer";
import { formatNumberToUSD } from "../../../shared/calculators";
import { Typography, Box, Grid, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import { admissionsAdvisorUtils } from "../../utils/admission-advisor-utils";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { EstFinancialAssistanceSummaryModal } from "./estimator-financial-assistance-summary-modal";
import { AppDispatch } from "../../../shared/state/store";
import { setEstimator } from "../../state/estimator-slice";
import { CrossoverPolicies } from "../../models/estimator";

export function SidebarFinancialAssistance() {

  const state = {
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimatorState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.estimatorContext.estimator
    ),
  };

	const {
		vobState,
		estimatorState
	} = state;

	const [financialSummaryModalOpen, setFinancialSummaryModalOpen] = useState(false);
	const [totalEstimatedPfrSelected, setTotalEstimatedPfrSelected] = useState(false);
	const [adjustedPFR, setAdjustedPFR] = useState<number>(0.00);
	const dispatch = useDispatch<AppDispatch>();
	const PFRName = "Financial Assistance PFR";

	useEffect(() => {
		// calculate PFR for plan crossover
		let pfrToAdjust: number = 0;
    pfrToAdjust = getNormalPFR();
		if (estimatorState.planYearCrossover?.usingPlanYearCrossoverPfr && estimatorState.planYearCrossover?.planYearCrossoverConfirmed) {
      const crossOverPolicies: CrossoverPolicies = admissionsAdvisorUtils.calculateCrossoverPfr(estimatorState.planYearCrossover.crossoverDays, estimatorState, vobState);
			if (crossOverPolicies) {
				pfrToAdjust = crossOverPolicies?.currentPolicyFinancialSummary?.totalPFR! + crossOverPolicies?.nextPolicyFinancialSummary?.totalPFR!;
			}
		}
		setAdjustedPFR(pfrToAdjust);
		if (estimatorState.hasFinancialAssistance && estimatorState.financialAssistance.qualifiesForAssistance && estimatorState.financialAssistance.scholarshipPercentage) {
      const scholarshipAmt = pfrToAdjust * estimatorState?.financialAssistance?.scholarshipPercentage!;
			setAdjustedPFR(pfrToAdjust - scholarshipAmt);
		}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatorState]);

	//admissionsAdvisorUtils.calculateCrossoverPfr(crossOverDays, estimatorState, vobState);

	const handleFinAssistPfrSelected = () => {
    setTotalEstimatedPfrSelected(!totalEstimatedPfrSelected);
		if (estimatorState.selectedPFRName !== PFRName) {
			let estimatorStateCopy = { ...estimatorState };
			estimatorStateCopy.selectedPFRName = PFRName;
      estimatorStateCopy.financialAssistanceAdjustmentPfr = adjustedPFR;
			dispatch(setEstimator(estimatorStateCopy));
		}
  }

	const getSideBarColor = (): string => {
    let sideBarColor = (estimatorState.selectedPFRName === PFRName)? "#6EACDE" : "#999999";
    return sideBarColor;
  };

	const handleFinSumModalCancel = () => {
    setFinancialSummaryModalOpen(false);
  };

	const openFinancialSummaryModal = () => {
		setFinancialSummaryModalOpen(true);
	}

  const getNormalPFR = () => {
    const financialSummary = admissionsAdvisorUtils.calculateFinancialSummary(estimatorState, vobState);
    return financialSummary.totalPFR;
  }

	return (
		<>

			{(estimatorState?.selectedLevelsOfCare?.length > 0 && estimatorState.hasFinancialAssistance && estimatorState.financialAssistance?.qualifiesForAssistance) && (
				<>
          <Box
            border={1}
            borderColor={getSideBarColor()}
						style={{
							backgroundColor: "white",
							marginBottom: "1.5em",
						}}
          >
            <Grid container direction="row">
              <Grid item direction="column" md={12}></Grid>
            </Grid>
            <Grid container direction="row">
              <Grid
                item
                direction="column"
                md={12}
								xs={12}
                style={{
                  color: "white",
                  backgroundColor: getSideBarColor(),
                  padding: "0.25em",
                }}
              >
                Financial Assistance
              </Grid>
              <Grid
                item
                direction="column"
                md={12}
                style={{
                  padding: "1em",
                }}
              >
                <Typography variant="body2" style={{ float: "left" }}>
                  Estimated PFR After Financial Assistance
                </Typography>

                <FormControlLabel
                  checked={estimatorState.selectedPFRName === PFRName}
                  control={<Checkbox color="primary" />}
                  id="adjustedPFR"
                  name="adjustedPFR"
                  label={<strong>{formatNumberToUSD(adjustedPFR)}</strong>}
                  labelPlacement="start"
                  style={{ float: "right" }}
                  onChange={() => {
                    handleFinAssistPfrSelected();
                  }}
                />

              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid
								item
								direction="column"
								md={12}
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<IconButton
									color="primary"
                  title="Open Details"
                  style={{ fontSize: "1.5rem" }}
                  onClick={openFinancialSummaryModal}
                >
                  <OpenInNewIcon fontSize="small" /> Open Details
                </IconButton>
							</Grid>
            </Grid>
          </Box>

					{financialSummaryModalOpen && (
						<EstFinancialAssistanceSummaryModal
							open={financialSummaryModalOpen}
							handleFinancialSummaryModalCancel={handleFinSumModalCancel }
						/>
					)}

				</>

      )}

		</>
	)
	


}