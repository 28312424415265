import {createAsyncThunk} from '@reduxjs/toolkit';
import { sortFacilityLevelOfCare } from "@finpay-development/shared-components";
import {
  admissionsAdvisorService,
} from '../services/admissions-advisor-services';
import {patientHelper} from '../../patient/services/patient-service';
import {
  EstLevelOfCare,
  EstLevelOfCareServiceConfig,
  FacilityLocRlos,
  SaveEstimatorPostBody,
} from '../models/estimator';
import {Estimate, Id} from "@finpay/estimation-types"
import {admissionsAdvisorUtils} from '../utils/admission-advisor-utils';
import {showErrorStatus} from '../../security/state/user-slice';
import {estimateConfig} from '../components/pfr-estimator/estimator-footer';

export const getFacilityLevelsOfCareByPlan = createAsyncThunk(
  "estimatorContext/getEstimatorLevelsOfCare",
  async (config: EstLevelOfCareServiceConfig, thunkAPI) => {
    const response = await admissionsAdvisorService.getEstimatorLevelsOfCare(config.paramId, config.facilityId, config.planId);
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      let responseBody = {
        facilityLevelsOfCare: [...response.entity],
        masterListLevelsOfCare: config.masterListLevelsOfCare
      };
      const cleanedData: EstLevelOfCare[] = admissionsAdvisorUtils.fillClientFacilityDataGaps(responseBody);
      const sortedData: EstLevelOfCare[] = sortFacilityLevelOfCare(cleanedData)
      return sortedData;
    }
  }
)
// TODO: remove this thunk. I think it's not being used anywhere.
export const getAllFacilityLevelsOfCare = createAsyncThunk(
  "estimatorContext/getAllFacilityLevelsOfCare",
  async (params: any, thunkAPI) => {
    const response = await admissionsAdvisorService.getEstimatorFacilityLocPayers(params.paramId, params.facilityId);
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      let responseBody = {
        facilityLevelsOfCare: [...response?.entity?.facilityLevelsOfCare],
        masterListLevelsOfCare: params.masterListLevelsOfCare
      };
      const cleanedData:EstLevelOfCare[] = admissionsAdvisorUtils.fillClientFacilityDataGaps(responseBody);

      return cleanedData;
    }
  }
);

export const getClientLevelsOfCare = createAsyncThunk(
  "estimatorContext/getClientLevelsOfCare",
  async (params: any, thunkAPI) => {
    const response = await admissionsAdvisorService.getClientLevelsOfCare(params.paramId, params.clientId);
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      let clientLevelsOfCare: EstLevelOfCare[] = [];
      // filter data by payerPlanId
      if (params.filterByPayerPlanId) {
        clientLevelsOfCare = admissionsAdvisorUtils.filterLevelsOfCareByPayerPlanId(response?.entity, params.payerPlanId)
      } else {
        if (params.payerPlanId) {
          clientLevelsOfCare = admissionsAdvisorUtils.filterPayorLocRateLosByPayerPlanId(response?.entity, params.payerPlanId)
        } else {
          clientLevelsOfCare = response?.entity;
        }
        
      }

      if(clientLevelsOfCare.length ===0){
        const errorMessage = "There are no client levels of care to show. Please contact a system administrator."
        thunkAPI.dispatch(showErrorStatus(errorMessage));
        throw new Error(errorMessage);
      }

      let responseBody = {
        facilityLevelsOfCare: clientLevelsOfCare,
        masterListLevelsOfCare: params.masterListLevelsOfCare
      };

      return admissionsAdvisorUtils.fillClientFacilityDataGaps(responseBody) as EstLevelOfCare[];
    }
  }
);

export const addLevelofCare = createAsyncThunk(
  'estimatorContext/addLevelofCare',
  async (levelOfCare: EstLevelOfCare, thunkAPI) => {
    let response;
    response = await admissionsAdvisorService.addLevelofCare(levelOfCare)
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    return response.entity;
  }
)

export const updateLevelofCare = createAsyncThunk(
  'estimatorContext/updateLevelofCare',
  async (levelOfCare: EstLevelOfCare, thunkAPI) => {
    let response;
    const paramId = -2;
    response = await admissionsAdvisorService.updateLevelofCare(paramId, levelOfCare)
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    return response.entity;
  }
)

export const deleteLevelofCare = createAsyncThunk(
  'estimatorContext/deleteLevelofCare',
  async (levelOfCare: EstLevelOfCare, thunkAPI) => {
    let response;
    const paramId = -2;
    response = await admissionsAdvisorService.deleteLevelofCare(paramId, levelOfCare)
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    return response.entity;
  }
)

export const addRLOS = createAsyncThunk(
  'estimatorContext/addRLOS',
  async (rlos: FacilityLocRlos, thunkAPI) => {
    let response;
    response = await admissionsAdvisorService.addRLOS(rlos)
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    return response.entity;
  }
)

export const updateRLOS = createAsyncThunk(
  'estimatorContext/updateRLOS',
  async (rlos: FacilityLocRlos, thunkAPI) => {
    let response;
    const paramId = -2;
    response = await admissionsAdvisorService.updateRLOS(paramId, rlos)
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    return response.entity;
  }
)

export const deleteRLOS = createAsyncThunk(
  'estimatorContext/deleteRLOS',
  async (rlos: FacilityLocRlos, thunkAPI) => {
    let response;
    const paramId = -2;
    response = await admissionsAdvisorService.deleteRLOS(paramId, rlos)
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    return response.entity;
  }
)

export const getClientDetails = createAsyncThunk(
  "estimatorContext/getClientDetails",
  async (config: any, thunkAPI) => {
    const response = await admissionsAdvisorService.getClientDetails(config.paramId, config.clientId);
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const getEstimate = createAsyncThunk(
  "estimatorContext/getEstimate",
  async (config: any, thunkAPI) => {
    const response = await admissionsAdvisorService.getEstimate(config.paramId, config.estimationId);
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const getSavedEstimates = createAsyncThunk(
  "estimatorContext/getSavedEstimates",
  async (config: estimateConfig, thunkAPI) => {

    const response = await admissionsAdvisorService.getEstimations({
      paramId: config.paramId,
      queryStringParams: {
        vobId : config.vobId
      }
    });

    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }

    return response.entity

  }
);

export const saveEstimate = createAsyncThunk(
  'estimatorContext/saveEstimate',
  async (estimate: SaveEstimatorPostBody, thunkAPI) => {
    let response;
    if (estimate?.estimateId) { // edit
      response = await admissionsAdvisorService.updateEstimation(estimate)
    } else { // add
      response = await admissionsAdvisorService.newEstimation(estimate)
    }
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    return response.entity;
  }
)

export const saveAdditionalEstimate = createAsyncThunk(
  'estimatorContext/saveAdditionalEstimate',
  async (estimate: SaveEstimatorPostBody, thunkAPI) => {
    let response;
    if (estimate?.estimateId) { // edit
      response = await admissionsAdvisorService.updateEstimation(estimate)
    } else { // add
      response = await admissionsAdvisorService.newEstimation(estimate)
    }
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    return response.entity;
  }
)

export const callNewEstimatePost = createAsyncThunk(
  'estimatorContext/callNewEstimatePost',
  async (estimate: Estimate, thunkAPI) => {
    const response = await admissionsAdvisorService.callNewEstimatePost(estimate)

    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }

    return response.entity;
  }
)

export const callNewEstimatePut = createAsyncThunk(
  'estimatorContext/callNewEstimatePut',
  async (config: {estimate: Estimate, estimateId: Id | undefined}, thunkAPI) => {
    const response = await admissionsAdvisorService.callNewEstimatePut(config.estimate, config.estimateId)

    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }

    return response.entity;
  }  
)

export const callNewEstimateGet = createAsyncThunk(
    'estimatorContext/callNewEstimateGet',
    async (config: any, thunkAPI) => {
        const response = await admissionsAdvisorService.callNewEstimateGet(
            config.paramId,
            config.estimationId
        );
        if (response.hasErrors) {
            patientHelper.robustErrorHandler(response, thunkAPI);
        } else {
            return response.entity;
        }
    }
);

export const getNewFacilityLevelsOfCare = createAsyncThunk(
  'estimatorContext/getFacilityLevelsOfCare',
  async (config: any, thunkAPI) => {
    const response = await admissionsAdvisorService.getFacilityLevelsOfCare(config.facilityId, config.facilityPayorPlanId);
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
)

