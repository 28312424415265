export enum StatusColors {
  success = "#38b079",
  error = "#df1e44",
  info = "#7356cc",
  warning = "#f2db00",
  gray = "#7f8994",
  blue = "#297ec1",
  orange = "#ffa700",
  purple = "#7356cc"
}

export enum CookieExpiration{
  days = 365
}

export enum DomainAccess{
  Finpay = "FINPAY",
  Client = "CLIENT"
}

export enum commentsPage {
  clients = "clients",
  patients = "patients"
}

export enum TypesOfCare {
  opt = "outpatient",
  inpt = "inpatient"
}

export enum errorCodes {
  invalidWorkflow = "INVALID_WORKFLOW",
  duplicateUserName = "USERNAME_ALREADY_EXISTS",
  duplicateRule = "DUPLICATE_RULE",
  externalServiceError = "EXTERNAL_SERVICE_ERROR"
}

export enum errorMessages {
  duplicateUser = "Client primary contact user already exists, please try again",
  duplicateRule = "Duplicate Entry found, please change values and try again"
}

export enum highRiskMessage{
  highRisk = `FinPay has completed the financial clearance curriculum as outlined in the Financial Clearance Policy Builder and based on those financial clearance rules the patient has failed financial clearance, resulting in a "HIGH RISK" status.  Please review the information below to determine if an exception should be made for this patient`
}

export enum clipboardKeys {
  downPmtAmt = "Down Payment",
  insurancePlanName = "Insurance Plan",
  copayAmt = "Copay",
  coinsuranceAmt = "CoInsurance",
  deductibleAmt ="Deductible",
  oopmaxAmt = "OOPM",
  remainingOOPMaxAmt = "Remaining OOP Max Amount",
  insuranceCompanyName = "Insurance Provider"
}


export enum reportsType {
  AllInstanceofCare = "All Instance of Care" as any,
  PatientFinancialClearance = "Patient Financial Clearance" as any,
  SettlementDetail = "Settlement Detail" as any,
  RiskClassReports = "Risk Class Reports" as any,
  PESActivityLog = "PES Activity Log Report" as any,
  SummaryTransactions = "Summary Transaction Report by Payment Channel" as any,
  MergedTransactions = "Transaction Report" as any,
  AutomatedPayment = "Automated Payment Report" as any,
  SettlementSummary = "Settlement Reports" as any
}

export enum ApiBatchLimits {
  patientRecords = 750,
  patientRecordsNode = 4000,
  users = 750,
  reports = 750,
  mergedTransactionsReports = 10000,
  automatedPaymentReports = 10000,
  settlementSummaryReports = 10000,
  patientEncounters = 100,
  pesActivityLogReports = 1500,
  allInstanceofCareReports=500
}

export enum RecentlyViewedTypes {
  clients= "clients" ,
  convertedPatients= "convertedPatients",
  preConvertedPatients= "preConvertedPatients"
}

export enum formats{
  usd = "USD",
  percentage = "Percentage"
}

export enum reportResultType {
  timeStamp = "Timestamp",
  bigDecimal = "BigDecimal",
  string = "String",
  integer = "Integer",
  boolean = "Boolean"
}

export enum userRoles {
  accountHolder = "Account Holder",
  clientOwner = "Client Owner",
  finpaySuperUser = "Finpay Super User",
  patientSpecialist = "Patient Specialist"
}

export enum LockStatus {
  release = "RELEASE",
  deny = "DENY"
}

export enum createCommentSuccesMessages {
  comment = "Comment Created",
  statusChangeComment = "Status changed and comment created"
}

export enum orderDirections {
  asc = "asc",
  desc = "desc"
}

export enum ExternalAccountType {
  Patient = 0,
  WebPayment = 1
}

export enum disabledConvertMessages {
  iocLoadingLabel = "IOC and Patient Demographics are not fully loaded",
  iocLoadingTooltip = "System is loading patient data",
  championLabel = "Patient Champion is not selected",
  paymentProgramLabel = "Payment Program is missing",
  downPaymentLabel = "Downpayment is missing or not fully paid",
  recurringPaymentLabel = "Recurring Payment is missing",
  iocCompletedLabel = "Instance of care is incomplete",
  demographicsLabel = "Patient demographic information is incomplete",
  riskClassLabel = "Risk class is missing",
  warmTransferLabel = "Warm Transfer option has not been selected",
  warmTransferTooltip = "Must select an option from the 'Warm Transfer Completed?' dropdown on Instance of Care Overview tab",
  pfrMissmatchLable = "PFR between IOC and Payment Schedule must match"

}

export enum LoginVerifiedStatus {
  pending = 0,
  verified = 1
}

export enum crmInboundErrorMessages {
  misconfigured = "CRM integration is misconfigured.",
  patientError = "Patient not found, verify CRM configuration",
  patientInfoError = "Patient record is missing required information. Check First Name, Last Name and Date of Birth.",
  facilityError = "Facility not found, verify CRM configuration.",
  clientPatientIOCId = "This clientPatientIOCId is associated with more than one Instance of Care. See administrator.",
  clientPatientAccountId = "Error creating new Instance of Care, clientsPatientAccountId cannot be duplicated.",
  missingPatientAccountId = "Error reading patient record. The clientsPatientAccountId is missing, verify CRM configuration. ",
  crmTypeError = "CRM configuration is missing or incomplete.",
  loadingMsg = "Patient record is currently being loaded into FinAdvisor",
  newEstimateIdError = "Error fetching Estimate."
}

export enum finAdvisorErrorMessage {
  missingQuoteMethod = "Cannot load Levels of Care, Quote Method is missing.",
  missingClient = "Cannot load Levels of Care, Client is not selected.",
  missingFacility =  "Cannot load Levels of Care, Facility is not selected.",
  missingPayer = "Cannot load Levels of Care, Payer is not selected.",
  missingPlan = "Cannot load Levels of Care, Plan is not selected."
}

export enum patientLedgerErrorMessages {
  transactions = "No transactions found for the selected patient.",
  clientError = "Failed to load client information, please check Client Id.",
  patientError = "Failed to load patient information, please check Patient Id.",
  authentication = "Permission required: Viewing the Master Patient Ledger is restricted."
}

export enum estimateErrorMessage {
  authenticationError = "Permission required: Viewing this Estimate is restricted.",
  loadingError = "Failed to load estimate information, please check Estimate Id."
}

export enum crmLabels {
  salesforce_slug = "sf",
  salesforce_url = "salesforce",
  dazos_slug = "dz",
  dazos_url = "dazos",
  dynamics_slug = "dn",
  dynamics_url = "dynamics",
}

export enum stripePaymentErrors {
  generic_decline = "Your card was declined.",
}

export enum authDocDispatchMethod {
  sms = 'sms',
  email = 'email'
}

export enum authDocType {
  inst = 'inst',
  spa = 'spa',
  phi = 'phi',
  pfr = 'pfr'
}
