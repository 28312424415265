/* eslint-disable react-hooks/exhaustive-deps */
import '../../../../scss/components/_icons.scss';
import '../../../../scss/components/_list-header.scss';

import { Button, DeleteModal, LoadingOverlay, Status, Toggle } from '@finpay-development/shared-components';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RefreshIcon from '@mui/icons-material/Refresh';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Box, Divider, Fade, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';

import LockModal from '../../../../implementation-specialist/components/locks/lock-modal';
import AccessControl from '../../../../security/components/access-control';
import { RolePageNames } from '../../../../security/model/role-page-names';
import { formatNumberToUSD } from '../../../../shared/calculators';
import { configSettings } from '../../../../shared/configuration/config-settings';
import { ApiBatchLimits, clipboardKeys, formats, highRiskMessage, StatusColors, userRoles } from '../../../../shared/enums';
import { Clipboard, EmptyClipBoard } from '../../../../shared/model/clipboard';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { Utils } from '../../../../shared/utils';
import { patientHelper, patientService } from '../../../services/patient-service';
import {
  resetPatientRecordLockingContext,
  setInUseModalOpen_paymentPrograms,
  setIsPaymentProgramsModalOpen,
  setLockRequestedById,
  setLockStatusReleased_paymentPrograms,
  setRequestDeniedModalOpen_paymentPrograms,
} from '../../../state/patient-record-locking-slice';
import {
  denyRequestOk,
  editModalPoll,
  releaseRecordOk,
  requestReleaseOk,
} from '../../../state/patient-record-locking-thunk';
import { resetPatientContext, setDownPaymentFullyPaid, setSelectedEncounter } from '../../../state/patient-slice';
import {
  deletePatientPaymentMethod,
  // getInstanceOfCare, // Used for v2 modal
  getPaymentMethods,
  getTransactions,
  lockIOC,
  releaseCharge,
  savePatientPaymentProgram,
  // updateEncountersAfterMakePayment, // Used for v2 modal
} from '../../../state/patient-thunk';
import { LockablePatientRecordSection } from '../../models/lockable-patient-record-section';
import { PatientPaymentProgram } from '../../models/patient-payment-program';
import { Payment } from '../../models/payment';
import { PAYMENT_METHOD_TYPES } from '../../models/payment-method';
import { PaymentStatus } from '../../models/payment-status';
import { StripePaymentMethod } from '../../models/stripe-payment-method';
import { Transaction } from '../../models/transaction';
import { HighRiskModal } from './high-risk-modal';
import { PaymentProgramsModal } from './payment-programs-modal';
import { RecurringPaymentModal } from './recurring-payment-modal';
// import { TakePaymentModal } from 'src/shared/components/take-payment-modal'; // v2 modal
import { DownPaymentModal } from './down-payment-modal';
import { ClientStatusCardViewModel } from 'src/shared/model/client-status-card';
import { PatientPaymentSchedule } from '../../models/patient-payment.schedule';
import { showErrorStatus, showStatus as showStatusUserSlice } from 'src/security/state/user-slice';
import { paynowService } from 'src/guest/services/paynow-service';
import { getAnonymousRequestToken } from 'src/guest/state/guest-thunk';
import { PatientChampion } from '../../models/patient-champion';
import { UserInfo } from 'src/admin/models/user-info';
import { userService } from 'src/admin/services/admin-user-service';
import { UserInfoClient } from 'src/admin/models/user-info-client';


type MenuActions = "Add Payor" | "Release" | "Delete";
let editModalPollingInterval: any; // an interval assigned via setInterval

// if there are no champions, pre-select the selected patient


interface ProgramAccordionProps {
  isCovertDisabled: boolean;
  setIsMissingReqModalOpen: (value: boolean) => void;
}

const PaymentProgramAccordionContents = (props: ProgramAccordionProps) => {
  const countdownMax = configSettings.REACT_APP_patient_locking_countdown_max;
  const lockPollingInterval = configSettings.REACT_APP_patient_locking_polling_interval;

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [clipboard, setClipboard] = useState("")
  const [copiedToClipboardMsg, setCopiedToClipboardMsg] = useState(false)

  const { isCovertDisabled, setIsMissingReqModalOpen } = props;

  const formRef: any = useRef();

  const handleShowMessage = () => {
    setCopiedToClipboardMsg(true)

    setTimeout(() => {
      setCopiedToClipboardMsg(false)
    }, 4000);
  }

  const stateFields = {
    paymentProgram: useSelector((state: RootState) => state?.patientContext?.selectedEncounter?.patientPaymentProgram?.length > 0 ?
      state.patientContext.selectedEncounter.patientPaymentProgram[state.patientContext.selectedEncounter.patientPaymentProgram.length - 1] : {} as PatientPaymentProgram),
    selectedEncounter: useSelector((state: RootState) => state.patientContext.selectedEncounter),
    selectedPatient: useSelector((state: RootState) => state.patientContext.selectedPatient),
    userName: useSelector((state: RootState) => state.userContext.userProfile.userName),
    transactions: useSelector((state: RootState) => state.patientContext.selectedEncounter?.patientTransactions?.length > 0 && state.patientContext.selectedEncounter.patientTransactions),
    recurringPayment: useSelector((state: RootState) => {
      if (state.patientContext.selectedEncounter?.patientPaymentMethods?.length > 0) {
        const recurringPayment = state.patientContext.selectedEncounter.patientPaymentMethods.filter((method) => {
          return method.metadata?.metaData_paymentMethodType?.includes("RECURRING") || method?.metadata?.isRecurring
        })
        return recurringPayment ? recurringPayment[0] : {} as StripePaymentMethod
      } else {
        return {} as StripePaymentMethod
      }
    }),
    isDownPaymentLoading: useSelector((state: RootState) => state.patientContext.isLoading.patientTransactions),
    isRecurringPaymentLoading: useSelector((state: RootState) => state.patientContext.isLoading.paymentMethods),
    isDownPaymentFetchError: useSelector((state: RootState) => state.patientContext.isError.patientTransactions),
    isRecurringPaymentFetchError: useSelector((state: RootState) => state.patientContext.isError.paymentMethods),
    lockingModalOpenStatus: useSelector((state: RootState) => state.patientRecordLockingContext.lockingModalOpenStatus),
    patientModalOpenStatus: useSelector((state: RootState) => state.patientRecordLockingContext.patientModalOpenStatus),
    isLockStatusReleased_paymentPrograms: useSelector((state: RootState) => state.patientRecordLockingContext.isLockStatusReleased_paymentPrograms),
    lockRequestedById: useSelector((state: RootState) => state.patientRecordLockingContext.lockRequestedById),
    isAccountHolderUser: useSelector((state: RootState) => state.userContext.userProfile.userRole.roleName === userRoles.accountHolder),
    missingAddressError: useSelector((state: RootState) => state.patientContext.isError.missingAddress),
    paymentProgramUpdateRequired: useSelector((state: RootState) => state?.patientContext?.isError?.paymentProgramNeedsUpdate),
    userRoleId: useSelector((state: RootState) => state.userContext.userProfile.userRole.userRoleId),
    paymentMethods: useSelector((state: RootState) => state.patientContext.selectedEncounter?.patientPaymentMethods?.length > 0
      && state.patientContext.selectedEncounter.patientPaymentMethods),
    allClients: useSelector(
      (state: RootState) =>
        state.implementationContext?.implementationSpecialistClient
          ?.allClients
    ),
    currentContact: useSelector((state: RootState) => state?.patientContext?.selectedPatient?.contact),
  }
  const {
    paymentProgram, selectedEncounter, selectedPatient, userName, transactions, recurringPayment, userRoleId, paymentMethods,
    isDownPaymentLoading, isRecurringPaymentLoading, isDownPaymentFetchError, isRecurringPaymentFetchError,
    lockingModalOpenStatus, patientModalOpenStatus, isLockStatusReleased_paymentPrograms, lockRequestedById,
    isAccountHolderUser, missingAddressError, paymentProgramUpdateRequired, allClients, currentContact
  } = stateFields;

  const doAnyAchPaymentsOrPaymentMethodsExist = patientHelper.checkNonRefundedAchTransactionsOrPaymentMethods(transactions, paymentMethods);
  let downPayments = transactions ? patientHelper.getNonRefundedDownPayments(transactions) : [];

  const handleRefreshTransactions = async () => {
    if (selectedEncounter?.patientEncounterId !== 0 && !missingAddressError) {
      await dispatch(getTransactions({
        patientId: selectedEncounter?.patientId,
        encounterId: selectedEncounter?.patientEncounterId
      }));
    }
  }

  const handleRefreshPaymentMethods = async () => {
    if (!missingAddressError) {
      await dispatch(getPaymentMethods({
        patientId: selectedEncounter?.patientId,
        encounterId: selectedEncounter?.patientEncounterId
      }));
    }
  }

  /* Used for v2 modal
  const handleTakePaymentCallBack = async (payment: any, response: any) => {
    await dispatch(updateEncountersAfterMakePayment({payment, response}));
    await dispatch(getInstanceOfCare({
      patientId: selectedEncounter?.patientId,
      encounterId: selectedEncounter?.patientEncounterId,
    }));
    await handleRefreshPaymentMethods();
    await handleRefreshTransactions();
  }
  */

  let emptyclipboard: Clipboard = EmptyClipBoard;

  useEffect(() => {
    const copay = Utils.arrayToString(selectedEncounter.patientInsurance, clipboardKeys.copayAmt, formats.usd)
    const coInsurance = Utils.arrayToString(selectedEncounter.patientInsurance, clipboardKeys.coinsuranceAmt, formats.percentage)
    const deductible = Utils.arrayToString(selectedEncounter.patientInsurance, clipboardKeys.deductibleAmt, formats.usd)
    const OOPM = Utils.arrayToString(selectedEncounter.patientInsurance, clipboardKeys.oopmaxAmt, formats.usd)
    const insuranceCompanyName = Utils.arrayToString(selectedEncounter.patientInsurance, clipboardKeys.insuranceCompanyName)
    let patientChampions= "";

    if(selectedEncounter?.patientChampion?.length > 0){
      selectedEncounter?.patientChampion?.forEach((patientChampion, index)=>{
        patientChampions += `Patient Champion ${index + 1}/Relationship: ${patientChampion?.contact?.firstName}/${patientChampion?.relationshipToPatient}\n`
      })
    }

    const arrayInfo = [insuranceCompanyName, copay, coInsurance, deductible, OOPM, patientChampions]

    emptyclipboard.Down_Payment = `${formatNumberToUSD(Number(selectedEncounter?.patientPaymentProgram[selectedEncounter?.patientPaymentProgram.length-1].downPmtAmt))  || ""}`
    emptyclipboard.Patient_Name = `${selectedPatient?.contact?.firstName} ${selectedPatient?.contact?.lastName}`
    emptyclipboard.Facility = selectedEncounter?.facilityName || ""
    emptyclipboard.Occupancy_Rate = ""
    emptyclipboard.Level_of_Care = selectedEncounter?.levelOfCare || "";
    emptyclipboard.Payor_Type = selectedEncounter.payorRisk.payorRiskName || ""
    emptyclipboard.Timing_Risk = selectedEncounter.timingRisk.timingRiskName || ""
    emptyclipboard.Risk_Class = selectedEncounter?.riskClass?.riskClassName || ""
    emptyclipboard.Patient_Financial_Responsibility = `${formatNumberToUSD(Number(selectedEncounter.pfrAmt)) || ""}`
    emptyclipboard.Stated_Income = `${formatNumberToUSD(Number(selectedPatient.contact.contactIncome?.yearlySalary))  || ""}`
    emptyclipboard.Income_Verification_Score = `${selectedPatient.contact.contactIncome?.verificationScore  || ""}`
    emptyclipboard.Disposable_Income = `${formatNumberToUSD(Number(selectedPatient.contact.contactIncome?.disposableIncome || "")) }`

    const resultString = Utils.objectToString(emptyclipboard, arrayInfo, highRiskMessage.highRisk)
    setClipboard(resultString)
    // eslint-disable-next-line
  }, [selectedEncounter, selectedPatient])

  // const groupedTransactions = transactions ? patientHelper.consolidateTransactions(transactions, false) : []
  const isHighRisk = stateFields?.paymentProgram?.isHighRisk;
  const [isHighRiskModalOpen, setIsHighRiskModalOpen] = useState(false);
  const [isDownPaymentModalOpen, setIsDownPaymentModalOpen] = useState(false);
  const [isRecurringPaymentModalOpen, setIsRecurringPaymentModalOpen] = useState(false);
  const [isReleaseFundsModalOpen, setIsReleaseFundsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [paymentId, setPaymentId] = useState<number | string | undefined>(0);
  const [isPaymentDeleteModalOpen, setIsPaymentDeleteModalOpen] = useState(false)
  const [isRecurringPaymentDeleteModalOpen, setIsRecurringPaymentDeleteModalOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDownPayment, setIsDownPayment] = useState(false);
  const [isRequestReleaseButtonDisabled, setIsRequestReleaseButtonDisabled] = useState(false);
  const [showDownPaymentDetails, setShowDownPaymentDetails] = useState(false)
  const [menuText, setMenuText] = useState("Release")
  const [maxPaymentAmt, setMaxPaymentAmt] = useState(0)
  const [totalDownPayments, setTotalDownPayments] = useState(0)
  const [isEnrollLoading, setIsEnrollLoading] = useState(false)

  let channelId = 5;
  const hasFinancialCounselorRole = userRoleId === 1 || userRoleId === 9;
  if(hasFinancialCounselorRole) {
    channelId = 2;
  }

  const getMaxPaymentAmt = () => {
    let existingPaymentsSum = 0;
    downPayments && downPayments?.length > 0 && downPayments.forEach((downPayment: Payment) => {
      existingPaymentsSum += downPayment?.paymentAmt;
    })
    return paymentProgram?.downPmtAmt - existingPaymentsSum;
  }

  const adjustedPFR = selectedEncounter?.adjustedPFR
  const doPFRAdjustmentsExist = selectedEncounter?.doPFRAdjustmentsExist

  useEffect(() => {
    // eslint-disable-next-line
    downPayments = transactions ? patientHelper.getNonRefundedDownPayments(transactions) : [];
    downPayments && downPayments?.length > 0 ? setMaxPaymentAmt(getMaxPaymentAmt()) : setMaxPaymentAmt(paymentProgram?.downPmtAmt)
    let downPaymentsSum = 0;

    downPayments.forEach((payment: Payment)=> {
      if (!payment?.paymentStatusFailed && !isAccountHolderUser){
        downPaymentsSum += payment?.paymentAmt
      }
    })
    setTotalDownPayments(downPaymentsSum)

  }, [adjustedPFR, dispatch, isDownPaymentLoading, transactions, selectedEncounter?.pfrAmt, selectedEncounter?.doPFRAdjustmentsExist])

  const handleHighRiskModalClose = () => {
    setIsHighRiskModalOpen(false);
  }

  const handleMenuClick = ( // determine which type of item it is (recurring/down payment)
    event: React.MouseEvent<HTMLButtonElement>,
    rowPaymentId: number | string | undefined,
    isPaymentTypeCard: boolean,
    isDownPayment: boolean,
    paymentMethodUsed?: number
  ) => {
    paymentMethodUsed && paymentMethodUsed !== 1 ? setMenuText("Remove") : setMenuText("Release")
    setAnchorEl(event.currentTarget);
    setPaymentId(rowPaymentId);
    setIsDownPayment(isDownPayment);
  };

  const handleRecurringPaymentModalOpen = (isOpen: boolean) => {
    setIsRecurringPaymentModalOpen(isOpen);
  }

  const handleDownPaymentModalOpen = (isOpen: boolean) => {
    setIsDownPaymentModalOpen(isOpen);
  }

  const handleDownPaymentRecurringPaymentModalClose = () => {
    setIsRecurringPaymentModalOpen(false);
    setIsDownPaymentModalOpen(false);
  }

  const getSelectedPayment = () => {
    return selectedEncounter?.patientTransactions?.find((transaction: Transaction) => (
      transaction?.payment?.paymentId === paymentId))?.payment as Payment;
  }

  const isHighRiskApproved = () => {
    if (isHighRisk) {
      return (stateFields?.paymentProgram?.highRiskApprovalDt ? true : false)
    } else {
      return true;
    }
  }

  const handleMenuClose = (menuActionType?: MenuActions) => {
    setAnchorEl(null);
    if (menuActionType === "Add Payor") {
      handleDownPaymentModalOpen(true);
    } else if (menuActionType === "Delete") { // del recurring payment
      setIsRecurringPaymentDeleteModalOpen(true);
    } else if (menuActionType === "Release") {
      setIsReleaseFundsModalOpen(true);
    }
  };

  function handleReleaseFunds() {
    dispatch(releaseCharge({patientId: selectedEncounter?.patientId, encounterId: selectedEncounter?.patientEncounterId, payment: getSelectedPayment()}))
    setIsReleaseFundsModalOpen(false);
  }

  function handleDeletePayment(paymentTypeId: number) {
    if (paymentId) {
      dispatch(deletePatientPaymentMethod({
        patientId: selectedEncounter?.patientId,
        encounterId: selectedEncounter?.patientEncounterId,
        stripeId: (typeof paymentId === 'number') ? paymentId.toString() : paymentId,
        paymentTypeId: paymentTypeId
      }))
    }
    setIsPaymentDeleteModalOpen(false);
    setIsRecurringPaymentDeleteModalOpen(false);
  }

  const handlePaymentProgramsModalClose = () => {
    releasePatientLock();
    dispatch(setIsPaymentProgramsModalOpen(false));
    clearInterval(editModalPollingInterval);
  }

  const releasePatientLock = () => {
    setTimeout( async () => {
      await dispatch(lockIOC({lock: false})) // unlock the IOC
    }, 500);
  }

  const doModalPolling = useCallback(async(patientId: number, encounterId: number) => {
    editModalPollingInterval = setInterval(async() => {
      dispatch(editModalPoll({patientId, encounterId, userName, lockablePatientRecordSection: LockablePatientRecordSection.paymentPrograms}))
    }, lockPollingInterval);
  }, [dispatch, userName])

  useEffect(() => {
    if (isLockStatusReleased_paymentPrograms) {
      doModalPolling(selectedEncounter.patientId, selectedEncounter.patientEncounterId);
      dispatch(setLockStatusReleased_paymentPrograms(false));
    }
  }, [dispatch, doModalPolling, setLockStatusReleased_paymentPrograms, selectedEncounter.patientId, selectedEncounter.patientEncounterId])

  const handleEditPaymentProgram = async() => {
    dispatch(setIsPaymentProgramsModalOpen(true));

    const patientId = selectedEncounter.patientId;
    const encounterId = selectedEncounter.patientEncounterId;

    const instanceOfCare = await patientService.getPatientInstanceOfCare({patientId, encounterId});
    const {isLocked} = instanceOfCare.entity || false; // Needed to add the default value to avoid undefined for brand new patient
    if (isLocked && instanceOfCare.entity.lockedByUserId !== userName && instanceOfCare.entity.lockedByUserId !== null) { // extra checks in case of bad data
      setIsRequestReleaseButtonDisabled(false);
      dispatch(setLockRequestedById(instanceOfCare.entity.lockedByUserId));
      dispatch(setInUseModalOpen_paymentPrograms(true));
    } else {
      // patient not locked by anyone. Go ahead and lock it for me so others are locked out
      dispatch(setLockRequestedById(undefined));
      await dispatch(lockIOC({lock: true, username: userName}))

      doModalPolling(patientId, encounterId);
    }
  }

  const handleRequestReleaseOk = async() => {
    setIsRequestReleaseButtonDisabled(true);
    dispatch(requestReleaseOk({userName: userName, lockablePatientRecordSection: LockablePatientRecordSection.paymentPrograms}));
  }

  const handleRequestReleaseCancel = () => {
    navigate("/specialist/dashboard");
    resetRedux();
    dispatch(setInUseModalOpen_paymentPrograms(false));
  }

  const handleReleaseRecordOk = async() => {
    dispatch(releaseRecordOk({lockablePatientRecordSection: LockablePatientRecordSection.paymentPrograms}));
    dispatch(setIsPaymentProgramsModalOpen(false));
    clearInterval(editModalPollingInterval);
  }

  const handleRequestDeniedClose = () => {
    resetRedux();
    navigate("/specialist/dashboard");
    dispatch(setRequestDeniedModalOpen_paymentPrograms(false));
  }

  const resetRedux = () => {
    dispatch(resetPatientContext());
    dispatch(resetPatientRecordLockingContext());
  }


  const getCumulativePaymentsAmt = () => {
    let existingPaymentsSum = 0;
    downPayments && downPayments?.length > 0 && downPayments.forEach((downPayment: Payment) => {
      existingPaymentsSum += downPayment?.paymentAmt;
    })
    return existingPaymentsSum;
  }

  const isPossibleToAddPayor = ((paymentProgram?.downPmtAmt - getCumulativePaymentsAmt()) > 0)

  useEffect(() => {
    dispatch(setDownPaymentFullyPaid(!isPossibleToAddPayor));
  }, [dispatch, isPossibleToAddPayor])

  const financialResponsibilityHeader = (
    <>
      <Grid
        container
        spacing={2}
        className="pb-3"
      >
        <Grid item xs={10}>
          <Typography variant="subtitle2">
            Patient Financial Responsibility
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {(!(selectedEncounter?.workflow?.workflowId === 3)) && (
            <Box display="flex" justifyContent="flex-end" marginTop="-1.2rem" marginRight="-1.5rem">
              <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Add Instance of Care"
                renderNoAccess={() => <IconButton disabled size="large"> <EditIcon className="colorDisabled" /></IconButton>}>
                <IconButton onClick={() => handleEditPaymentProgram()} size="large"> <EditIcon className="icon" /> </IconButton>
              </AccessControl>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  )

  const sectionHeader = (startString: string, endString: string) => (
    <>
      <Grid item xs={6}>
        <Typography variant="h3">
          {startString}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="h3">
            {endString}
          </Typography>
        </Box>
      </Grid>
    </>
  )

  const paymentFrequencyDetails = () => {
    let paymentPlanType = '';
    if (!!paymentProgram?.patientPaymentSchedule?.paymentFreq) {
      switch (paymentProgram?.patientPaymentSchedule?.paymentFreq) {
        case 'M':
          paymentPlanType = 'Monthly'
          break;
        case 'W':
          paymentPlanType = 'Weekly'
          break;
        case 'D':
          paymentPlanType = 'Daily'
          break;
        case 'F':
          paymentPlanType = 'Full Payment'
          break;
        default:
          paymentPlanType = paymentProgram?.patientPaymentSchedule?.paymentFreq
          break;
      }
    }
    return (
      <Grid container spacing={2} className="pb-5">
        {(!!paymentProgram?.patientPaymentSchedule?.paymentFreq) && (
          sectionHeader('Payment Plan Type', paymentPlanType)
        )}
      </ Grid>
    )
  }

  const client: ClientStatusCardViewModel | undefined = allClients.find(
    (c: ClientStatusCardViewModel) =>
        c.clientId === selectedEncounter?.clientId
  );
  
  const isEnrollBtnVisible = client?.paymentProgramVersion === 4;

  const handleEnrollOrConvert = async () => {
    if (isCovertDisabled) {
      return setIsMissingReqModalOpen(true);
    }
    setIsEnrollLoading(true);

    try {
      const paymentsToCapture = downPayments.filter(payment => 
        payment.isCaptured === false && 
        payment.paymentMethodUsed?.paymentMethodTypeId === PAYMENT_METHOD_TYPES.CARD.paymentMethodTypeId
      );

      await Promise.all(
        paymentsToCapture.map(async (downPayment) => {
          const result = await paynowService.updatePaymentDetails(-2, { 
            receiptEmail: selectedPatient.contact.email, 
            isCaptured: true,
            paymentId: downPayment.paymentId
          });
          if (result.hasErrors) {
            throw new Error(`Failed to capture payment: ${result.entity}`);
          }
        })
      );

      const isPaidInFull = selectedEncounter.patientPaymentProgram[0].isPaidInFull;

      let documentResponse;
      if (isPaidInFull) {
        documentResponse = await patientService.spaDocument(selectedEncounter.patientEncounterId);
      } else {
        documentResponse = await patientService.installmentDocument(selectedEncounter.patientEncounterId);
      }
      
      if (documentResponse.hasErrors) {
        throw new Error(documentResponse.entity.message ?? documentResponse.errorMessage);
      }
      
      dispatch(showStatusUserSlice(documentResponse.entity.message));

      const statusResult = await patientService.saveStatus({
        workFlow: {
          workflowId: 3,
          workflowStatus: {
            workflowStatusId: 6,
            workflowStatusDesc: '',
          },
          workflowSubStatus: {
            workflowSubStatusId: 30,
            workflowSubStatusDesc: '',
          },
        },
        encounterId: selectedEncounter.patientEncounterId!,
        patientId: selectedEncounter.patientId!,
      });

      if (statusResult.hasErrors) {
        throw new Error(`Failed to update workflow status: ${statusResult.entity}`);
      }

      const saveResult = await dispatch(savePatientPaymentProgram({
        paymentProgram: {
          patientPaymentProgramId: selectedEncounter.patientPaymentProgram?.[0]?.patientPaymentProgramId || 0,
          patientPaymentSchedule: {
            scheduleStatus: 'Active',
          } as PatientPaymentSchedule
        } as PatientPaymentProgram,
        patientId: selectedEncounter.patientId,
        encounterId: selectedEncounter.patientEncounterId,
        showNotify: false,
      }));

      if (saveResult.meta.requestStatus === 'rejected') {
        throw new Error("Failed to update payment program status");
      }

      handleRefreshTransactions();

      let linkedUserId = currentContact?.email;
      let firstName = currentContact.firstName;
      let lastName = currentContact.lastName;
      let middleName = currentContact.middleName;
      if (selectedEncounter?.patientChampion?.length) {
        const guarantor = (selectedEncounter?.patientChampion.filter((champion: PatientChampion) => (
          champion?.isGuarantor && !!champion?.contact?.email
        )))[0];
        if (guarantor) {
          linkedUserId = guarantor.contact.email;
          firstName = guarantor.contact.firstName;
          lastName = guarantor.contact.lastName;
          middleName = guarantor.contact.middleName; 
        }
      }
      const user = await userService.getAllUsers(0, ApiBatchLimits.users, { userName: linkedUserId || '' });
      if (user.entity.length > 0) {
        await patientService.saveIOCWithPartialObject(
          { linkedUserId },
          selectedEncounter.patientEncounterId
        );
      } else {
        const userClientScopeToSave = new UserInfoClient();
        userClientScopeToSave.clientId = selectedEncounter?.clientId;
        userClientScopeToSave.isFacilityLevel = true;
        userClientScopeToSave.allowedFacilities = [];

        const userToCreate = {
          userName: linkedUserId,
          firstName,
          lastName,
          userScope: "FINPAY",
          clientId: selectedEncounter?.clientId,
          entityId: 0,
          isActive: true,
          isPatient: true,
          convRunNum: 1,
          userRole: {
            userRoleId: 4,
            roleName: "Account Holder",
            roleDesc: "User Role for Patients or Patient Guarators",
            roleType: "E",
            isActive: true
          },
          allowedClients: [userClientScopeToSave],
        };
        const newUser = await userService.createOrUpdateUser(userToCreate);
        
        await patientService.saveIOCWithPartialObject(
          { linkedUserId: newUser.entity.userName },
          selectedEncounter.patientEncounterId
        );
      }

      const iocResponse = await patientService.getPatientInstanceOfCare({
        patientId: selectedEncounter.patientId,
        encounterId: selectedEncounter.patientEncounterId,
      });

      if (iocResponse.hasErrors) {
        throw new Error(iocResponse.errorMessage);
      }
      
      dispatch(setSelectedEncounter(iocResponse.entity));

    } catch (error) {
      dispatch(showErrorStatus((error as Error).message));
      throw error; // Re-throw the error to stop execution
    } finally {
      setIsEnrollLoading(false);
    }
  };

  const listItem = (
    <>
      <Grid container spacing={2} className="mx-1 mt-0_5" style={isHighRisk ? {border: '2px solid #DF1E44'} : {}}>
        <Grid item xs={2} >
          <Typography variant="h4">
            {paymentProgram?.patientPaymentSchedule?.terms}
          </Typography>
        </Grid>
        <Grid item xs={isEnrollBtnVisible ? 4 : 5}>
          <Typography variant="h4">
            {formatNumberToUSD(paymentProgram?.downPmtAmt)}
          </Typography>
        </Grid>
        <Grid item xs={isEnrollBtnVisible ? 4 : 5}>
          <Typography variant="h4">
            {formatNumberToUSD(paymentProgram?.patientPaymentSchedule?.paymentDueAmt)}
          </Typography>
        </Grid>
        {isEnrollBtnVisible && (
        <Grid item xs={2} data-testid='payment-program-enroll-button' style={{ marginTop: '-12px' }}>
          <Button
            loading={isEnrollLoading}
            disabled={false}
            onClick={async () => {
              try {
                await handleEnrollOrConvert();
                // Success handling
              } catch (error) {
                // Additional error handling if needed
                console.error('Enrollment failed:', error);
              }
            }}
            spinnerLeftPosition={5}
          >
            Enroll
          </Button>
        </Grid>
        )}
      </Grid>
    </>
  );

  const showStatus = (payment: Payment) => !(payment?.paymentMethodUsed?.ach && !payment?.isCaptured && !(selectedEncounter?.workflow?.workflowId === 3));

  const downPaymentOccurenceItem = (payment: Payment, isGreyBackground: boolean) => {
    let shortDate = "", fullDate = ""
    if(payment.paymentInitDt) {
      let arrayDate = Utils.convertISODate(new Date(payment.paymentInitDt), showDownPaymentDetails).split(" ")
      shortDate = arrayDate[0]
      if(showDownPaymentDetails) fullDate = `${arrayDate[1]} ${arrayDate[2]} `
    }
    return <>
      <Grid container spacing={2} className="mx-1 pr-2 mt-2" style={isGreyBackground ? {backgroundColor: '#f5f7f9'} : {}}>
        <Grid item xs={2}  >
          <Typography variant="body2">
              {shortDate}
          </Typography>
          <Typography variant="body2">
              {fullDate}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="body2">
            {Utils.getPaymentTypeName(payment)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {(showStatus(payment)) && (
            <Typography variant="body2" className="pl-2">
              <Status
              text={(payment?.isCaptured || payment.paymentMethodUsed?.ach) ? payment?.paymentStatus || '' : "Uncaptured"}
              statusColor={
                (payment?.paymentStatus === PaymentStatus.success && payment?.isCaptured ) ? StatusColors.success :
                ((payment?.paymentStatus === PaymentStatus.pending || !payment?.isCaptured  ) ? StatusColors.warning
                : StatusColors.error)
              }
            />
            </Typography>
          )}
          <Typography variant="body2" className="break-word pl-2">
            {showDownPaymentDetails ?
              Utils.getTransactionDetails(payment) : ""
            }
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2">
            {formatNumberToUSD(payment?.paymentAmt)}
          </Typography>
          <Typography variant="body2" className="break-word">
            {showDownPaymentDetails  && (
              Utils.getExternalPaymentId(payment)
            )}
          </Typography>
        </Grid>
        <Grid item xs={1} style={{marginTop: -12, maxHeight: 10}}>
          {(!(selectedEncounter?.workflow?.workflowId === 3)) && (
            <>
              <IconButton
                aria-haspopup="true"
                onClick={(e) => {
                  const isPaymentTypeCard = (payment?.paymentMethodUsed?.paymentMethodTypeId === PAYMENT_METHOD_TYPES.CARD.paymentMethodTypeId)
                  const paymentMethod = !isPaymentTypeCard ? 2 : payment.paymentMethodUsed?.paymentMethodTypeId
                  const isDownPayment = true
                  handleMenuClick(e, payment?.paymentId, isPaymentTypeCard, isDownPayment, paymentMethod)
                }}
                size="large">
                <MoreHorizIcon />
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
    </>;
  }


  const recurringPaymentOccurenceItem = (payment: StripePaymentMethod) => (
    <>
      <Grid container spacing={2} className="mx-1 pr-2 px-1 mt-0_5" data-testid="recurring-payment-items">
        <Grid item xs={5} >
          <Typography variant="h4">
            {payment?.name}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4">
            {(payment?.object === 'card') ? "Credit Card" : "ACH"}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4">
            {formatNumberToUSD(paymentProgram?.patientPaymentSchedule?.paymentDueAmt)}
          </Typography>
        </Grid>
        <Grid item xs={1} style={{marginTop: -12, maxHeight: 10}} className="pr-3">
          {(!(selectedEncounter?.workflow?.workflowId === 3)) && (
            <>
              <IconButton
                aria-haspopup="true"
                onClick={(e) => {
                  const isPaymentTypeCard = (payment && (payment?.object === 'card'))
                  const isDownPayment = false
                  handleMenuClick(e, payment?.id, isPaymentTypeCard, isDownPayment, 0)
                }}
                size="large">
                <MoreHorizIcon />
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );


  const highRiskDetails = ( // supporting components for when the terms are high risk
    <>
      <Grid container spacing={1} className="pt-3 px-2 mt-4">
        <Grid item xs={8}>
          <Typography variant="h4">
            Step 1
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Fade in={copiedToClipboardMsg}>
              <Typography variant="h3" color="primary" className="px-0" align="center">
                Copied to clipboard
              </Typography>
          </Fade>
        </Grid>
        <Grid item xs={6} className="ml-4">
          <Typography variant="h4">
            Email the facility with the details of this instance of care
          </Typography>
        </Grid>
        <Grid item xs={5} className="ml-4 w-100">
          <Box display="flex" justifyContent="flex-end" marginRight="-5rem">
            <CopyToClipboard text={clipboard} onCopy={() => handleShowMessage()}>
              <Button type="text">
                Copy Details
              </Button>
            </CopyToClipboard>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">
            Step 2
          </Typography>
        </Grid>
        <Grid item xs={6} className="ml-4">
          <Typography variant="h4">
            Approve High Risk Exception and provide details received from the Facility
          </Typography>
        </Grid>
        <Grid item xs={5} className="ml-4">
          <Box display="flex" justifyContent="flex-end" marginRight="-2rem">
            <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Add Instance of Care"
              renderNoAccess={() => <Button disabled>Approve</Button>}>
              <Button onClick={() => setIsHighRiskModalOpen(true)}>Approve</Button>
            </AccessControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )

  const paymentList = (paymentTitle: string, listItems: any[], paymentAmt: string) => {
    return (
      <>
        <Grid
          container
          spacing={2}
          className="pb-3 px-2"
        >
          {sectionHeader(paymentTitle, paymentAmt)}
          <Grid container direction="row" className="mx-1 pl-3 px-1 py-1 mt-3 header">
            <Grid item xs={2}>
              <Typography variant="h4">
                Initiated Date
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h4" className="break-word">
                Type
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h4" className="pl-3">
                Status / Source
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h4">
                Amount/ ID
              </Typography>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          {listItems.map((item: Payment, index: number) => (
            ((item?.paymentMethodUsed?.ach && !item.isCaptured) && isAccountHolderUser) ?
            ""
            :
            (<React.Fragment key={index}>
              {downPaymentOccurenceItem(
                item,
                (index % 2 !== 0) // if there is no Payor Id, then the Payor Id is the Patient Id.
              )}
            </React.Fragment>)
          ))}
          <Grid container className="pt-5 mx-1 px-1 mt-5">
            {(paymentTitle === "Down Payment") && (
              <Grid container spacing={3}>
                {sectionHeader('Estimated PFR', `${formatNumberToUSD(selectedEncounter?.pfrAmt)}`)}
                {(doPFRAdjustmentsExist) && (
                  sectionHeader('Current PFR', `${formatNumberToUSD(selectedEncounter?.adjustedPfrAmt)}`)
                )}
                {sectionHeader('Down Payments', `${formatNumberToUSD(totalDownPayments)}`)}
                {sectionHeader('Pending Remaining Balance', `${formatNumberToUSD(paymentProgram?.patientPaymentSchedule?.pfrPendingBalance)}`)}
                {sectionHeader('Remaining Balance', `${formatNumberToUSD(paymentProgram?.patientPaymentSchedule?.pfrCapturedBalance)}`)}
              </Grid>
            )
            }
            { maxPaymentAmt > 0 && (
              <div test-id="btn-add-payment">
                <Button
                  type="text"
                  paddingLeft={0}
                  icon={<AddIcon />}
                  onClick={() => handleDownPaymentModalOpen(true)}
                >
                  Add Payment
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </>
    )
  }

  const recurringPaymentList = (paymentTitle: string, paymentAmt: string) => {

    return (
      <>
        <Grid
          container
          spacing={2}
          className="pb-3 px-2"
        >
          {sectionHeader(paymentTitle, paymentAmt)}
          <Grid container direction="row" className="mx-1 pl-3 px-1 py-1 mt-3 header">
            <Grid item xs={5}>
              <Typography variant="h4">
                Payor
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h4">
                Payment Type
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h4">
                Amount
              </Typography>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          {recurringPaymentOccurenceItem(
            recurringPayment // if there is no Payor Id, then the Payor Id is the Patient Id.
          )}
        </Grid>
      </>
    )
  }

  const initialValues = {
    showDownPaymentDetails: false,
  }

  const validationSchema = Yup.object({
    showDownPaymentDetails: Yup.boolean(),
  })

  function checkIfIsValid(value: { showDownPaymentDetails: boolean }) {
    setShowDownPaymentDetails(value.showDownPaymentDetails);
    validationSchema
      .validate(value)
  }

  const accordionContents = (
    <>
      <Grid item xs={12} className="mt-2 mb-4">
        <Divider />
      </Grid>
      {isDownPaymentFetchError ? (
        <>
          <Grid item xs={10}>
            <Typography variant="h4">
              Transactions Pending. Use Refresh button to update.
            </ Typography>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end" marginTop="-1.2rem" marginRight="-1.5rem">
              <IconButton onClick={() => handleRefreshTransactions()} size="large">
                <RefreshIcon className="icon" />
              </IconButton>
            </Box>
          </Grid>
        </>
      ) : (
        <>
          {isHighRisk && (selectedEncounter?.workflow?.workflowId === 3) && (
            <Grid container direction="row" justifyContent="space-between" spacing={5} className="mb-1">
            <Grid container item xs={7} >
                  <Fade in={copiedToClipboardMsg}>
                      <Typography variant="h3" color="primary" className="px-0">
                        Copied to clipboard
                      </Typography>
                  </Fade>
                </Grid>
            <Grid container item xs={3}  >
              <Box display="flex"    >
                <CopyToClipboard text={clipboard} onCopy={() => handleShowMessage()}>
                  <Button type="text">
                    Copy Details
                  </Button>
                </CopyToClipboard>
              </Box>
            </Grid>
          </Grid>
          )}
          {downPayments && downPayments?.length > 0 ? (
            <>
            {!isAccountHolderUser && (
              <Grid container className="my-4 ml-2">
                <Grid xs={12} item>
                  <Typography variant="h3" className="mb-2">
                    Details
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validate={checkIfIsValid}
                    onSubmit={() => {}}
                  >
                    {(formik) => (
                      <Form>
                        <div className="mb-6">
                          <Toggle
                            name="showDownPaymentDetails"
                            formik={formik}
                            value={formik.values.showDownPaymentDetails}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            ) }
            {paymentList('Down Payment', downPayments, `${formatNumberToUSD(paymentProgram?.downPmtAmt)}`)}
            </>
            ) : (
              isDownPaymentLoading ? (
                <Grid item xs={12}>
                  <LoadingOverlay whiteBackground />
                </Grid>
              ) : (
                <>
                  {(!(selectedEncounter?.workflow?.workflowId === 3)) && (
                    <>
                      {sectionHeader('Down Payment', `${formatNumberToUSD(paymentProgram?.downPmtAmt)}`)}
                      <div test-id="downpay-btn-add-payor">
                        <Button
                          type="text"
                          icon={<AddIcon />}
                          paddingLeft={0}
                          onClick={() => handleDownPaymentModalOpen(true)}
                          disabled={!isHighRiskApproved()}
                        >
                          Add Payor
                        </Button>
                      </div>
                    </>
                  )}
                </>
              )
          )}
        </>
      )}
      {(!(paymentProgram?.patientPaymentSchedule?.paymentFreq === 'F')) && (
        <Grid item xs={12} className="mt-2 mb-4">
          <Divider />
        </Grid>
      )}
      {(!(paymentProgram?.patientPaymentSchedule?.paymentFreq === 'F')) && (
        isRecurringPaymentFetchError ? (
          <>
            <Grid item xs={10}>
              <Typography variant="h4">
                Payment Methods Pending. Use Refresh button to update.
              </ Typography>
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" justifyContent="flex-end" marginTop="-1.2rem" marginRight="-1.5rem">
                <IconButton onClick={() => handleRefreshPaymentMethods()} size="large">
                  <RefreshIcon className="icon" />
                </ IconButton>
              </ Box>
            </Grid>
          </>
        ) : (
          recurringPayment && recurringPayment?.id ? (
            recurringPaymentList('Recurring Payment', `${formatNumberToUSD(paymentProgram?.patientPaymentSchedule?.paymentDueAmt)}`)
          ) : (
            isRecurringPaymentLoading ? (
              <Grid item xs={12}>
                <LoadingOverlay whiteBackground />
              </Grid>
            ) : (
              <>
                {(!(selectedEncounter?.workflow?.workflowId === 3)) && (
                  <>
                    {sectionHeader('Recurring Payment', `${formatNumberToUSD(paymentProgram?.patientPaymentSchedule?.paymentDueAmt)}`)}
                    <Button
                      type="text"
                      icon={<AddIcon />}
                      paddingLeft={0}
                      onClick={() => handleRecurringPaymentModalOpen(true)}
                      disabled={!isHighRiskApproved()}
                    >
                      Add Payor
                    </Button>
                  </>
                )}
              </>
            )
          )
        )
      )}
    </>
  )

  return (
    <div className="px-2 list-header">
      {financialResponsibilityHeader}
      {paymentFrequencyDetails()}
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        className="pb-2"
      >
        <Grid container direction="row" className="mx-1 px-1 py-1 header">
          <Grid item xs={2}>
            <Typography variant="h4">
              Terms
            </Typography>
          </Grid>
          <Grid item xs={isEnrollBtnVisible ? 4 : 5}>
            <Typography variant="h4">
              Down Payment
            </Typography>
          </Grid>
          <Grid item xs={isEnrollBtnVisible ? 4 : 5}>
            <Typography variant="h4">
              Recurring
            </Typography>
          </Grid>
          {isEnrollBtnVisible && (<Grid item xs={2} />)}
        </Grid>
        {listItem}
        {isHighRisk && !(selectedEncounter?.workflow?.workflowId === 3) && !isHighRiskApproved() && highRiskDetails}
        {accordionContents}
      </Grid>
      {isHighRiskModalOpen && (<HighRiskModal
        open={isHighRiskModalOpen}
        handleModalClose={handleHighRiskModalClose}
      />
      )}
      {isReleaseFundsModalOpen && (
        <DeleteModal
          open={isReleaseFundsModalOpen}
          title={`${menuText} Funds`}
          subTitle="This action cannot be undone"
          okButtonText={`${menuText} Funds`}
          handleDeleteCancel={() => setIsReleaseFundsModalOpen(false)}
          handleDeleteOk={handleReleaseFunds}
        />
      )}
      {isPaymentDeleteModalOpen && (
        <DeleteModal
          open={isPaymentDeleteModalOpen}
          title="Delete Payment"
          subTitle="This action cannot be undone"
          okButtonText="Delete"
          handleDeleteCancel={() => setIsPaymentDeleteModalOpen(false)}
          handleDeleteOk={() => handleDeletePayment(1)}
        />
      )}
      {isRecurringPaymentDeleteModalOpen && (
        <DeleteModal
          open={isRecurringPaymentDeleteModalOpen}
          title="Delete Source"
          subTitle="This action cannot be undone"
          okButtonText="Delete"
          handleDeleteCancel={() => setIsRecurringPaymentDeleteModalOpen(false)}
          handleDeleteOk={() => handleDeletePayment(2)}
        />
      )}
      {(patientModalOpenStatus.isPaymentProgramModalOpen || paymentProgramUpdateRequired) && (
        <PaymentProgramsModal
          open={patientModalOpenStatus.isPaymentProgramModalOpen || paymentProgramUpdateRequired}
          handleModalClose={handlePaymentProgramsModalClose}
          isEdit
        />
      )}
      {isDownPaymentModalOpen && (
        <DownPaymentModal
          open={isDownPaymentModalOpen}
          handleModalClose={handleDownPaymentRecurringPaymentModalClose}
        />
      )}
      {
      // isDownPaymentModalOpen && 
      //   <TakePaymentModal
      //     open={isDownPaymentModalOpen} 
      //     handleModalCancel={handleDownPaymentRecurringPaymentModalClose}
      //     handleSubmitCallBack={handleTakePaymentCallBack}
      //     paymentData={{
      //       patientId: selectedPatient.patientId,
      //       patientEncounterId: selectedEncounter.patientEncounterId,
      //       defaultPaymentAmt: 0,
      //       paymentChannelId: channelId,
      //       isAuthOnly: !selectedEncounter?.isConverted,
      //     }}
      //   />
      }

      {isRecurringPaymentModalOpen && (
        <RecurringPaymentModal
          open={isRecurringPaymentModalOpen}
          handleModalClose={handleDownPaymentRecurringPaymentModalClose}
        />
      )}
      {lockingModalOpenStatus.isInUseModalOpen_paymentPrograms && <LockModal
        open={lockingModalOpenStatus.isInUseModalOpen_paymentPrograms}
        title="Instance of Care In Use"
        subTitle1={`A release request will be sent to ${lockRequestedById}`}
        subTitle2={`Access will be granted in ${countdownMax} seconds or less`}
        hasCountdown={false}
        countdownSeconds={countdownMax}
        primaryButtonType="primary"
        primaryButtonText="Request Release"
        handlePrimaryButton={handleRequestReleaseOk}
        secondaryButtonType="secondary"
        secondaryButtonText="Cancel"
        handleSecondaryButton={handleRequestReleaseCancel}
        icon={<LockIcon />}
        modalType="info"
        justifyButtons="flex-end"
        primaryButtonDisabled={isRequestReleaseButtonDisabled}
      />}

      {lockingModalOpenStatus.isRequestDeniedModalOpen_paymentPrograms && <LockModal
        open={lockingModalOpenStatus.isRequestDeniedModalOpen_paymentPrograms}
        title="Request Denied"
        subTitle1={`${lockRequestedById} will retain access to this record`}
        hasCountdown={false}
        primaryButtonType="secondary"
        primaryButtonText="Close"
        handlePrimaryButton={handleRequestDeniedClose}
        icon={<LockIcon />}
        modalType="error"
        justifyButtons="flex-end"
      />}

      {(lockingModalOpenStatus.isLockRequestedModalOpen_paymentPrograms) && <LockModal
        open={lockingModalOpenStatus.isLockRequestedModalOpen_paymentPrograms}
        title="Instance of Care Requested"
        subTitle1={`${lockRequestedById} requested this record`}
        subTitle2={`Access will be granted in ${countdownMax} seconds or less`}
        hasCountdown
        countdownSeconds={countdownMax}
        primaryButtonType="primary"
        primaryButtonText="Release Record"
        handlePrimaryButton={handleReleaseRecordOk}
        secondaryButtonType="danger"
        secondaryButtonText="Deny Request"
        handleSecondaryButton={() => dispatch(denyRequestOk({lockablePatientRecordSection: LockablePatientRecordSection.paymentPrograms}))}
        icon={<WarningRoundedIcon />}
        modalType="warning"
        justifyButtons="space-between"
      />}



      {(!(selectedEncounter?.workflow?.workflowId === 3)) && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleMenuClose()}
        >
          {isDownPayment && isPossibleToAddPayor &&
            <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Apply Payment Program"
              renderNoAccess={() => <MenuItem disabled>Add Payor</MenuItem>}>
              <MenuItem onClick={() => handleMenuClose("Add Payor")} className="primary">Add Payor</MenuItem>
            </AccessControl>}
          {isDownPayment && (
            <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Apply Payment Program"
              renderNoAccess={() => <MenuItem disabled>Release</MenuItem>}>
              <MenuItem
                onClick={() => handleMenuClose("Release")}
                className="danger"
              >
                {menuText}
              </MenuItem>
            </AccessControl>
          )}
          {(!isDownPayment) &&
            <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Apply Payment Program"
              renderNoAccess={() => <MenuItem disabled>Delete</MenuItem>}>
              <MenuItem onClick={() => handleMenuClose("Delete")} className="danger">Delete</MenuItem>
            </AccessControl>}
        </Menu>
      )}
    </div>
  );
}

export default PaymentProgramAccordionContents;
