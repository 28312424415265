import {
  GenericLevelOfCare,
  getFacilityLevelsOfCareSummary,
  getDaysBetweenAdmissionAndPolicyEndDates
} from "@finpay-development/shared-components";
import {
  PayorLocRateLos,
  EstLevelOfCare,
  EditEstLevelOfCare,
  EstPriorLevelsOfCare,
  PriorCareSummary,
  EstimatorBody,
  PFRSummary,
  PriorCarePFR,
  SummaryOopDeductible,
  CrossoverPolicies,
  MaxDaysCoPays,
  NewPriorCareSummary,
} from '../models/estimator';
import {
  FacilityPayers,
  FacilityPayerPlans,
  Vob,
  VobPostBody,
  vobClassificationsItem,
  vobClassificationGroup,
} from "../models/vob";
import { LevelOfCare } from "../../admin-configuration/models/level-of-care";
import { PayerRow } from "../../admin-configuration/models/payers";
import { ImplementationFacility } from "../../implementation-specialist/components/implementation-clients/details/models/implementation-facility";
import { vobPatient } from '../models/patient';
import { Utils } from "../../shared/utils";
import { ClientCrm, ClientStatusCardViewModel } from "../../shared/model/client-status-card";
import moment from "moment";
import {
  Estimate,
  PriorCare,
  PriorCareProvider,
  FacilityLevelOfCare, ThirdPartyPayerStatus,
  CrossoverSelectionEnum, Id, QuoteMethod,
  SummarySelections, FacilityLevelOfCareWithCrossoverDays,
} from '@finpay/estimation-types';
import _ from 'lodash';

class AdmissionAdvisorUtils {
  formatDateTime = (date: Date) => {
    var hours: number = date.getHours();
    var minutes: number = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr: string =
      minutes < 10 ? "0" + minutes : minutes.toString();
    var strTime = hours + ":" + minutesStr + " " + ampm;
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      " " +
      strTime
    );
  };

  dateFormatFromIso = (date: Date): string => {
    const utcDate: Date = new Date(
      new Date(date).toLocaleDateString("en-US", {
        timeZone: "UTC",
      })
    );
    const year = utcDate.getFullYear();
    let monthNum: number = utcDate.getMonth() + 1;
    let dtNum: number = utcDate.getDate();
    let monthStr: string = monthNum.toString();
    let dtStr: string = dtNum.toString();
    if (dtNum < 10) {
      dtStr = "0" + dtNum;
    }
    if (monthNum < 10) {
      monthStr = "0" + monthNum;
    }
    return year + "-" + monthStr + "-" + dtStr;
  };

  getFacilityName = (
    facilityId: number,
    facilities: ImplementationFacility[][]
  ) => {
    let facilityNm = "";
    let facilityItem: ImplementationFacility | undefined = facilities
      ?.flat()
      .find((fac) => fac?.facilityId === facilityId);
    if (facilityItem) {
      facilityNm = facilityItem.facilityName;
    }
    return facilityNm;
  };

  replaceFacilityPayersNulls = (
    payor: FacilityPayers,
    payersMasterList: PayerRow[]
  ) => {
    // client config data may have nulls. backfill nulls with masterList data
    let payerCopy: FacilityPayers = {
      ...payor,
    };
    const masterListItem = payersMasterList.find(
      (el) => el.payorId === payerCopy.cfgPayorId
    );
    if (masterListItem) {
      if (!payerCopy.facilityPayorName) {
        payerCopy.facilityPayorName = masterListItem.payorName;
      }
      if (!payerCopy.facilityPayorCode) {
        payerCopy.facilityPayorCode = masterListItem.externalPayorId;
      }
      payerCopy.facilityPayorPlans?.forEach((plan: FacilityPayerPlans) => {
        const masterListItemPlan = masterListItem.payorPlans.find(
          (el) => el.payorPlanId === plan.cfgPayorPlanId
        );
        if (masterListItemPlan) {
          // @todo handle facilityPayorLocRateLos.pdrRate, facilityPayorLocRateLos.losDays
          if (!plan.facilityPayorPlanCode) {
            plan.facilityPayorPlanCode =
              masterListItemPlan?.externalPayorPlanId;
          }
          if (!plan.facilityPayorPlanId) {
            plan.facilityPayorPlanId = masterListItemPlan?.payorPlanId;
          }
          if (!plan.facilityPayorPlanName) {
            plan.facilityPayorPlanName = masterListItemPlan?.planName;
          }
        }
      });
    }
    return payerCopy;
  };

  replaceFacilityLocNulls = (
    locItem: EstLevelOfCare,
    masterListLevelsOfCare: LevelOfCare[]
  ) => {
    let locCopy: EstLevelOfCare = {
      ...locItem,
    };
    const masterListItem = masterListLevelsOfCare.find(
      (el) => el.levelOfCareId === locCopy.cfgLevelOfCareId
    );
    if (masterListItem) {
      if (!locCopy.vobClassificationId) {
        locCopy.vobClassificationId = masterListItem.vobClassificationId;
      }
      if (!locCopy.facilityLocRlos) {
        locCopy.facilityLocRlos = {
          losDays: 0,
        };
      }
      locCopy.thirdPartyAverageInnLos = masterListItem.thirdPartyAverageInnLos;
      locCopy.thirdPartyAverageInnPdr = masterListItem.thirdPartyAverageInnPdr;
      locCopy.thirdPartyAverageOnnLos = masterListItem.thirdPartyAverageOnnLos;
      locCopy.thirdPartyAverageOnnPdr = masterListItem.thirdPartyAverageOnnPdr;
    }
    return locCopy;
  };

  fillClientFacilityDataGaps = (data: any): EstLevelOfCare[] => {
    let facilityLocPayersCopy = {
      ...data,
    };

    let locCopy: EstLevelOfCare;
    let facilityLocCopy: EstLevelOfCare[] = [];
    let masterListLoc = facilityLocPayersCopy.masterListLevelsOfCare;

    facilityLocPayersCopy?.facilityLevelsOfCare?.forEach(
      (loc: EstLevelOfCare) => {
        // client config data may have null values that should fall back to masterList values
        locCopy = this.replaceFacilityLocNulls(loc, masterListLoc);
        // each facilityPayer might have an array of facilityPayorLocRateLos
        // need to use only the most recent
        const activePayorLocRateLos = this.findLatestEffectiveDate(
          locCopy.facilityPayorLocRateLos as PayorLocRateLos[]
        );
        locCopy.facilityPayorLocRateLos = activePayorLocRateLos;
        facilityLocCopy.push(locCopy);
      }
    );

    return facilityLocCopy;
  };

  fillClientPayerDataGaps = (data: any): FacilityPayers[] => {
    let facilityLocPayersCopy = {
      ...data,
    };
    let facilityPayersCopy: FacilityPayers[] = [];
    let payerCopy: FacilityPayers;
    let masterListPayers = facilityLocPayersCopy.masterListPayers;
    facilityLocPayersCopy.facilityPayors?.forEach((payor: FacilityPayers) => {
      // client config data may have null values that should fall back to masterList values
      payerCopy = this.replaceFacilityPayersNulls(payor, masterListPayers);
      payerCopy.facilityPayorPlans.forEach((plan: FacilityPayerPlans) => {
        // each facilityPayer might have an array of facilityPayorLocRateLos
        // need to use only the most recent
        const activePayorLocRateLos = this.findLatestEffectiveDate(
          plan.facilityPayorLocRateLos as PayorLocRateLos[]
        );
        plan.facilityPayorLocRateLos = activePayorLocRateLos;
      });
      facilityPayersCopy.push(payerCopy);
    });
    return facilityPayersCopy;
  };

  filterLevelsOfCareByPayerPlanId = (
    levelsOfCare: EstLevelOfCare[],
    payerPlanId: number
  ): EstLevelOfCare[] => {
    let levelsOfCareForPayer: EstLevelOfCare[] = [];
    let levelOfCareCopy: EstLevelOfCare;
    let levelsOfCareLocRateLos: PayorLocRateLos[] = [];
    levelsOfCare.forEach((loc) => {
      levelOfCareCopy = Utils.deepClone(loc);
      levelsOfCareLocRateLos = [];
      delete levelOfCareCopy.facilityPayorLocRateLos;
      if (loc.facilityPayorLocRateLos) {
        levelsOfCareLocRateLos = (
          loc.facilityPayorLocRateLos as PayorLocRateLos[]
        ).filter((locRateLos) => locRateLos.cfgPayorPlanId === payerPlanId);
        if (levelsOfCareLocRateLos?.length > 0) {
          levelOfCareCopy.facilityPayorLocRateLos = levelsOfCareLocRateLos;
          levelsOfCareForPayer.push(levelOfCareCopy);
        }
      }
    });
    return levelsOfCareForPayer;
  };

  filterPayorLocRateLosByPayerPlanId = (
    levelsOfCare: EstLevelOfCare[],
    payerPlanId: number
  ): EstLevelOfCare[] => {
    let levelsOfCareForPayer: EstLevelOfCare[] = [];
    let levelOfCareCopy: EstLevelOfCare;
    let levelsOfCareLocRateLos: PayorLocRateLos[] = [];
    levelsOfCare.forEach((loc) => {
      levelOfCareCopy = Utils.deepClone(loc);
      if (loc.facilityPayorLocRateLos) {
        levelsOfCareLocRateLos = (
          loc.facilityPayorLocRateLos as PayorLocRateLos[]
        ).filter((locRateLos) => locRateLos.cfgPayorPlanId === payerPlanId);
        if (levelsOfCareLocRateLos?.length > 0) {
          delete levelOfCareCopy.facilityPayorLocRateLos;
          levelOfCareCopy.facilityPayorLocRateLos = levelsOfCareLocRateLos;
        }
      }
      levelsOfCareForPayer.push(levelOfCareCopy);
    });
    return levelsOfCareForPayer;
  };

  getNewPriorLevelsOfCare = (
      priorCareState: PriorCare,
      facilities: ImplementationFacility[][]
  ): NewPriorCareSummary => {

    switch (priorCareState.priorCareProvider) {
      case PriorCareProvider.THIRDPARTY:
        return {
          labelOne: "Prior Care Class",
          labelTwo: "Third Party Payer Status",
          valueOne: "Third Party",
          valueTwo: (()=>{
            switch(priorCareState.thirdPartyPayerStatus){
              case ThirdPartyPayerStatus.INNETW:
                return "Average In-Network"
              case ThirdPartyPayerStatus.OONETW:
                return "Average Out-of-Network"
              default:
                return "N/A"
            }
          })(),
          priorLevelsOfCare: priorCareState?.loc,
        };
      case PriorCareProvider.CLIENT:
        return {
          labelOne: "Prior Care Class",
          labelTwo: "Facility",
          valueOne: "Client",
          valueTwo: `${admissionsAdvisorUtils.getFacilityName(
              +priorCareState.facilityId!, facilities)}`,
          priorLevelsOfCare:  priorCareState?.loc,
        };
      default:
        throw new Error("Unsupported provider type")
    }
  };

  getPriorLevelsOfCare = (
    priorCareState: EstPriorLevelsOfCare
  ): PriorCareSummary => {
    let returnData: PriorCareSummary = {
      masterListLoc: true,
      labelOne: "",
      labelTwo: "",
      valueOne: "",
      valueTwo: "",
      priorLevelsOfCare: [],
    };

    switch (priorCareState.priorCareProvider.value) {
      case "thirdparty":
        returnData = {
          masterListLoc: true,
          labelOne: "Prior Care Class",
          labelTwo: "Third Party Payer Status",
          valueOne: priorCareState?.priorCareProvider?.name,
          valueTwo: priorCareState?.thirdPartyPayerStatus?.name,
          priorLevelsOfCare: priorCareState?.priorThirdPartyLevelsOfCare,
        };
        break;
      case "client":
        returnData = {
          masterListLoc: false,
          labelOne: "Prior Care Class",
          labelTwo: "Facility",
          valueOne: priorCareState?.priorCareProvider?.name,
          valueTwo: priorCareState?.facility?.facilityName!,
          priorLevelsOfCare: priorCareState?.priorFacilityLevelsOfCare,
        };
        break;
      default:
    }
    return returnData;
  };

  getNewGenericPriorAdjChg = (
      priorCare: PriorCare,
      estimatorState: Estimate,
      vobState: Vob
  ): GenericLevelOfCare[] => {

    if(Array.isArray(priorCare?.loc)){

      return priorCare.loc.map(
          (loc: FacilityLevelOfCare ) => {
            return {
              code: loc.facilityLevelOfCareCode || "",
              name: loc.facilityLevelOfCareName || "",
              days: loc.losDays || 0,
              rate: loc.pdrRate || 0.0,
              cfgLocType: loc.locType,
              covered: vobState.selfPay ? false : loc.isCovered,
              vobClassificationId: loc.vobClassificationId,
              facilityId: +priorCare.facilityId! || 0,
            }
          }
      );
    } else{
      return []
    }

  };

  getGenericPriorAdjChg = (
    priorLevelsOfCareSummary: PriorCareSummary,
    estimatorState: EstimatorBody,
    vobState: Vob
  ): GenericLevelOfCare[] => {
    let priorLoc: GenericLevelOfCare[] = [];
    let code = "";
    let name = "";
    let days: number = 0;
    let rate: number = 0.0;
    let isCovered: boolean = false;
    let facilityId: number = 0;
    let vobClassificationId: number | undefined = undefined;
    const inNetwork = vobState?.payer?.inNetwork;

    if (priorLevelsOfCareSummary.masterListLoc) {
      priorLevelsOfCareSummary?.priorLevelsOfCare?.forEach(
        (loc: LevelOfCare | EstLevelOfCare) => {
          code = (loc as LevelOfCare)?.levelOfCareCode;
          name = (loc as LevelOfCare)?.levelOfCareName;
          if (
            estimatorState?.priorCare?.priorCareProvider?.value ===
              "thirdparty" &&
            estimatorState?.priorCare?.thirdPartyPayerStatus?.value === "innetw"
          ) {
            days = (loc as LevelOfCare)?.lengthOfStay!;
            rate = (loc as LevelOfCare)?.thirdPartyAverageInnPdr!;
            // @todo - determine if thirdparty isCovered
            isCovered = true;
            vobClassificationId = loc.vobClassificationId!;
            facilityId = -1;
          } else if (
            estimatorState?.priorCare?.priorCareProvider?.value ===
              "thirdparty" &&
            estimatorState?.priorCare?.thirdPartyPayerStatus?.value === "oonetw"
          ) {
            days = (loc as LevelOfCare)?.lengthOfStay!;
            rate = (loc as LevelOfCare)?.thirdPartyAverageOnnPdr!;
            // @todo - determine if thirdparty isCovered
            isCovered = true;
            vobClassificationId = loc.vobClassificationId!;
            facilityId = -1;
          }

          priorLoc.push({
            code: code,
            name: name,
            days: days,
            rate: rate,
            cfgLocType: (loc as LevelOfCare).locType,
            covered: vobState.selfPay ? false : isCovered,
            vobClassificationId: vobClassificationId,
            facilityId: facilityId,
          });
        }
      );
    } else {
      priorLevelsOfCareSummary?.priorLevelsOfCare?.forEach(
        (loc: LevelOfCare | EstLevelOfCare) => {
          const facilityLoc = loc as EstLevelOfCare;
          if (facilityLoc?.facilityPayorLocRateLos as PayorLocRateLos) {
            days = (facilityLoc?.facilityPayorLocRateLos as PayorLocRateLos)
              .losDays!;
            if (
              (facilityLoc?.facilityPayorLocRateLos as PayorLocRateLos).pdrRate
            ) {
              rate = (facilityLoc?.facilityPayorLocRateLos as PayorLocRateLos)
                .pdrRate!;
            } else {
              rate = inNetwork
                ? facilityLoc?.thirdPartyAverageInnPdr!
                : facilityLoc?.thirdPartyAverageOnnPdr!;
            }
            if (
              (facilityLoc?.facilityPayorLocRateLos as PayorLocRateLos)
                .isCovered
            ) {
              isCovered = (
                facilityLoc?.facilityPayorLocRateLos as PayorLocRateLos
              ).isCovered!;
            } else {
              isCovered = false;
            }
            code = facilityLoc.facilityLevelOfCareCode!;
            name = facilityLoc.facilityLevelOfCareName!;
            vobClassificationId = facilityLoc.vobClassificationId!;
            facilityId = facilityLoc.clientFacilityId;

            priorLoc.push({
              code: code,
              name: name,
              days: days,
              rate: rate,
              cfgLocType: (loc as LevelOfCare).locType,
              covered: vobState.selfPay ? false : isCovered,
              vobClassificationId: vobClassificationId,
              facilityId: facilityId,
            });
          }
        }
      );
    }

    return priorLoc;
  };

  getPriorCareAdjChg = (
    estimatorState: EstimatorBody,
    vobState: Vob
  ): PriorCarePFR => {
    const priorLevelsOfCareSummary = this.getPriorLevelsOfCare(
      estimatorState?.priorCare as EstPriorLevelsOfCare
    );
    let priorLoc: GenericLevelOfCare[] = [];

    // get prior Loc Adjustments as GenericLevelOfCare[]
    priorLoc = this.getGenericPriorAdjChg(
      priorLevelsOfCareSummary,
      estimatorState,
      vobState
    );

    const adjustedFinancialSummary = this.doFinancialSummaryCalcs(
      priorLoc,
      estimatorState,
      vobState
    );

    const priorCareOopDeductible: SummaryOopDeductible = {
      deductibleSpent: adjustedFinancialSummary.deductibleSpent,
      remainingDeductible: adjustedFinancialSummary.remainingDeductible,
      remainingOopMax: adjustedFinancialSummary.includeOOPmax
        ? adjustedFinancialSummary.remainingOopMaxAfterServices
        : -1,
    };

    // rerun financialSummaryCalcs with prior loc data but prior care deductible and maxoop
    const financialSummary = this.calculateFinancialSummary(
      estimatorState,
      vobState,
      priorCareOopDeductible
    );

    return {
      priorCarePFR: adjustedFinancialSummary,
      adjustedCurrentPFR: financialSummary,
    };
  };

  calculateCrossoverPfr = (
    crossOverDays: number,
    estimatorState: EstimatorBody,
    vobState: Vob
  ): CrossoverPolicies => {
    // split the vob and estimate so that they begin/end on policy begin/end dates
    let firstEstimate = Utils.deepClone(estimatorState);
    let currentPolicyVob = Utils.deepClone(vobState);

    const inNetwork = vobState?.payer?.inNetwork;
    const allLevelsOfCare: GenericLevelOfCare[] =
      getFacilityLevelsOfCareSummary(
        firstEstimate.selectedLevelsOfCare,
        firstEstimate.quoteMethod,
        inNetwork!,
        vobState?.selfPay!
      );
    // total days
    const totalLos: number = allLevelsOfCare.reduce(
      (n, { days }) => n + days,
      0
    );

    // current policy period
    const currentPolicyDays: number = totalLos - crossOverDays;
    const currentPolicyDaysPerLoc =
      currentPolicyDays / allLevelsOfCare.length > 0
        ? currentPolicyDays / allLevelsOfCare.length
        : 0;
    const currentPolicyLevelsOfCare: GenericLevelOfCare[] = allLevelsOfCare.map(
      (loc) => ({
        ...loc,
        days: currentPolicyDaysPerLoc,
      })
    );
    const currentPolicyFinancialSummary = this.doFinancialSummaryCalcs(
      currentPolicyLevelsOfCare,
      firstEstimate,
      currentPolicyVob
    );

    // next policy period
    let nextPolicyVob = Utils.deepClone(vobState);
    const nextPolicyDays: number = crossOverDays;
    const nextPolicyDaysPerLoc =
      nextPolicyDays / allLevelsOfCare.length > 0
        ? nextPolicyDays / allLevelsOfCare.length
        : 0;
    const nextPolicyLevelsOfCare: GenericLevelOfCare[] = allLevelsOfCare.map(
      (loc) => ({
        ...loc,
        days: nextPolicyDaysPerLoc,
      })
    );
    // reset remaining oops in second vob
    nextPolicyVob.inNetwDeductibleRemaining = nextPolicyVob.inNetwDeductible;
    nextPolicyVob.inNetwFamilyDeductibleRemaining =
      nextPolicyVob.inNetwFamilyDeductible;
    nextPolicyVob.inNetwOopMaxRemaining = nextPolicyVob.inNetwOopMax;
    nextPolicyVob.inNetwFamilyOopMaxRemaining =
      nextPolicyVob.inNetwFamilyOopMax;
    nextPolicyVob.ooNetwDeductibleRemaining = nextPolicyVob.ooNetwDeductible;
    nextPolicyVob.ooNetwFamilyDeductibleRemaining =
      nextPolicyVob.ooNetwFamilyDeductible;
    nextPolicyVob.ooNetwOopMaxRemaining = nextPolicyVob.ooNetwFamilyOopMax;
    nextPolicyVob.ooNetwFamilyOopMaxRemaining =
      nextPolicyVob.ooNetwFamilyOopMax;

    const nextPolicyFinancialSummary = this.doFinancialSummaryCalcs(
      nextPolicyLevelsOfCare,
      estimatorState,
      nextPolicyVob
    );
    return {
      currentPolicyFinancialSummary: currentPolicyFinancialSummary,
      nextPolicyFinancialSummary: nextPolicyFinancialSummary,
    };
  };

  findLatestEffectiveDate = (
    facilityPayorLocRateLos: PayorLocRateLos[]
  ): PayorLocRateLos | undefined => {
    let latest: PayorLocRateLos = {
      facilityPayorLocRateLosId: undefined,
      facilityLevelOfCareId: undefined,
      facilityPayorPlanId: undefined,
      effectiveDate: "",
      isCovered: false,
      pdrRate: 0,
      losDays: 0,
      entryType: "",
    };
    if (facilityPayorLocRateLos?.length! > 1) {
      latest = facilityPayorLocRateLos?.reduce(function (r, a) {
        if (r.effectiveDate && a.effectiveDate) {
          return r.effectiveDate > a.effectiveDate ? r : a;
        } else {
          return r;
        }
      });
    } else if (facilityPayorLocRateLos?.length! === 1) {
      latest = facilityPayorLocRateLos![0];
    }
    if (!latest.losDays) {
      latest.losDays = 0;
    }
    if (!latest.pdrRate) {
      latest.pdrRate = 0;
    }
    return latest;
  };

  isPolicyActive = (vobState: Vob): boolean => {
    const startDate = moment(vobState.policyBeginDate);
    const endDate = moment(vobState.policyEndDate);
    const todayDate = moment();
    return (
      vobState.activePolicy &&
      todayDate.isBetween(startDate, endDate, "days", "[]")
    );
  };

  calculateTotalSurchargeRate = (
      levelsOfCare: EditEstLevelOfCare[] | undefined
  ): number => {
      const result = levelsOfCare?.reduce((accumulator, currentValue) => {
          if (currentValue.cfgLocType === 1) {
              accumulator += currentValue?.selectedRateLos?.pdrRate!;
          }
          return accumulator;
      }, 0);
      return result ? result * 100 : 0;
  };

  newEstimateCalculateTotalSurchargeRate = (
      levelsOfCare: FacilityLevelOfCare[] | undefined
  ): number => {
      const result = levelsOfCare?.reduce((accumulator, currentValue) => {
          if (currentValue.locType === 1) {
              accumulator += currentValue?.pdrRate!;
          }
          return accumulator;
      }, 0);
      return result ? result * 100 : 0;
  };

  getSelectedPFR = (estimatorState: EstimatorBody) => {
    let returnPfr = 0.0;
    switch (estimatorState.selectedPFRName) {
      case "Total Estimated PFR":
        returnPfr = estimatorState.totalEstimatedPfr
          ? estimatorState.totalEstimatedPfr
          : 0.0;
        break;
      case "Prior Care Adjusted PFR":
        returnPfr = estimatorState.priorCareAdjustmentPfr
          ? estimatorState.priorCareAdjustmentPfr
          : 0.0;
        break;
      case "Plan Crossover":
        returnPfr = estimatorState.crossoverAdjustmentPfr
          ? estimatorState.crossoverAdjustmentPfr
          : 0.0;
        break;
      case "Financial Assistance PFR":
        returnPfr = estimatorState.financialAssistanceAdjustmentPfr
          ? estimatorState.financialAssistanceAdjustmentPfr
          : 0.0;
        break;
      default:
      // nothing
    }
    return returnPfr;
  };



  // this gets the lower of ind deductible/fam deductible ind oop/fam oop
  getLowerAmt = (
    individualAmt: number | string | null | undefined,
    familyAmt: number | string | null | undefined
  ): number => {
    const amounts = [individualAmt, familyAmt];
    const min = amounts.reduce(function (prvAmt, curAmt) {
      return curAmt !== null && curAmt !== "" && curAmt! < prvAmt!
        ? curAmt
        : prvAmt;
    }, Infinity);
    return min && min !== Infinity ? parseFloat(min.toString()) : 0;
  };

  // for inpatient servicelevel, copays get capped by max-days across Levels of Care that share the same vobClassification
  // the maxDaysCoPays array will hold all copays by vobClassificationId so we can apply the max-days cap across Levels of Care that share vobClassification
  trackMaxDaysCoPays = (
    vobClassificationId: number | undefined,
    coPayAmt: number,
    los: number,
    maxDays: number,
    maxDaysCoPays: MaxDaysCoPays[],
  ) => {
    let existingCoPayAmt: number = 0;
    let existingLos: number = 0;
    let newLosTotal: number = 0;
    let newCopyAmt: number = 0;

    if (vobClassificationId) {
      // remove this copayitem if exists
      let index = maxDaysCoPays?.findIndex(
        (copayitem: MaxDaysCoPays) =>
          copayitem.vobClassificationId === vobClassificationId
      );
      if (index! > -1) {
        existingCoPayAmt = maxDaysCoPays[index].coPayAmt;
        existingLos = maxDaysCoPays[index].losTotal;
        maxDaysCoPays?.splice(index!, 1);
      }
      newLosTotal = existingLos + los;
      // dont allow copays beyond max days
      if (maxDays) {
        if (newLosTotal >= maxDays) {
          newCopyAmt = maxDays * coPayAmt;
        } else {
          newCopyAmt = existingCoPayAmt + coPayAmt * los;
        }
      } else {
        newCopyAmt = existingCoPayAmt + coPayAmt * los;
      }
      return {
        vobClassificationId: vobClassificationId,
        losTotal: newLosTotal,
        maxDays: maxDays,
        coPayAmt: newCopyAmt,
      };
    }
  };

  // get Vob Classification for Level of Care
  getVobClassification = (
    vobClassificationId: Number | undefined,
    vobClassifications: vobClassificationGroup[] | undefined
  ): vobClassificationGroup | null | undefined => {
    let selectedVobClassification = vobClassifications?.find(
      (vclass) =>
        vclass?.selectedClassification?.vobClassificationId ===
        vobClassificationId
    );
    // fall back to vobClassification[0]
    if (!selectedVobClassification) {
      if (vobClassifications?.length) {
        selectedVobClassification = vobClassifications[0];
      }
    }
    return selectedVobClassification;
  };

  /*
   Calculates estimate totals based on a list of generic LoCs and a VoB
   */
  doFinancialSummaryCalcs = (
    genericLevelsOfCare: GenericLevelOfCare[],
    estimatorState: EstimatorBody,
    vob: Vob,
    oopMaxDeductible?: SummaryOopDeductible
  ): PFRSummary => {
    let remainingOopMax = 0.0;
    let remainingDeductible = 0.0;
    let deductible = 0.0;
    let deductibleSpent = 0.0;
    let isDeductibleMetInPriorCare: boolean = false;
    let isDeductibleMet: boolean = false;
    let hasPriorCare: boolean = false;
    let includeOOPmax: boolean = true;

    let inPatientServiceLevel: boolean =
      estimatorState.serviceLevel === "inpatient" ? true : false;

    const inNetwork = vob?.payer?.inNetwork;

    const deductibleIncludedInOop: boolean = inNetwork
      ? vob.inNetwOopIncluded
      : vob.ooNetwOopIncluded;

    let vobClassifications = inNetwork
      ? vob.inNetwVobClassifications
      : vob.ooNetwVobClassifications;

    let oopMaxFromVob = inNetwork
      ? this.getLowerAmt(
          vob?.inNetwOopMax,
          vob?.inNetwFamilyOopMax
        )
      : this.getLowerAmt(
          vob?.ooNetwOopMax,
          vob?.ooNetwFamilyOopMax
        );

    const remainingOopMaxFromVob = inNetwork
      ? this.getLowerAmt(
          vob?.inNetwOopMaxRemaining,
          vob?.inNetwFamilyOopMaxRemaining
        )
      : this.getLowerAmt(
          vob?.ooNetwOopMaxRemaining,
          vob?.ooNetwFamilyOopMaxRemaining
        );

    let maxDaysCoPays: MaxDaysCoPays[] = []

    if (oopMaxDeductible) {
      hasPriorCare = true;
      remainingOopMax = oopMaxDeductible.remainingOopMax;
      remainingDeductible = oopMaxDeductible.remainingDeductible;
      isDeductibleMetInPriorCare =
        oopMaxDeductible.remainingDeductible === 0 ? true : false;
    } else {
      remainingOopMax = remainingOopMaxFromVob 
      remainingDeductible = inNetwork
        ? this.getLowerAmt(
            vob?.inNetwDeductibleRemaining,
            vob?.inNetwFamilyDeductibleRemaining
          )
        : this.getLowerAmt(
            vob?.ooNetwDeductibleRemaining,
            vob?.ooNetwFamilyDeductibleRemaining
          );
    }
    deductible = remainingDeductible;

    // if remaining out of pocket is -1 then don't calc max oop
    if (remainingOopMax === -1) {
      includeOOPmax = false;
    }

    let lengthOfStay = 0;
    let coveredLengthOfStay = 0;
    let uncoveredLengthOfStay = 0;
    let copayOnlyLengthOfStay = 0;
    let locCharge = 0.0;
    let locPatientPayment = 0.0;
    let serviceCharges = 0.0;
    let coveredCharges = 0.0;
    let unCoveredCharges = 0.0;
    let patientPfr = 0.0;
    let percentOfPfr = 0.0;
    let coveredSvcsPfr = 0.0;
    let patientTotalCoInsurance = 0.0;
    let patientTotalCoPays = 0.0;
    let coveredLevelsOfCare: GenericLevelOfCare[] = [];

    // add up coveredCharges, unCoveredCharges, serviceCharges, deductibleSpent
    genericLevelsOfCare.forEach((loc) => {
      // get vob classification for this loc
      let selectedVobClassification = this.getVobClassification(
        loc.vobClassificationId,
        vobClassifications
      );

      lengthOfStay += loc?.days;
      if (loc.cfgLocType === 1) {
        // skip surcharge loc
      } else {
        if (loc?.days > 0) {
          locCharge = loc?.days * loc?.rate;
          if (loc.covered && !vob.selfPay) {
            coveredLengthOfStay += loc.days;
            coveredCharges += locCharge;
            coveredLevelsOfCare.push(loc);

            if (selectedVobClassification?.coPaySelection === "copayonly") {
              if (inPatientServiceLevel) {
                copayOnlyLengthOfStay += loc.days;

                const copay = this.trackMaxDaysCoPays(
                    loc.vobClassificationId,
                    selectedVobClassification?.coPay!,
                    loc.days,
                    selectedVobClassification.maxDays!,
                    maxDaysCoPays
                );

                if(copay) maxDaysCoPays.push(copay) 
              } else {
                patientTotalCoPays +=
                    loc.days * selectedVobClassification?.coPay!;
              }
            } else {
              serviceCharges += locCharge;
              if (serviceCharges >= remainingDeductible) {
                // subtract deductible
                if (!isDeductibleMet)
                  serviceCharges = serviceCharges - remainingDeductible;
                deductibleSpent += remainingDeductible;
                remainingDeductible = 0;
                isDeductibleMet = true;
              } else {
                deductibleSpent += locCharge;
                remainingDeductible =
                    remainingDeductible - coveredCharges > 0
                        ? remainingDeductible - coveredCharges
                        : 0;
              }
            }
          } else {
            uncoveredLengthOfStay += loc.days;
            unCoveredCharges += locCharge;
          }
        }
      }
    });

    coveredLevelsOfCare.forEach((loc) => {

      if (loc.cfgLocType===1) {
        // skip surcharge loc

      } else {
        // get vob classification for this loc
        let selectedVobClassification = this.getVobClassification(
            loc.vobClassificationId,
            vobClassifications
        );

        // get % of charges for this loc (loc.day / entire los)
        // remove length of stay days that are copay only from locPatientPayment
        percentOfPfr = loc.days / (coveredLengthOfStay - copayOnlyLengthOfStay);
        locPatientPayment = serviceCharges * percentOfPfr;

        if (isDeductibleMet) {
          // deductible is met - calculate coinsurance
          switch (selectedVobClassification?.coPaySelection) {
            case "n":
              patientTotalCoInsurance +=
                  locPatientPayment *
                  (selectedVobClassification?.coInsurance! / 100);
              break;
            case "copayonly":
              // copay only was already calculated in the loop above
              patientTotalCoInsurance += 0;
              break;
            default:
              patientTotalCoInsurance +=
                  locPatientPayment *
                  (selectedVobClassification?.coInsurance! / 100);
          }
        }
        // Pulling copay calculation out of behind deductible met if
        switch (selectedVobClassification?.coPaySelection) {
          case "n":
            patientTotalCoPays += 0;
            break;
          case "copayonly":
            break;
          default:
            if (inPatientServiceLevel) {
              const copay = this.trackMaxDaysCoPays(
                loc.vobClassificationId,
                selectedVobClassification?.coPay!,
                loc.days,
                selectedVobClassification!.maxDays!,
                maxDaysCoPays
            );

            if(copay) maxDaysCoPays.push(copay) 
            } else {
              patientTotalCoPays +=
                  loc.days * selectedVobClassification?.coPay!;
            }
        }
      }
    });
    // maxDaysCoPays tracks coPay max by vobCLassification so that different Levels of Care
    // that share a vob classification don't charge more than coPay * maxDays (only for inpatient service level)
    if (inPatientServiceLevel) {
      let talliedCopays: number = 0;
      maxDaysCoPays.forEach((maxdaycopay) => {
        talliedCopays += maxdaycopay.coPayAmt;
      });
      patientTotalCoPays += talliedCopays;
    }

    patientPfr = patientTotalCoPays + patientTotalCoInsurance + deductibleSpent;
    coveredSvcsPfr =
      patientTotalCoPays + patientTotalCoInsurance + deductibleSpent;

    let pfrOopCompare = patientPfr;
    if (!deductibleIncludedInOop) {
      // deductible does not count toward oop max
      pfrOopCompare = patientTotalCoPays + patientTotalCoInsurance;
    }

    if (includeOOPmax) {
      if (remainingOopMax > pfrOopCompare) {
        // we have not reached oop max
        remainingOopMax =
          remainingOopMax - pfrOopCompare > 0
            ? remainingOopMax - pfrOopCompare
            : 0;
      } else {
        patientPfr = deductibleIncludedInOop
          ? remainingOopMax 
          : remainingOopMax + deductibleSpent;
        if (hasPriorCare) {
          // oop max has been reached betw combination of prior care and current care so...
          // patientPfr = remainingOopMax;
          if (isDeductibleMetInPriorCare) {
            patientPfr = remainingOopMax;
          } else {
            patientPfr = deductibleIncludedInOop
              ? remainingOopMax
              : remainingOopMax + deductibleSpent;
          }
        }
        remainingOopMax = 0;
      }
    } else {
      remainingOopMax = 0;
    }
    let subtotalPFR = patientPfr + unCoveredCharges;
    let totalSurcharges = 0;
    // calculate surcharges
    genericLevelsOfCare.forEach((loc) => {
      if (loc.cfgLocType === 1) {
        totalSurcharges += loc.rate * subtotalPFR;
      }
    });

    let scholarshipAdjustedSubtotalPFR = 0
    let scholarshipAdjustedSurcharge = 0
    if(estimatorState?.financialAssistance?.scholarshipPercentage) {
      scholarshipAdjustedSubtotalPFR = subtotalPFR - (subtotalPFR * estimatorState.financialAssistance.scholarshipPercentage)
      genericLevelsOfCare.forEach((loc) => {
        if (loc.cfgLocType === 1) {
          scholarshipAdjustedSurcharge += loc.rate * scholarshipAdjustedSubtotalPFR;
        }
      }); 
    }

    const patientPfrSummary = {
      lengthOfStay: lengthOfStay,
      lengthOfStayCovered: coveredLengthOfStay,
      lengthOfStayUnCovered: uncoveredLengthOfStay,
      coveredCharges: coveredCharges,
      unCoveredCharges: unCoveredCharges,
      netPFR: patientPfr,
      subtotalPFR: subtotalPFR,
      totalSurcharges: totalSurcharges,
      totalPFR: subtotalPFR + totalSurcharges,
      scholarshipAdjustedSubtotalPFR: scholarshipAdjustedSubtotalPFR,
      scholarshipAdjustedSurcharge: scholarshipAdjustedSurcharge,
      scholarshipAdjustedTotalPFR: scholarshipAdjustedSubtotalPFR + scholarshipAdjustedSurcharge,
      coveredSvcsPfr: coveredSvcsPfr,
      patientTotalCoPays: patientTotalCoPays,
      patientTotalCoInsurance: patientTotalCoInsurance,
      remainingDeductible: remainingDeductible,
      deductible: deductible,
      deductibleSpent: deductibleSpent,
      oopMaxFromVob: oopMaxFromVob,
      remainingOopMaxFromVob: remainingOopMaxFromVob,
      remainingOopMaxAfterServices: remainingOopMax,
      includeOOPmax: includeOOPmax,
    };

    return patientPfrSummary;
  };

  calculateFinancialSummary = (
    estimatorState: EstimatorBody,
    vob: Vob,
    oopMaxDeductible?: SummaryOopDeductible
  ): PFRSummary => {
    const selectedFacilityLevelsOfCare: GenericLevelOfCare[] =
      getFacilityLevelsOfCareSummary(
        estimatorState.selectedLevelsOfCare!,
        estimatorState.quoteMethod!,
        vob?.payer?.inNetwork!,
        vob?.selfPay!
      );
    const patientPfrSummary: PFRSummary = this.doFinancialSummaryCalcs(
      selectedFacilityLevelsOfCare,
      estimatorState,
      vob,
      oopMaxDeductible
    );
    return patientPfrSummary;
  };

  createSelfPayVob = (
    advisorPatientId: number,
    vobBody: Vob,
    vobClassification: vobClassificationsItem
  ): VobPostBody => {
    // set begin date to 1 month ago
    let policyBeginDt: Date = new Date();
    policyBeginDt.setMonth(policyBeginDt.getMonth() - 1);
    const policyStartDate: string = this.dateFormatFromIso(policyBeginDt);
    // set end date to 1 year from now
    let policyEndDt = new Date();
    policyEndDt.setFullYear(policyEndDt.getFullYear() + 1);
    const policyEndDate: string = this.dateFormatFromIso(policyEndDt);

    let selfPayVob: VobPostBody = {
      advisorPatientId: advisorPatientId,
      fpClientId: vobBody.client?.clientId,
      fpClientFacilityId: vobBody.facility?.facilityId,
      vobBody: {
        isValid: true,
        selfPay: true,
        digitalVerificationMethod: false,
        client: vobBody.client,
        facility: vobBody.facility,
        payer: vobBody.payer,
        plan: vobBody.plan,
        groupNum: "",
        policyNum: "",
        liveVOB: false,
        activePolicy: true,
        policyBeginDate: policyStartDate,
        policyEndDate: policyEndDate,
        inNetwDeductible: 0,
        inNetwDeductibleRemaining: 0,
        inNetwFamilyDeductible: 0,
        inNetwFamilyDeductibleRemaining: 0,
        inNetwOopIncluded: true,
        inNetwOopMax: 0,
        inNetwOopMaxRemaining: 0,
        inNetwFamilyOopMax: 0,
        inNetwFamilyOopMaxRemaining: 0,
        inNetwVobClassifications: [
          {
            id: 1,
            selectedClassification: {
              vobClassificationId: vobClassification?.vobClassificationId,
              vobClassificationName: vobClassification?.vobClassificationName,
              vobClassificationDesc: vobClassification?.vobClassificationDesc,
            },
            coInsurance: 100,
            coPay: 0,
            maxDays: 0,
            coPaySelection: "y",
          },
        ],
        ooNetwDeductible: 0,
        ooNetwDeductibleRemaining: 0,
        ooNetwFamilyDeductible: 0,
        ooNetwFamilyDeductibleRemaining: 0,
        ooNetwOopIncluded: true,
        ooNetwOopMax: 0,
        ooNetwOopMaxRemaining: 0,
        ooNetwFamilyOopMax: 0,
        ooNetwFamilyOopMaxRemaining: 0,
        ooNetwVobClassifications: [
          {
            id: 2,
            selectedClassification: {
              vobClassificationId: vobClassification?.vobClassificationId,
              vobClassificationName: vobClassification?.vobClassificationName,
              vobClassificationDesc: vobClassification?.vobClassificationDesc,
            },
            coInsurance: 100,
            coPay: 0,
            maxDays: 0,
            coPaySelection: "y",
          },
        ],
      },
    };
    if (vobBody.vobId) {
      selfPayVob.vobBody.vobId = vobBody.vobId;
      selfPayVob.vobId = vobBody.vobId;
    }
    return selfPayVob;
  };

  sanitizeInput = (input: string): string => {
    if (input !== "") {
      return input.replace(/[^\w #'*+",.@áéíñóúü-]/gim, "");
    } else {
      return input;
    }
  };

  crmVOBUpdate = (aaVOB: Vob, clientsPatientAccountId: string, clientsPatientIOCId: string, client: any, facility: any, patient: vobPatient): any => {
    let vobCRMUpdatePayload: any;
    let formedVob: any = {...aaVOB};
    const birthDateISO = new Date(patient.birthDate!).toISOString();
    delete formedVob.client;
    delete formedVob.facility;
    vobCRMUpdatePayload = {
        client: client,
        facility: facility,
        vob: formedVob,
        clientsPatientAccountId: clientsPatientAccountId,
        clientsPatientIocId: clientsPatientIOCId,
        patient: {
          firstName: patient.firstName,
          lastName: patient.lastName,
          birthDate: birthDateISO,
        }
    };
    return vobCRMUpdatePayload;
  };

  comparePayers = (facilityPayers: FacilityPayers[], vobStateCopy: Vob) => {
    let payer: FacilityPayers | null = null
  
    payer = facilityPayers.find((payer: FacilityPayers) => {
      return payer.cfgPayorId === vobStateCopy.payer?.payorId
    }) || null

    return payer
  }

  comparePlans = (payer: FacilityPayers, vobStateCopy: Vob) => {
    let plan: FacilityPayerPlans | null = null 
    
    plan = payer.facilityPayorPlans?.find(
      (plan: FacilityPayerPlans) => plan.cfgPayorPlanId === vobStateCopy.plan?.payorPlanId 
    ) || null;

    return plan
  }

  // used to merge different vob and insurance information from crm with new(empty) or existing vob
  mapVOBs = (vobToMapTo: Vob, ptRecordVOB: any, ptRecordInsurance: any) => {
    let clonedVobToMap: Vob = Utils.deepClone(vobToMapTo);
    clonedVobToMap.inNetwDeductible =
        ptRecordVOB?.inNetwDeductible ??
        ptRecordInsurance?.INNDeductibleAmt ??
        clonedVobToMap.inNetwDeductible;

    clonedVobToMap.inNetwDeductibleRemaining = 
        ptRecordVOB?.inNetwDeductibleRemaining ?? 
        ptRecordInsurance?.INNRemDeductibleAmt ?? 
        clonedVobToMap.inNetwDeductibleRemaining;

    clonedVobToMap.inNetwFamilyDeductible = 
        ptRecordVOB?.inNetwFamilyDeductible ?? 
        ptRecordInsurance?.INNFamilyDeductibleAmt ?? 
        clonedVobToMap.inNetwFamilyDeductible;
    
    clonedVobToMap.inNetwFamilyDeductibleRemaining = 
        ptRecordVOB?.inNetwFamilyDeductibleRemaining ?? 
        ptRecordInsurance?.INNFamilyRemDeductibleAmt ?? 
        clonedVobToMap.inNetwFamilyDeductibleRemaining;

    clonedVobToMap.inNetwOopIncluded = ptRecordVOB?.inNetwOopIncluded || ptRecordInsurance?.INNDeductibleIncInOOPMaxFl || clonedVobToMap.inNetwOopIncluded  

    clonedVobToMap.inNetwFamilyOopMax = 
        ptRecordVOB?.inNetwFamilyOopMax ?? 
        ptRecordInsurance?.INNFamilyOOPMaxAmt ?? 
        clonedVobToMap.inNetwFamilyOopMax;

    clonedVobToMap.inNetwFamilyOopMaxRemaining = 
        ptRecordVOB?.inNetwFamilyOopMaxRemaining ?? 
        ptRecordInsurance?.INNFamilyRemainingOOPMaxAmt ?? 
        clonedVobToMap.inNetwFamilyOopMaxRemaining;

    clonedVobToMap.inNetwOopMax = 
        ptRecordVOB?.inNetwOopMax ?? 
        ptRecordInsurance?.INNOOPMaxAmt ?? 
        clonedVobToMap.inNetwOopMax;

    clonedVobToMap.inNetwOopMaxRemaining =
        ptRecordVOB?.inNetwOopMaxRemaining ??
        ptRecordInsurance?.INNRemainingOOPMaxAmt ??
        clonedVobToMap.inNetwOopMaxRemaining;

    clonedVobToMap.ooNetwDeductible =
        ptRecordVOB?.ooNetwDeductible ??
        ptRecordInsurance?.OONDeductibleAmt ??
        clonedVobToMap.ooNetwDeductible;

    clonedVobToMap.ooNetwDeductibleRemaining =
        ptRecordVOB?.ooNetwDeductibleRemaining ??
        ptRecordInsurance?.OONRemDeductibleAmt ??
        clonedVobToMap.ooNetwDeductibleRemaining;

    clonedVobToMap.ooNetwFamilyDeductible =
        ptRecordVOB?.ooNetwFamilyDeductible ??
        ptRecordInsurance?.OONFamilyDeductibleAmt ??
        clonedVobToMap.ooNetwFamilyDeductible;

    clonedVobToMap.ooNetwFamilyDeductibleRemaining =
        ptRecordVOB?.ooNetwFamilyDeductibleRemaining ??
        ptRecordInsurance?.OONFamilyRemDeductibleAmt ??
        clonedVobToMap.ooNetwFamilyDeductibleRemaining;
    
    clonedVobToMap.ooNetwOopIncluded = ptRecordVOB?.ooNetwOopIncluded || ptRecordInsurance?.OONDeductibleIncInOOPMaxFl || clonedVobToMap.ooNetwOopIncluded 

    clonedVobToMap.ooNetwOopMax =
        ptRecordVOB?.ooNetwOopMax ??
        ptRecordInsurance?.OONOOPMaxAmt ??
        clonedVobToMap.ooNetwOopMax;

    clonedVobToMap.ooNetwOopMaxRemaining =
        ptRecordVOB?.ooNetwOopMaxRemaining ??
        ptRecordInsurance?.OONRemainingOOPMaxAmt ??
        clonedVobToMap.ooNetwOopMaxRemaining;

    clonedVobToMap.ooNetwFamilyOopMax =
        ptRecordVOB?.ooNetwFamilyOopMax ??
        ptRecordInsurance?.OONFamilyOOPMaxAmt ??
        clonedVobToMap.ooNetwFamilyOopMax;

    clonedVobToMap.ooNetwFamilyOopMaxRemaining =
        ptRecordVOB?.ooNetwFamilyOopMaxRemaining ??
        ptRecordInsurance?.OONFamilyRemainingOOPMaxAmt ??
        clonedVobToMap.ooNetwFamilyOopMaxRemaining;

    clonedVobToMap.policyNum = ptRecordVOB?.policyNum || ptRecordInsurance?.policyNumber || clonedVobToMap.policyNum   
    
    clonedVobToMap.groupNum = ptRecordVOB?.groupNum || ptRecordInsurance?.groupNumber || clonedVobToMap.groupNum 

    clonedVobToMap.activePolicy = ptRecordVOB?.activePolicy || clonedVobToMap.activePolicy 

    clonedVobToMap.policyBeginDate = ptRecordVOB?.policyBeginDate || clonedVobToMap.policyBeginDate 

    clonedVobToMap.policyEndDate = ptRecordVOB?.policyEndDate || clonedVobToMap.policyEndDate

    clonedVobToMap.inNetwVobClassifications =
        ptRecordVOB?.inNetwVobClassifications &&
        ptRecordVOB.inNetwVobClassifications.length > 0
            ? ptRecordVOB.inNetwVobClassifications
            : clonedVobToMap.inNetwVobClassifications;

    clonedVobToMap.ooNetwVobClassifications =
        ptRecordVOB?.ooNetwVobClassifications &&
        ptRecordVOB.ooNetwVobClassifications.length > 0
            ? ptRecordVOB.ooNetwVobClassifications
            : clonedVobToMap.ooNetwVobClassifications;

    const digitalVerificationMethod = ptRecordVOB?.digitalVerificationMethod || clonedVobToMap.digitalVerificationMethod;
    if (typeof digitalVerificationMethod === 'boolean') {
      clonedVobToMap.digitalVerificationMethod = digitalVerificationMethod;
    } else if (digitalVerificationMethod === 'digital') {
      clonedVobToMap.digitalVerificationMethod = true;
    } else {
      clonedVobToMap.digitalVerificationMethod = false;
    }

    clonedVobToMap.isValid = ptRecordVOB?.isValid || clonedVobToMap.isValid;

    clonedVobToMap.selfPay = ptRecordVOB?.selfPay || clonedVobToMap.selfPay;

    // clonedVobToMap.isLive = ptRecordVOB?.isLive || clonedVobToMap.isLive;    
    // clonedVobToMap.note = ptRecordVOB?.note || clonedVobToMap.note;

    return clonedVobToMap
  }

  // function to match VOB Classifications and update ptRecord in Launch Route
  matchClassifications = (
    ptRecordVOB: any,
    vobClassificationsState: vobClassificationsItem[]
  ): any => {

    if(!ptRecordVOB) return ptRecordVOB

    let clonePtRecordVob = Utils.deepClone(ptRecordVOB);
    
    if (ptRecordVOB?.inNetwVobClassifications?.length > 0) {
        const updatedInNetwVobClassifications =
            ptRecordVOB.inNetwVobClassifications.map((item: any) => {
                const matchingVobClassification =
                    vobClassificationsState.find(
                        vobClassification =>
                            vobClassification.vobClassificationId ===
                            item.vobClassificationId
                    );
                return {
                    ...item,
                    coPaySelection: item.copayType === "No" ? 'n': item.copayType === "CoPay Only" ? 'copayonly' : 'y',
                    coInsurance: item.coInsurance ? item.coInsurance : null,
                    coPay: item.coPay ? item.coPay : null,
                    selectedClassification: matchingVobClassification || null,
                    maxDays: item.maxDays || null,
                };
            });
          clonePtRecordVob.inNetwVobClassifications =
            updatedInNetwVobClassifications;
    }
    if (ptRecordVOB?.ooNetwVobClassifications?.length > 0) {
        const updatedOoNetwVobClassifications =
            ptRecordVOB.ooNetwVobClassifications.map((item: any) => {
                const matchingVobClassification =
                    vobClassificationsState.find(
                        vobClassification =>
                            vobClassification.vobClassificationId ===
                            item.vobClassificationId
                    );
                return {
                    ...item,
                    coPaySelection: item.copayType === "No" ? 'n': item.copayType === "CoPay Only" ? 'copayonly' : 'y',
                    coInsurance: item.coInsurance ? item.coInsurance : null,
                    coPay: item.coPay ? item.coPay : null,
                    selectedClassification: matchingVobClassification || null,
                    maxDays: item.maxDays || null,
                };
            });
            clonePtRecordVob.ooNetwVobClassifications =
            updatedOoNetwVobClassifications;
    }
    return clonePtRecordVob;
  };

  checkIntegrationFeatureFlag = (clientCrm: ClientCrm[]) => {
    return clientCrm?.[0]?.isIntEnabled;
  }

  getClientCrmDetails = (currentClientId: number | undefined, allClients: ClientStatusCardViewModel[]) => {
    const clientItem: ClientStatusCardViewModel | undefined = allClients?.find(
        (client: ClientStatusCardViewModel) =>
            client.clientId === currentClientId
    );
    const clientCrm = clientItem?.clientCrm || [];
    return clientCrm;
  }

  mapToNewEstimate = (
    vobId: any,
    clientId: Id,
    facilityId: Id,
    clonedEstimate: Estimate,
    advisorPatientId: number,
    patientEncounterId?: string
  ): Estimate => {
    const createdDate = clonedEstimate?.createDt
      ? admissionsAdvisorUtils.formatDateTime(
          new Date(clonedEstimate?.createDt!)
        )
      : admissionsAdvisorUtils.formatDateTime(new Date());
    const levelsOfCare = clonedEstimate?.facilityLevelOfCare
      ?.map(loc => loc.facilityLevelOfCareName)
      .join(', ');
    const estimateDescription = `${levelsOfCare} - ${createdDate}`;
    return {
      vobId: vobId,
      advisorPatientId: advisorPatientId,
      clientId: clientId,
      facilityId: facilityId,
      ...(patientEncounterId !== undefined && {  patientEncounterId: patientEncounterId }),
      description: estimateDescription,
      quoteMethod: clonedEstimate?.quoteMethod || QuoteMethod.AVGLOS,
      anticipatedAdmitDate: clonedEstimate?.anticipatedAdmitDate || new Date(),
      facilityLevelOfCare: clonedEstimate?.facilityLevelOfCare || [],
      isPlanYearCrossover: clonedEstimate?.isPlanYearCrossover ||  false,
      isActive: clonedEstimate?.isActive || true,
      crossoverSelection: clonedEstimate?.crossoverSelection || CrossoverSelectionEnum.BEFORE,
      financialAssistance: clonedEstimate?.financialAssistance,
      priorCare: clonedEstimate?.priorCare,
      summarySelection: clonedEstimate?.summarySelection || SummarySelections.NONADJUSTED,
      riskAssessment: clonedEstimate?.riskAssessment,
      riskAssessmentHre: clonedEstimate?.riskAssessmentHre
    };
  }

  doLocsHaveUndefinedCrossoverValues = (selectedLocs: FacilityLevelOfCareWithCrossoverDays[]) => {
    return selectedLocs.some((loc) => _.isNil(loc.losDaysBeforeCrossover) || _.isNil(loc.losDaysAfterCrossover))
  }

  isSelectedBeforeCrossoverDayGreaterThanTotal = ({
      selectedLocs,
      policyEndDate,
      admissionDate,
                                                  }: {
    selectedLocs: FacilityLevelOfCareWithCrossoverDays[]
    policyEndDate: Date,
    admissionDate: Date
  }) => {
    const selectedTotalDaysBeforeCrossover = selectedLocs.reduce((acc: number, selectedLoc)=>{
      acc += selectedLoc.losDaysBeforeCrossover
      return acc
    }, 0)

    return selectedTotalDaysBeforeCrossover > getDaysBetweenAdmissionAndPolicyEndDates({
      admissionDate,
      policyEndDate
    })
  }

  isModalUpdateNeededForCrossover = ({
                                             isCrossover,
                                             newSelectedLocs,
                                             policyEndDate,
                                             anticipatedAdmissionDate
                                           }: {
    isCrossover: boolean,
    newSelectedLocs: FacilityLevelOfCareWithCrossoverDays[]
    policyEndDate: Date,
    anticipatedAdmissionDate: Date
  }) => {

    return isCrossover && (
        this.doLocsHaveUndefinedCrossoverValues(newSelectedLocs) ||
        this.isSelectedBeforeCrossoverDayGreaterThanTotal({
          selectedLocs: newSelectedLocs,
          admissionDate: anticipatedAdmissionDate,
          policyEndDate
        })
    )
  }

  getDeductibleSpentPct = (deductibleSpent: number, totalDeductible: number): string => {
    return ((deductibleSpent / totalDeductible) * 100).toFixed(0) + "%";
  };

  getOopSpentPct = (
    includeOOPmax: boolean,
    oopMaxFromVob: number,
    remainingOopMaxFromVob: number
  ): string => {
    if (!includeOOPmax) return 'N/A';
    return (((oopMaxFromVob - remainingOopMaxFromVob) / oopMaxFromVob) * 100).toFixed(0) + '%';
  };

  getQuoteMethodName = (quoteMethod: QuoteMethod): string => {
    const quoteMethods = [
      {name: 'Average Length of Stay', value: QuoteMethod.AVGLOS},
      {name: 'Recommended Length of Stay', value: QuoteMethod.RLOS},
      {name: 'Manual', value: QuoteMethod.MANUAL},
      {name: 'Single Case Agreement', value: QuoteMethod.SCA},
    ];
    return quoteMethods.find(qm => qm.value === quoteMethod)?.name || '';
  };

  calculateTotalLOS = (levelsOfCare: FacilityLevelOfCare[]): number => {
    return levelsOfCare.reduce((total, loc) => total + (loc.losDays || 0), 0);
  };

  convertOldEstimateToNew = (estimateBody: EstimatorBody, vobId: number, advisorPatientId: number): Estimate => {
    const locToLoopOver = estimateBody.selectedLevelsOfCare || [];

    const facilityLevelOfCare: FacilityLevelOfCare[] = locToLoopOver?.length > 0 ? locToLoopOver?.map(
      (loc: any) => {
        const pdrRate = loc.facilityPayorLocRateLos?.pdrRate ?? loc.pdrRate;
        const losDays = loc.facilityPayorLocRateLos?.losDays ?? loc.losDays;
        const entryType =
          loc.facilityPayorLocRateLos?.entryType ?? loc.entryType;
        const isCovered =
          loc.facilityPayorLocRateLos?.isCovered ?? loc.isCovered;
        const locType = loc.cfgLocType ?? loc.locType;
        const isInpatient = loc.isInpatient ?? true
        const cfgLevelOfCareId = loc.cfgLevelOfCareId ?? 0
        const facilityPayorPlanId = loc.facilityPayorPlanId ?? 0
        const recommendedLos = loc.recommendedLos ?? 0

        return {
          facilityLevelOfCareId: loc.facilityLevelOfCareId,
          facilityLevelOfCareCode: loc.facilityLevelOfCareCode,
          facilityLevelOfCareName: loc.facilityLevelOfCareName,
          facilityPayorPlanId,
          cfgLevelOfCareId, 
          sortOrder: loc.sortOrder,
          perDiemOperatingCost: loc.perDiemOperatingCost,
          minRevenueAmount: loc.minRevenueAmount,
          minRevenueFlatRate: loc.minRevenueFlatRate,
          pdrRate,
          losDays,
          recommendedLos,
          entryType,
          isCovered,
          isInpatient,
          vobClassificationId: loc.vobClassificationId,
          locType,
          losDaysAfterCrossover: loc.losDaysAfterCrossover,
          losDaysBeforeCrossover: loc.losDaysBeforeCrossover
        };
      }
    ) : [];

    const quoteMethod =
      typeof estimateBody.quoteMethod === "string"
        ? this.stringToQuoteMethod(estimateBody.quoteMethod)
        : estimateBody.quoteMethod;
    
    const anticipatedAdmitDate = Boolean(estimateBody.admissionDate) ? new Date(estimateBody.admissionDate!) : undefined 

    const description = Boolean(estimateBody.description) ? estimateBody.description : undefined

    const patientEncounterId = estimateBody.finPay?.patientEncounterId || estimateBody.patientEncounterId

     // old estimate has "hasPriorCare"
     const isOldEstimate = estimateBody.hasOwnProperty("hasPriorCare")

     let priorCare: PriorCare | undefined;
    
     if(isOldEstimate) {
       if(estimateBody.priorCare && estimateBody.hasPriorCare) {
         priorCare = this.mapOldPriorCareToNew(estimateBody.priorCare)
       } else {
         priorCare = undefined
       }
     } else {
      // new estimate, set prior care to the estimate body prior care
       priorCare = estimateBody.priorCare as unknown as PriorCare
     }

    const newEstimate: Estimate = {
      estimateId: estimateBody.pfrEstimateId,
      clientId: estimateBody.client.clientId!,
      facilityId: estimateBody.facility.facilityId!,
      vobId,
      patientEncounterId, 
      advisorPatientId,
      description,
      quoteMethod: quoteMethod || QuoteMethod.AVGLOS,
      anticipatedAdmitDate,
      facilityLevelOfCare,
      priorCare,
      isPlanYearCrossover: false, 
      crossoverSelection: CrossoverSelectionEnum.BEFORE,
      summarySelection: SummarySelections.NONADJUSTED,
      isActive: Boolean(estimateBody.isActive),
    };

    return newEstimate;
  }

  stringToQuoteMethod = (quoteMethod: string): QuoteMethod => {
    switch (quoteMethod) {
      case "sca":
        return QuoteMethod.SCA;
      case "avglos":
        return QuoteMethod.AVGLOS;
      case "manual":
        return QuoteMethod.MANUAL;
      case "rlos":
        return QuoteMethod.RLOS;
      default:
        return QuoteMethod.AVGLOS;
    }
  };

  isEmptyObject = (obj: Record<string, any>): boolean => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  mapOldPriorCareToNew = (priorCare: any): PriorCare | undefined => {
    if (!priorCare || typeof priorCare !== "object" || this.isEmptyObject(priorCare))
      return undefined;
  
    let priorCareProvider;
  
    if (typeof priorCare.priorCareProvider === "object") {
      priorCareProvider =
        priorCare.priorCareProvider?.value === "thirdparty"
          ? PriorCareProvider.THIRDPARTY
          : PriorCareProvider.CLIENT;
    } else priorCareProvider = priorCare.priorCareProvider;
  
    let thirdPartyPayerStatus;
  
    if (typeof priorCare.thirdPartyPayerStatus === "object") {
      thirdPartyPayerStatus =
        priorCare.thirdPartyPayerStatus?.value === "onnetw"
          ? ThirdPartyPayerStatus.OONETW
          : ThirdPartyPayerStatus.INNETW;
    } else thirdPartyPayerStatus = priorCare.thirdPartyPayerStatus;
  
    const facilityId = priorCare?.facility?.facilityId || priorCare.facilityId;
  
    let isOldFormat = false;
    let priorCareProviderType: PriorCareProvider;
    let locToLoopOver;
  
    if (typeof priorCare.priorCareProvider === "object") {
      if (priorCare.priorCareProvider.value === "client") {
        locToLoopOver = priorCare.priorFacilityLevelsOfCare;
        priorCareProviderType = PriorCareProvider.CLIENT;
        isOldFormat = true;
      } else {
        locToLoopOver = priorCare.priorThirdPartyLevelsOfCare;
        priorCareProviderType = PriorCareProvider.THIRDPARTY;
        isOldFormat = true;
      }
    } else {
      locToLoopOver = priorCare.loc;
    }
  
    let loc: FacilityLevelOfCare[];
  
    if (isOldFormat) {
      loc = locToLoopOver?.map((loc: any) => {
        if (priorCareProviderType === PriorCareProvider.CLIENT) {
          return {
            facilityLevelOfCareId: loc.facilityLevelOfCareId,
            facilityLevelOfCareCode: loc.facilityLevelOfCareCode,
            facilityLevelOfCareName: loc.facilityLevelOfCareName,
            sortOrder: loc.sortOrder,
            perDiemOperatingCost: loc.perDiemOperatingCost,
            minRevenueAmount: loc.minRevenueAmount,
            minRevenueFlatRate: loc.minRevenueFlatRate,
            pdrRate: loc.facilityPayorLocRateLos?.pdrRate,
            losDays: loc.facilityPayorLocRateLos?.losDays,
            entryType: loc.facilityPayorLocRateLos?.entryType,
            isCovered: loc.facilityPayorLocRateLos?.isCovered,
            vobClassificationId: loc.vobClassificationId,
            locType: loc.cfgLocType
          };
        } else {
          return {
            facilityLevelOfCareId: loc.levelOfCareId,
            facilityLevelOfCareCode: loc.levelOfCareCode,
            facilityLevelOfCareName: loc.levelOfCareName,
            sortOrder: 1,
            perDiemOperatingCost: loc.perDiemOperatingCost,
            minRevenueAmount: loc.minRevenueAmount,
            minRevenueFlatRate: loc.minRevenueFlatRate,
            pdrRate: loc.thirdPartyAverageInnPdr,
            losDays: loc.lengthOfStay,
            entryType: loc.entryType,
            isCovered: true,
            vobClassificationId: loc.vobClassificationId,
            locType: loc.locType
          };
        }
      });
    } else {
      loc = priorCare.loc;
    }
  
    return {
      priorCareProvider,
      thirdPartyPayerStatus,
      facilityId,
      loc
    };
  };
}

export const admissionsAdvisorUtils = new AdmissionAdvisorUtils();
