import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
	IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { TableColumn } from "../../../shared/model/table-column";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/state/root-reducer";
import { formatNumberToUSD } from "../../../shared/calculators";
import { Button, GenericLevelOfCare, getFacilityLevelsOfCareSummary } from '@finpay-development/shared-components';
import { admissionsAdvisorUtils } from "../../utils/admission-advisor-utils";
import { CrossoverPolicies } from "../../models/estimator";
import _ from "lodash";

interface EstFinancialSummaryModalProps {
  open: boolean;
  handleFinancialSummaryModalCancel: () => void;
}

export function EstFinancialAssistanceSummaryModal(props: EstFinancialSummaryModalProps) {
  const { open, handleFinancialSummaryModalCancel } = props;
	const [crossoverPolicies, setCrossoverPolicies] = useState<CrossoverPolicies>();
  const [levelsOfCareSummary, setLevelsOfCareSummary] = useState<GenericLevelOfCare[]>([]);
	const [normalPfr, setNormalPfr] = useState<number>(0.00);

  const locTableHeaders: TableColumn[] = [
    { id: "colLocName", label: "Levels of Care", align: "left" },
    { id: "colLocDays", label: "Length of Stay (days)", align: "left" },
    { id: "colLocChgs", label: "Adjusted Charges", align: "right" },
  ];

	useEffect(() => {
		// calculate PFR for plan crossover
		if (estimatorState.planYearCrossover?.usingPlanYearCrossoverPfr && estimatorState.planYearCrossover?.planYearCrossoverConfirmed) {
      const crossOverPolicies: CrossoverPolicies = admissionsAdvisorUtils.calculateCrossoverPfr(estimatorState.planYearCrossover.crossoverDays, estimatorState, vobState);
			setCrossoverPolicies(crossOverPolicies);
		}
		const noCrossOverPfr: number = getNormalPFR();
		setNormalPfr(noCrossOverPfr);
    setLevelsOfCareSummary(getFacilityLevelsOfCareSummary(estimatorState.selectedLevelsOfCare, estimatorState.quoteMethod, vobState?.payer?.inNetwork!, vobState?.selfPay!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const state = {
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.estimator
    ),
  };

  const { vobState, estimatorState } = state;

  const financialSummary = admissionsAdvisorUtils.calculateFinancialSummary(estimatorState, vobState);

	const pfrAdjustment = () => {
		const scholarshipAmt = (normalPfr * estimatorState?.financialAssistance?.scholarshipPercentage!);
		return formatNumberToUSD(normalPfr - scholarshipAmt);
	}

	const pfrAdjWithCrossover = () => {
		const scholarshipAmt = (crossoverPolicies?.currentPolicyFinancialSummary?.totalPFR! + crossoverPolicies?.nextPolicyFinancialSummary?.totalPFR!) * estimatorState?.financialAssistance?.scholarshipPercentage!
		return formatNumberToUSD((crossoverPolicies?.currentPolicyFinancialSummary?.totalPFR! + crossoverPolicies?.nextPolicyFinancialSummary?.totalPFR!) - scholarshipAmt);
	}

  const getNormalPFR = () => {
    const financialSummary = admissionsAdvisorUtils.calculateFinancialSummary(estimatorState, vobState);
    return financialSummary.subtotalPFR;
  }

	const showScholarshipPct = () => {
		let scholarship = 0;
		if (estimatorState?.financialAssistance?.scholarshipPercentage) {
			scholarship = estimatorState?.financialAssistance?.scholarshipPercentage * 100;
		}
		return scholarship.toFixed(0) + "%";
	}

	const showScholarshipAmt = (pfr: number) => {
		let scholarshipPct = 1;
		if (estimatorState?.financialAssistance?.scholarshipPercentage) {
			scholarshipPct = estimatorState?.financialAssistance?.scholarshipPercentage;
		}
		return formatNumberToUSD(pfr * scholarshipPct);
	}

  function handleModalCancel() {
    handleFinancialSummaryModalCancel();
  }

  function adjustedChgs() {
    let charges = 0.0;
    levelsOfCareSummary.forEach((loc) => {
      if(loc.cfgLocType === 1) return
      charges += loc?.rate * loc?.days;
    });
    return formatNumberToUSD(charges);
  }

  function estimatedLos() {
    let los = 0;
    levelsOfCareSummary.forEach((loc) => {
      if(loc.cfgLocType === 1) return
      los += loc?.days;
    });
    return los;
  }

  return (
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>Financial Assistance Summary</DialogTitle>

			<IconButton
         aria-label="close"
        onClick={handleModalCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>

        <TableContainer component={Paper}>
          <Table size="small" className="alternating-row">
            <TableHead>
              <TableRow>
                {locTableHeaders.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {_.sortBy(
                levelsOfCareSummary,
                "name"
              ).filter((loc: GenericLevelOfCare) => {
                if(loc.cfgLocType === 1) return false
                return true;
              }).map((row: GenericLevelOfCare, index: number) => {
                return (
                  <TableRow key={index} className="py-3">
                    <TableCell>
                      {row?.code}-
                      {row?.name}
                    </TableCell>
                    <TableCell>
                      {row?.days}
                    </TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(
                        row?.days * row?.rate
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow className="py-3 light-green-row">
                <TableCell colSpan={2}>Estimated Adjusted Charges</TableCell>
                <TableCell align="right">{adjustedChgs()}</TableCell>
              </TableRow>
              <TableRow className="py-3 light-green-row">
                <TableCell colSpan={2}>Estimated Length of Stay</TableCell>
                <TableCell align="right">{estimatedLos()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="subtitle2" className="mt-2">
          PFR Details
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small" className="alternating-row">
            <TableBody>


						{estimatorState.planYearCrossover?.usingPlanYearCrossoverPfr && estimatorState.planYearCrossover?.planYearCrossoverConfirmed ?
							(<>
            	<TableRow className="py-3">
                <TableCell>Crossover Year PFR</TableCell>
                <TableCell align="right">
									{formatNumberToUSD(crossoverPolicies?.nextPolicyFinancialSummary?.totalPFR)}
                </TableCell>
              </TableRow>
            	<TableRow className="py-3">
                <TableCell>Current Year PFR</TableCell>
                <TableCell align="right">
									{formatNumberToUSD(crossoverPolicies?.currentPolicyFinancialSummary?.totalPFR)}
                </TableCell>
              </TableRow>
            	<TableRow className="py-3">
                <TableCell>
									PFR Subtotal
								</TableCell>
                <TableCell align="right">
									{formatNumberToUSD(crossoverPolicies?.nextPolicyFinancialSummary?.totalPFR! + crossoverPolicies?.currentPolicyFinancialSummary?.totalPFR!)}
                </TableCell>
              </TableRow>
            	<TableRow className="py-3">
                <TableCell>
									Financial Assistance Scholarship ({showScholarshipPct()})
								</TableCell>
                <TableCell align="right">
									{showScholarshipAmt(crossoverPolicies?.nextPolicyFinancialSummary?.totalPFR! + crossoverPolicies?.currentPolicyFinancialSummary?.totalPFR!)}
                </TableCell>
              </TableRow>
              <TableRow className="py-3">
                <TableCell>
								<Typography variant="subtitle2">PFR After Financial Assistance</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    {pfrAdjWithCrossover()}
                  </Typography>
                </TableCell>
              </TableRow>
							</>)
							:
							(<>
            	<TableRow className="py-3">
                <TableCell>Total Patient PFR</TableCell>
                <TableCell align="right">
									{formatNumberToUSD(normalPfr)}
                </TableCell>
              </TableRow>
            	<TableRow className="py-3">
                <TableCell>Financial Assistance Scholarship ({showScholarshipPct()})</TableCell>
                <TableCell align="right">
									{showScholarshipAmt(normalPfr)}
                </TableCell>
              </TableRow>
            	<TableRow className="py-3">
                <TableCell>PFR After Financial Assistance</TableCell>
                <TableCell align="right">
								{pfrAdjustment()}
                </TableCell>
              </TableRow>
              {financialSummary.scholarshipAdjustedSurcharge > 0 && (
                <>
                  <TableRow className="py-3">
                    <TableCell>Admission Assessment</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(financialSummary.scholarshipAdjustedSurcharge)}
                    </TableCell>
                  </TableRow>
                </>
              )}
              <TableRow className="py-3">
                <TableCell><Typography variant="subtitle2">Total PFR</Typography></TableCell>
                <TableCell align="right">
								<Typography variant="subtitle2">{formatNumberToUSD(financialSummary.scholarshipAdjustedTotalPFR)}</Typography>
                </TableCell>
              </TableRow>
							</>)
						}

 
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleModalCancel()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
