import { TextField } from '@finpay-development/shared-components';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import SearchIcon from '@mui/icons-material/Search';
import {
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LevelOfCare } from '../../../../admin-configuration/models/level-of-care';
import { configGetLOC } from '../../../../admin-configuration/state/admin-configuration-thunk';
import { TableColumn } from '../../../../shared/model/table-column';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';

interface PriorCareMasterLocGridProps {
  handleSelections: (selectedCopy: LevelOfCare[], isValid: boolean) => void;
  selectedThirdPartyLocs: LevelOfCare[];
}

export function PriorCareMasterLocGrid(props: PriorCareMasterLocGridProps) {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState<LevelOfCare[]>();
  const [searched, setSearched] = useState<string>("");
  const [page, setPage] = useState(0);
  const [selectedLocs, setSelectedLocs] = useState<LevelOfCare[]>(
    props.selectedThirdPartyLocs
  );
  const dispatch = useDispatch<AppDispatch>();
  const paramId = -2;

  const state = {
    masterLevelsOfCareState: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.levelsOfCare
    ),
  };
  const { masterLevelsOfCareState } = state;

  const locHeaders: TableColumn[] = [
    { id: "colCheck", label: "", minWidth: 30 },
    { id: "levelOfCareCode", label: "Code", minWidth: 200 },
    { id: "levelOfCareName", label: "Name", minWidth: 200 },
    { id: "levelOfCareDesc", label: "Description", minWidth: 200 },
    { id: "colLocDays", label: "Days/Sessions" },
  ];

  useEffect(() => {
    dispatch(configGetLOC(paramId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state?.masterLevelsOfCareState?.length > 0) {
      setRows(state?.masterLevelsOfCareState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterLevelsOfCareState]);

  useEffect(() => {
    setSelectedLocs(props.selectedThirdPartyLocs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedThirdPartyLocs]);

  const clearSelectedLocs = (event: unknown) => {
    setSelectedLocs([]);
  };

  const handleCheckboxClick = (row: LevelOfCare) => {
    let index;
    let selectedCopy = [...selectedLocs];
    index = selectedCopy.findIndex(
      (el) => el.levelOfCareId === row.levelOfCareId
    );
    if (index > -1) {
      selectedCopy.splice(
        selectedCopy.findIndex((el) => {
          return el.levelOfCareId === row.levelOfCareId;
        }),
        1
      );
    } else {
      let editedLoc: LevelOfCare = { ...row };
      selectedCopy.push(editedLoc);
    }
    setSelectedLocs(selectedCopy);
    const isFormValid = checkIsFormValid(selectedCopy);
		props.handleSelections(selectedCopy, isFormValid);
  };

  const changeLocDays = (id: number, value: string) => {
    let selectedCopy: LevelOfCare[] = JSON.parse(JSON.stringify(selectedLocs));
    let index = selectedCopy.findIndex((el) => el.levelOfCareId === id);
    if (selectedCopy[index]) {
      selectedCopy[index].lengthOfStay = parseInt(value);
    }
    setSelectedLocs(selectedCopy);
    const isFormValid = checkIsFormValid(selectedCopy);
    props.handleSelections(selectedCopy, isFormValid);
  };

  const checkIsFormValid = (selectedCopy: LevelOfCare[]) => {
    return (
      selectedCopy.length > 0 &&
      selectedCopy.every((currentValue) => !isNaN(currentValue?.lengthOfStay!))
    );
  };

  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const filteredRows = masterLevelsOfCareState?.filter((row) => {
      return (
        row.levelOfCareName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.levelOfCareCode.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.levelOfCareDesc.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
    setPage(0);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const isSelected = (levelOfCareId: number) =>
    selectedLocs.findIndex((el) => el.levelOfCareId === levelOfCareId) !== -1;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Grid container direction="row" spacing={2} alignItems="center" className="my-1">
        <Grid item direction="column" xs={7}>
          <Typography variant="subtitle2">Select Levels of Care</Typography>
        </Grid>
        <Grid item xs={5} direction="column">
          <TextField
            placeholder="Search by code or name"
            name="searched"
            value={searched}
            onChange={(event: any) => requestSearch(event?.target?.value)}
            onBlur={() => cancelSearch()}
            startAdornment={<SearchIcon />}
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {locHeaders.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.sortBy(rows, "levelOfCareName").slice!(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).filter((loc: LevelOfCare) => {
              if(loc.locType === 1) return false
              return true
            }).map((row: LevelOfCare) => {
              const isItemSelected = isSelected(row?.levelOfCareId);
              const selectedRow: LevelOfCare =
                selectedLocs[
                  selectedLocs.findIndex(
                    (el) => el.levelOfCareId === row.levelOfCareId
                  )
                ];
              return (
                <TableRow>
                  <TableCell align="center">
                    <Checkbox
                      onClick={(e) => handleCheckboxClick(row)}
                      className="selectCheckbox"
                      checked={isItemSelected}
                    />
                  </TableCell>
                  <TableCell align="left">{row.levelOfCareCode}</TableCell>
                  <TableCell align="left">{row.levelOfCareName}</TableCell>
                  <TableCell align="left">{row.levelOfCareDesc}</TableCell>
                  <TableCell>
                    {isItemSelected ? (
                      <MuiTextField
                        type="number"
                        style={{ width: "4.5em" }}
                        value={selectedRow?.lengthOfStay}
                        onChange={(e) =>
                          changeLocDays(row.levelOfCareId, e.target.value)
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Grid container justifyContent="space-between">
          <Grid item>
            <IconButton
              title="clear selected"
              style={{ fontSize: "1.5rem", marginLeft: "1.25rem" }}
              onClick={(e) => clearSelectedLocs(e)}
            >
              <HighlightOffSharpIcon color="error" /> Clear selected
            </IconButton>
          </Grid>
          <Grid item>
            <TablePagination
              component="div"
              rowsPerPageOptions={[
                5,
                10,
                50,
                { value: rows?.length!, label: "All" },
              ]}
              count={rows?.length!}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={"View"}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </TableContainer>
    </>
  );
}
