import React from "react";
import { useParams } from "react-router-dom";


function DisputeResponse(){
    const params = useParams()
    const disputeId = params.did
    
    return (
        <>
        </>
    )
}

export default DisputeResponse